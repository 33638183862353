<div class="sfdc-configuration-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">{{langObj ? langObj?.ShopFloorTitle : ('ShopFloorTitle' | translate)}}</h1>
    </div>
  </div>
  <div class="wrapper-box wrapper-box-shadow table-wrapper">
    <ngx-spinner [ngClass]="{'display': spinnerShow == 'block', 'displayNone': spinnerShow == 'none'}" bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="groupDataForm">
      <div style="display: flex;">
        <div style="width: 85%;">
          <div class="content-top-row">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left: 2px;">
              <mat-label class="placeholder-title">{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}
              </mat-label>
              <mat-select formControlName="group" panelClass="testClass" name="Group"
                (selectionChange)="getPlants()">
                <mat-option *ngFor="let group of groups" [value]="group.TypeId">
                  {{ group.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
              <mat-select name="list" formControlName="plant" (selectionChange)="GetProcessOrderList()" >
                <mat-option *ngFor="let o of Plants" [value]="o.DeptId">{{o.DeptDesc}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="margin-left: 10px">
              <mat-label>{{ langObj ? langObj?.ProcessOrderNo : ('ProcessOrderNo' | translate) }}</mat-label>
              <mat-select formControlName="processOrder" panelClass="testClass" (selectionChange)="getMaterialConsumption()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'PO'" [array]="processOrderList"
                (filteredReturn)="fiteredProcessOrderList = $event"></mat-select-filter>
                <mat-option *ngFor="let Po of fiteredProcessOrderList" [value]="Po.PPDId"> {{Po.PO}} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left:10px;">
              <mat-label>{{ langObj ? langObj?.OperationName : ('OperationName' | translate)}}</mat-label>
              <input matInput formControlName="operationName" name="operationName" [readonly]="true" />
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing " pTooltip="{{PDtooltip}}"
              tooltipPosition="top" style="margin-left:10px;">
              <mat-label>{{ langObj ? langObj?.ProductDetail : ('ProductDetail' | translate) }}</mat-label>
              <input matInput formControlName="productDetail" name="productDetails" [readonly]="true" />
            </mat-form-field>
          </div>
          <div class="content-top-row">

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left: 2px">
              <mat-label>{{ langObj ? langObj?.ItemCode : ('ItemCode' | translate)}}</mat-label>
              <input matInput formControlName="itemCode" name="itemCode" [readonly]="true" />
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing " pTooltip="{{IDtooltip}}"
              style="margin-left: 10px">
              <mat-label>{{ langObj ? langObj?.ItemDescription : ('ItemDescription' | translate) }}</mat-label>
              <input matInput formControlName="itemDescription" name="itemDesc" [readonly]="true" />
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing "
              style="margin-left: 10px">
              <mat-label>{{ langObj ? langObj?.Status : ('Status' | translate) }}</mat-label>
              <input matInput formControlName="status" name="status" [readonly]="true" />
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left: 2px">
              <mat-label>{{ langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
              <textarea matInput formControlName = "remarks" name="remarks" cdkTextareaAutosize [readonly]="true"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3">
              </textarea>
            </mat-form-field>

            <button (click)="resetGroupDataFormForAllPO()" mat-mini-fab mat-button color="primary" 
              pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Accordian -->

  <mat-accordion style="margin-top: 10px;">
    <mat-expansion-panel (opened)="panelOpenState = true;" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2" class="disable_ripple_acc2">
        <mat-panel-title>
          {{ langObj ? langObj?.MaterialConsumption : ('MaterialConsumption' | translate)}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="materialConsumptionForm">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="margin-left: 1px;">
              <mat-label>{{ langObj ? langObj?.MaterialDetail : ('MaterialDetail')}}</mat-label>
              <mat-select formControlName="materialDetail" panelClass="testClass" name="materialDetail"
                (selectionChange)="GetLableBatch($event)">
                <mat-option *ngFor="let material of ItemTypeList" [value]="material.BatchItemTypeId">{{material.Name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="inputField">
              <nz-date-picker class="calender-input" id="timePicker" formControlName="date" nzFormat="dd/MM/yyyy HH:mm"
                [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
              <span class="floating-text">{{ langObj ? langObj?.Time : ('Time' | translate) }}</span>
            </div>

            <mat-form-field *ngIf="isLoteCodeNecessary" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="margin-left: 10px">
              <mat-label>{{ langObj ? langObj?.LotCode : ('LotCode' | translate) }}</mat-label>
              <input formControlName="lot" matInput name="batchdesc" />
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="margin-left: 10px">
              <mat-label>{{ langObj ? langObj?.ActualQuantity : ('ActualQuantity' | translate)}} {{quantityUnit}}</mat-label>
              <input formControlName="actQty" type="number" matInput name="length" />
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="margin-left: 10px">
              <mat-label>{{ langObj ? langObj?.EstimatedQuantity : ('EstimatedQuantity' | translate)}} {{quantityUnit}}
              </mat-label>
              <input formControlName="qty" matInput name="qty" [readonly]="true" />
            </mat-form-field>
          </div>


          <div class="content-top-row">
            <button class="user_badge" mat-flat-button color="accent" matBadgePosition="after" matBadgeColor="accent"
              MatBadgeSize="large" disableRipple>
              {{itemCode}}
            </button>

            <button class="user_badge" mat-flat-button color="accent" matBadgePosition="after" matBadgeColor="accent"
              MatBadgeSize="large" disableRipple style="margin-left: 14px;"
              [class]="languageDir === 'rtl' ? 'arb-btn-mrgn' : ''">
              {{total}}
            </button>

            <mat-form-field appearence="outline"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 14px;">
              <mat-label>{{ langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
              <input formControlName="remarks" matInput name="remarks" />
            </mat-form-field>

            <div style="margin-left: 10px;">
              <!--Material can consume or edit if selected Order number is not Completed or Close-->
              <button (click)="UpdateJBBatchInfoForMaterialConsumption()" type="submit" *ngIf="JobBatchId != 0"
                [disabled]="canMaterialConsume || !materialConsumptionForm.valid" mat-mini-fab mat-button color="primary"
                [pTooltip]="langObj ? langObj?.Update : ('Update' | translate)" tooltipPosition="top">
                <i class="fa fa-pencil" style="color: #fff"></i>
              </button>

              <button (click)="SaveJBBatchInfoForMaterialConsumption()" type="submit" *ngIf="JobBatchId == 0"
                [disabled]="canMaterialConsume || !materialConsumptionForm.valid" mat-mini-fab mat-button color="primary"
                [pTooltip]="langObj ? langObj?.Save: ('Save' | translate)" tooltipPosition="top">
                <i class="fa fa-floppy-o" style="color: #fff"></i>
              </button>

              <button type="button" mat-mini-fab mat-button class="refresh-btn"
                [pTooltip]="langObj ? langObj?.Reset: ('Reset' | translate)" tooltipPosition="top"
                style="margin-left: 10px;" (click)="RefreshMaterialConsumption()">
                <i class="fa fa-refresh" style="color: #fff"></i>
              </button>

              <button type="button" mat-mini-fab mat-button class="list-btn"
                [pTooltip]="langObj ? langObj?.HistoryBatchList : ('HistoryBatchList' | translate)" type="button"
                tooltipPosition="top" style="margin-left: 10px;" (click)="openHistorybatchModal($event)">
                <i class="fa fa-list" style="color: #fff"></i>
              </button>
            </div>
          </div>
        </div>

        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine"
          [rowData]="rowDataMaterialConsumption" [columnDefs]="columnMaterialConsumption" (gridReady)="onGridReady($event)"
          [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true; getPOListByDateRangeForProductionOutput(true)" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc3" class="disable_ripple_acc3">
        <mat-panel-title>
          Production Output
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ngx-spinner [ngClass]="{'display': spinnerShow == 'none', 'displayNone': spinnerShow == 'block'}" bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' /> " [fullScreen] = "false"></ngx-spinner>

      <form [formGroup]="manualStackerFromProductionOutput">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">
            <div class="inputField">
              <nz-date-picker class="calender-input" id="picker"formControlName="startDate" [nzDisabledDate]="" 
              nzFormat="yyyy-MM-dd" (ngModelChange)="getPOListByDateRangeForProductionOutput()"></nz-date-picker>
              <span class="floating-text" style="margin-left: 10px;">Start Date</span>
            </div>

            <div class="inputField">
              <nz-date-picker class="calender-input" id="picker" formControlName="endDate" [nzDisabledDate]="" 
              nzFormat="yyyy-MM-dd" (ngModelChange)="getPOListByDateRangeForProductionOutput()"></nz-date-picker>
              <span class="floating-text" style="margin-left: 10px;">End Date</span>
            </div>
    
            <div class="inputField">
                <p-multiSelect formControlName="stacker" [options]="machineNameForSearchFilter" optionLabel="MachineName"
                  [filter]="true" selectedItemsLabel="ALL" maxSelectedLabels={{machineNameForSearchFilter.length-1}}  defaultLabel="Select Stacker">
                </p-multiSelect> 
                <span class="floating-text1">MachineName</span>
            </div>
    
            <div class="inputField">
                <p-multiSelect formControlName="job_no" [options]="POListForProductionOutputSearchFilter" optionLabel="PO"
                  [filter]="true" selectedItemsLabel="ALL" maxSelectedLabels={{POListForProductionOutputSearchFilter.length-1}}  defaultLabel="Select JobNo">
                </p-multiSelect> 
                <span class="floating-text1">OrderNumber</span>
            </div>
    
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Shift</mat-label>
              <mat-select panelClass="testClass" formControlName="shift" name="Shift" >
                <mat-option value="0">ALL</mat-option>
                <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Crew</mat-label>
              <mat-select panelClass="testClass" formControlName="crew" name="Crew" >
                <mat-option value="0">ALL</mat-option>
                <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="content-top-row">
            <div style="width: 100%;">
              <button mat-flat-button color="accent" [disabled]="selectedRowsForLinkingPO.length == 0" class="btn-accent-big" 
                style="margin-left: 20px" (click)="linkOrderNumberForManualProductionOutput()">
                Link Order Number
              </button>
              <button mat-flat-button color="accent" [disabled]="ERPDataForSannad.length == 0 || !isUserHasRoleOfSanedDataPush" class="btn-accent-big" 
                style="margin-left: 20px" (click)="updatePrintStatusForProductionOutput('',false)">
                Push Data to ERP
              </button>

              <mat-slide-toggle (change)="togglePrintedAndAllDataForProductionOutput()" 
                style="margin-left: 15px; font-weight: 500;" [(ngModel)]="toggleStatus" [ngModelOptions]="{standalone: true}">
                {{toggleStatus ? 'Show All Data' : 'Show Printed Data'}}
              </mat-slide-toggle>

              <button *ngIf="!isDataAsc" [disabled]="rowProductionOutput.length == 0" mat-flat-button color="accent" class="btn-accent-big" 
                style="margin-left: 20px; float: right;" (click)="sortGridDataForActalBatch('Asc',rowProductionOutput,'ProductionOutput')">
                Sort Data Asc
              </button>

              <button *ngIf="isDataAsc" [disabled]="rowProductionOutput.length == 0" mat-flat-button color="accent" class="btn-accent-big" 
                style="margin-left: 20px; float: right;" (click)="sortGridDataForActalBatch('Desc',rowProductionOutput,'ProductionOutput')">
                Sort Data Desc
              </button>

              <button mat-mini-fab mat-button mat-dialog-close color="accent" (click)="openManualStackerModel()"  pTooltip="Add" 
                tooltipPosition="top" style="float: right;">
                <mat-icon>add</mat-icon>
              </button>

              <button type="button" mat-button mat-mini-fab color="primary" (click)="getManualProductionOutput()" pTooltip="Search Data" 
                tooltipPosition="top" style="float: right; margin-right: 10px;">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div style="width: 100%;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" 
            [rowData]="rowProductionOutput" [columnDefs]="columnDefsProductionOutput" [defaultColDef]="defaultColDef" 
            [frameworkComponents]="frameworkComponentsForProduction" (gridReady)="onGridReadyProductionOutput($event)" 
            [pagination]="true" [paginationPageSize]="50" [getRowStyle]="getRowStyle" (cellClicked)='oncellclicked($event)'
            [suppressRowClickSelection]="true" [rowSelection]="rowSelection" (selectionChanged)="rowSelectionForProductionOutput($event)"
            (firstDataRendered)="applyState('MaterialConsumptionPO-vTLU31IVZb')"
            (columnMoved)="onSaveGridState('MaterialConsumptionPO-vTLU31IVZb')" (columnVisible)="onSaveGridState('MaterialConsumptionPO-vTLU31IVZb')" 
            (columnPinned)="onSaveGridState('MaterialConsumptionPO-vTLU31IVZb')" (displayedColumnsChanged)="onSaveGridState('MaterialConsumptionPO-vTLU31IVZb')">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>

    <!-- Production Output Report -->
    <mat-expansion-panel (opened)="panelOpenState = true; getPOListByDateRangeForProductionOutputReport(true)" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2" class="disable_ripple_acc2">
        <mat-panel-title>
          Production Output Report
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ngx-spinner [ngClass]="{'display': spinnerShow == 'none', 'displayNone': spinnerShow == 'block'}" bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' /> " [fullScreen] = "false"></ngx-spinner>

      <!-- HEADER START -->
      <form [formGroup]="manualStackerFromProductionOutputReport">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">
            <div class="inputField">
              <nz-date-picker class="calender-input" id="picker" formControlName="startDate" [nzDisabledDate]=""
                nzFormat="yyyy-MM-dd" (ngModelChange)="getPOListByDateRangeForProductionOutputReport()"></nz-date-picker>
              <span class="floating-text" style="margin-left: 10px;">Start Date</span>
            </div>

            <div class="inputField">
              <nz-date-picker class="calender-input" id="picker" formControlName="endDate" [nzDisabledDate]=""
                nzFormat="yyyy-MM-dd" (ngModelChange)="getPOListByDateRangeForProductionOutputReport()"></nz-date-picker>
              <span class="floating-text" style="margin-left: 10px;">End Date</span>
            </div>

            <div class="inputField">
              <p-multiSelect formControlName="job_no"
                [options]="POListForProductionOutputReportSearchFilter" optionLabel="PO" [filter]="true"
                selectedItemsLabel="ALL" maxSelectedLabels={{POListForProductionOutputReportSearchFilter.length-1}}
                defaultLabel="Select JobNo">
              </p-multiSelect>
              <span class="floating-text1">OrderNumber</span>
            </div>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Shift</mat-label>
              <mat-select panelClass="testClass" formControlName="shift" name="Shift">
                <mat-option value="0">ALL</mat-option>
                <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Crew</mat-label>
              <mat-select panelClass="testClass" formControlName="crew" name="Crew">
                <mat-option value="0">ALL</mat-option>
                <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          

          <div class="content-top-row">
            <button type="button" mat-button mat-mini-fab color="primary" (click)="getManualProductionOutputReport()"
              pTooltip="Search Data" tooltipPosition="top" style="margin-left: 20px;">
              <mat-icon>search</mat-icon>
            </button>
            <button  mat-mini-fab mat-button color="primary" pTooltip="Get Print" tooltipPosition="top" (click)="getPrint()" 
              style="margin-left: 10px;">
              <mat-icon>print</mat-icon>
            </button>
          </div>
        </div>
        </div>
      </form>
      <!-- HEADER ENDS -->

      <!-- CARDS  -->
      <div >
        <div class="p-grid p-fluid" style="margin-top: 10px" >
          <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
            <div class="menu-items">
              <div (click)="productionOutputReportCards('Total')"
                [ngClass]="{'active-card': totalProductionCard == true && rowProductionOutputReport.length != 0,'menu-card-item': true, 'chart-items': true}">
                <p class="title-text">Total</p>
                <p class="data-text">{{totalRows}}
                </p>
                <div class="menu-bottom-icon">
                  <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
            <div class="menu-items">
              <div (click)="productionOutputReportCards('Pending')"
                [ngClass]="{'active-card': pendingProductionCard == true,'menu-card-item': true, 'chart-items': true}">
                <p class="title-text">Pending</p>
                <p class="data-text">{{pendingRows}}
                </p>
                <div class="menu-bottom-icon">
                  <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
            <div class="menu-items">
              <div (click)="productionOutputReportCards('0_Value')"
                [ngClass]="{'active-card': zeroValueProductionCard == true,'menu-card-item': true, 'chart-items': true}">
                <p class="title-text">0 & -ve Values</p>
                <p class="data-text">{{zeroValue}}
                </p>
                <div class="menu-bottom-icon">
                  <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
            <div class="menu-items">
              <div (click)="productionOutputReportCards('Printed')"
                [ngClass]="{'active-card': printedProductionCard == true,'menu-card-item': true, 'chart-items': true}">
                <p class="title-text">Printed</p>
                <p class="data-text">{{printedRows}}
                </p>
                <div class="menu-bottom-icon">
                  <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard" >
            <div class="menu-items">
              <div (click)="productionOutputReportCards('Actual_Output')"
                [ngClass]="{'active-card': actualProductionCard == true,'menu-card-item': true, 'chart-items': true}">
                <p class="title-text" style="margin-top: -20px;">Actual Output</p>
                <p class="data-text">{{actualRows}}</p>
                <p class="title-text">Actual Weight</p>
                <p class="data-text">{{actualweight}}</p>
                <div class="menu-bottom-icon">
                  <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CARDS ENDS -->

      <div class="content-top-row">
        <div style="width: 100%;">
          <button *ngIf="!isDataAscReport" [disabled]="rowProductionOutputReport.length == 0" mat-flat-button color="accent" class="btn-accent-big" 
            style="margin-left: 20px; float: right;" (click)="sortGridDataForActalBatch('Asc',rowProductionOutputReport,'')">
            Sort Data Asc
          </button>

          <button *ngIf="isDataAscReport" [disabled]="rowProductionOutputReport.length == 0" mat-flat-button color="accent" class="btn-accent-big" 
            style="margin-left: 20px; float: right;" (click)="sortGridDataForActalBatch('Desc',rowProductionOutputReport,'')">
            Sort Data Desc
          </button>
        </div>
      </div>

      <div style="width: 100%;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowProductionOutputReport"
            [columnDefs]="columnDefsProductionOutputReport" [defaultColDef]="defaultColDef"
            (gridReady)="onGridReadyProductionOutputReport($event)" [pagination]="true" [paginationPageSize]="50" 
            [getRowStyle]="getRowStyle" (firstDataRendered)="applyState('MaterialConsumptionPOR-vTLU31IVZb')"
            (columnMoved)="onSaveGridState('MaterialConsumptionPOR-vTLU31IVZb')" (columnVisible)="onSaveGridState('MaterialConsumptionPOR-vTLU31IVZb')" 
            (columnPinned)="onSaveGridState('MaterialConsumptionPOR-vTLU31IVZb')" (displayedColumnsChanged)="onSaveGridState('MaterialConsumptionPOR-vTLU31IVZb')">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- History Batch Material Consumption Modal -->
  <ng-template #historyBatch1Modal>
    <form>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ langObj ? langObj?.HistoryBatch : ('HistoryBatch' | translate) }}</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <mat-dialog-content>
        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine"
          [rowData]="rowDataMaterialBatchHistory" [columnDefs]="columnMaterialBatchHistory"
          (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
          [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-dialog-content>

    </form>
  </ng-template>

  <ng-template #manualStackerModel>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2 *ngIf="isManualProductionId==0">Add Manual Production</h2>
        <h2 *ngIf="isManualProductionId!=0">Update Manual Production</h2>        
        <button mat-mini-fab mat-button mat-dialog-close color="warn" pTisCoatedGlassooltip="Close" tooltipPosition="top">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <hr>
    <mat-dialog-content>
      <form [formGroup]="addManualStackerForProductionOutput">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">
            <mat-form-field *ngIf="isManualProductionId == 0 || (isManualProductionId != 0 && rowHasOrderNumber == false)" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>{{ langObj ? langObj?.ProcessOrderNo : ('ProcessOrderNo' | translate) }}</mat-label>
              <mat-select formControlName="OrderNumber" panelClass="testClass" (selectionChange)="isPOSelected()" [disabled]="rowHasOrderNumber">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'PO'" [array]="POListForProductionOutput"
                (filteredReturn)="filterPOListForProductionOutput = $event"></mat-select-filter>
                <mat-option *ngFor="let Po of filterPOListForProductionOutput" [value]="Po.PPDId"> {{Po.PO}} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isManualProductionId != 0 && rowHasOrderNumber == true" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Process Order No</mat-label>
              <input type="text" readonly matInput formControlName = "OrderNumber" name="OrderNumber">
            </mat-form-field>

            <div class="inputField" style="width: 18%; margin-right: 20px;">
              <nz-date-picker class="calender-input" id="picker" (ngModelChange)="getBaseDetailsForProductionOutput(false,'no')"
                formControlName="manualStackerCreationDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd" style="width: 100%;"></nz-date-picker>
              <span class="floating-text" style="margin-left: 10px;">Select Date</span>
            </div>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Select Machine</mat-label>
              <mat-select panelClass="testClass" formControlName="stacker" name="stacker">
                <mat-option *ngFor="let m of machineNameForManualProductionEntry" [value]="m.MachineName">{{m.MachineName}}</mat-option>
              </mat-select>
            </mat-form-field>
                          
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Shift</mat-label>
              <mat-select panelClass="testClass" formControlName="shift" name="Shift" required (selectionChange)="getBaseDetailsForProductionOutput(true,'no')">
                <mat-option *ngFor="let s of shiftListForManualEntry" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
    
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Crew</mat-label>
              <mat-select panelClass="testClass" formControlName="crew" name="Crew" required>
                <mat-option *ngFor="let s of crewListForManualEntry" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="content-top-row">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 18px;">
              <mat-label>General Batch Number</mat-label>
              <input type="text" readonly matInput formControlName = "GeneralBatchNumber" name="GeneralBatchNumber">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Actual Batch Number</mat-label>
              <input type="text" readonly matInput formControlName = "ActualBatchNumber" name="ActualBatchNumber">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Lite Count ERP</mat-label>
              <input type="text" readonly matInput formControlName = "LiteCountERP" name="LiteCountERP">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Result ERP</mat-label>
              <input *ngIf="isManualProductionId == 0 || (isManualProductionId != 0 && isRowPrinted == 0)" type="text" readonly matInput formControlName = "ResultERP" name="ResultERP">
              <input *ngIf="isManualProductionId != 0 && isRowPrinted == 1" type="number" matInput formControlName = "ResultERP" name="ResultERP">
            </mat-form-field>

            <mat-form-field *ngIf="isManualProductionId==0" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>No. of Pallets</mat-label>
              <input type="number" matInput formControlName = "NoOfPallets" name="NoOfPallets">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable && isManualProductionId!=0" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Size</mat-label>
              <input type="text" readonly matInput formControlName = "Size" name="Size">
            </mat-form-field>
          </div>
          <div class="content-top-row" *ngIf="isPOAvailable">
            <mat-form-field *ngIf="isPOAvailable && isManualProductionId==0" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Size</mat-label>
              <input type="text" readonly matInput formControlName = "Size" name="Size">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Thickness</mat-label>
              <input type="text" readonly matInput formControlName = "Thickness" name="Thickness">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label *ngIf="!laminationBranding">Packing Type</mat-label>
              <mat-label *ngIf="laminationBranding">PI Number</mat-label>
              <input type="text" readonly matInput formControlName = "PackingType" name="PackingType">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable && !laminationBranding" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Standard</mat-label>
              <input type="text" readonly matInput formControlName = "Standard" name="Standard">
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable && laminationBranding" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>PVB Color</mat-label>
              <mat-select panelClass="testClass" formControlName="Standard" name="Standard" required [disabled]="PVBColorFieldDisable">
                <mat-option *ngFor="let pvbC of PVBColorList" [value]="pvbC.TypeId">{{pvbC.ColorName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable && laminationBranding && isCoatedGlass" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Coated Glass Color</mat-label>
              <mat-select panelClass="testClass" formControlName="CoatedGlassColor" name="CoatedGlassColor" required disabled="true">
                <mat-option *ngFor="let pvbC of CoatedGlassColorList" [value]="pvbC.TypeId">{{pvbC.ColorName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable && (isManualProductionId!=0 || (isManualProductionId==0 && !isCoatedGlass))" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Branding</mat-label>
              <mat-select panelClass="testClass" formControlName = "Branding" name="Branding" required [disabled]="!laminationBranding">
                <mat-option value="N">N</mat-option>
                <mat-option value="B">B</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isPOAvailable && isManualProductionId!=0 && !isCoatedGlass" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Quality</mat-label>
              <input type="text" readonly matInput formControlName = "Quality" name="Quality">
            </mat-form-field>
          </div>
          <div class="content-top-row">
            <div style="width: 100%;">
              <mat-form-field *ngIf="isPOAvailable && isCoatedGlass && isManualProductionId==0" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Branding</mat-label>
                <mat-select panelClass="testClass" formControlName = "Branding" name="Branding" required [disabled]="!laminationBranding">
                  <mat-option value="N">N</mat-option>
                  <mat-option value="B">B</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="isPOAvailable && (isManualProductionId==0 || (isCoatedGlass && isManualProductionId!=0))" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Quality</mat-label>
                <input type="text" readonly matInput formControlName = "Quality" name="Quality">
              </mat-form-field>

              <button *ngIf="isManualProductionId==0" (click)="submitManualProductionOutput()" type="submit" 
                [disabled]="addManualStackerForProductionOutput.invalid || isClicked" mat-mini-fab mat-button color="primary" pTooltip="Save" 
                tooltipPosition="top" style="float: right; margin-right: 30px;">
                <mat-icon>save</mat-icon>
              </button>

              <button *ngIf="isManualProductionId!=0" (click)="submitManualProductionOutput()" type="submit" 
                [disabled]="addManualStackerForProductionOutput.invalid || isClicked" mat-mini-fab mat-button color="primary" pTooltip="Update"
                tooltipPosition="top" style="float: right; margin-right: 30px;">
                <mat-icon>edit</mat-icon>
              </button>

              <button (click)="resetManualStackerForProductionOutputForm()" mat-mini-fab mat-button color="primary" 
                pTooltip="Reset" tooltipPosition="top" style="float: right; margin-right: 10px;">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  
  </ng-template>

  <ng-template #OrderNumberLinkingModel>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Link Order Number to Manual Production</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <hr>
    <mat-dialog-content>
      <form [formGroup]="linkingOrderNumberForProductionOutput">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 30%;">
              <mat-label>{{ langObj ? langObj?.ProcessOrderNo : ('ProcessOrderNo' | translate) }}</mat-label>
              <mat-select formControlName="OrderNumber" panelClass="testClass" (selectionChange)="isBrandingNeedToEnter()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'PO'" [array]="linkingPOListForProductionOutput"
                (filteredReturn)="filterLinkingPOListForProductionOutput = $event"></mat-select-filter>
                <mat-option *ngFor="let Po of filterLinkingPOListForProductionOutput" [value]="Po.PPDId"> {{Po.PO}} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="!laminationBranding" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Branding</mat-label>
              <mat-select panelClass="testClass" formControlName = "Branding" name="Branding">
                <mat-option value="N">N</mat-option>
                <mat-option value="B">B</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="!laminationBranding" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>PVB Color</mat-label>
              <mat-select panelClass="testClass" formControlName="PVBColorCode" name="PVBColorCode" [disabled]="PVBColorFieldDisable">
                <mat-option *ngFor="let pvbC of PVBColorList" [value]="pvbC.TypeId">{{pvbC.ColorName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="!laminationBranding && isCoatedGlass" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Coated Glass Color</mat-label>
              <mat-select panelClass="testClass" formControlName="CoatedGlassColor" name="CoatedGlassColor" required disabled="true">
                <mat-option *ngFor="let pvbC of CoatedGlassColorList" [value]="pvbC.TypeId">{{pvbC.ColorName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <button type="submit" (click)="submitOrderNumberForProductionOutput()"
              [disabled]="linkingOrderNumberForProductionOutput.invalid || isClicked" mat-mini-fab mat-button color="primary" pTooltip="Save" 
              tooltipPosition="top" style="margin-left: 20px;">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  
  </ng-template>
</div>

<div class="header1" style="display: none;">
  <div class="header">
    <h2 style="color: white; padding-top: 15px; padding-left: 5px;">Production Output Report</h2>
  </div>
  <table class="headerTable" >
    <tr style="height: 60px;">
      <td><p class="filters">Start Date:  {{startDate}}</p></td>
      <td><p class="filters">End Date:  {{endDate}}</p></td>
      <td><p class="filters">Shift:  {{shift}}</p></td>
      <td><p class="filters">Crew:  {{crew}}</p></td>
    </tr>
  </table>
</div>

<div id="print" style="display: none;">
  <table style="width: 100%;">
    <tr class="thRow">
      <th>Date</th>
      <th>Shift</th>
      <th>Crew</th>
      <th>OrderNumber</th>
      <th>MachineName</th>
      <th>GeneralBatch Number</th>
      <th>ActualBatch Number</th>
      <th>Lite Count ERP</th>
      <th>Result ERP</th>
      <th>CreatedOn</th>
    </tr>
    <div style="display: table-row-group;" *ngFor="let row of rowProductionOutputReport">
      <tr [ngClass]="{'resultRow': row.printStatus == true, 'tdRow': row.printStatus == false}">
        <td>{{row.Date}}</td>
        <td>{{row.Shift}}</td>
        <td>{{row.Crew}}</td>
        <td>{{row.OrderNumber}}</td>
        <td>{{row.StackerName}}</td>
        <td>{{row.GeneralBatchNo}}</td>
        <td>{{row.ActualBatchNo}}</td>
        <td>{{row.LiteCountErp}}</td>
        <td>{{row.ResultErp}}</td>
        <td>{{row.CreatedOn}}</td>
      </tr>
    </div>
  </table>
</div>