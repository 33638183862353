import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Pillar } from 'src/app/models/pillar';
import { AuditCategoryService } from 'src/app/services/audit-category.service';
import { GembaWalkService } from 'src/app/services/gemba-walk.service';
import { DefectActionService } from 'src/app/services/defect-action.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { GlobalStandardService } from 'src/app/services/global-standard.service';
import { AreaService } from 'src/app/services/area.service';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { CheckboxRenderer } from 'src/app/CheckboxRenderer';

import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
// import { environment } from 'src/environments/environment';
import * as _moment from 'moment';
import { CheckboxIsWrite } from 'src/app/checkboxIsWrite';
import { DefectConfigurationService } from 'src/app/services/defect-configuration.service';
import { environment } from 'src/environments/environment';
const moment = _moment;
interface ActionInterface {
  action: any;
  target: any;
  newtarget: any;
  misses: any;
  responsible: any;
  completed: any;
}

@Component({
  selector: 'app-UsersActions',
  templateUrl: './UsersActions.component.html',
  styleUrls: ['./UsersActions.component.scss'],
})
export class UsersActionsComponent implements OnInit {
  moduleId!: any;
  pageId!: any;
  usersActionsForm!: FormGroup;
  gembaDataForm!: FormGroup;
  PictureBefore!: FormGroup;
  pictureAfter!: FormGroup;
  rejectedRemarksForm!: FormGroup;
  owners!: any[];
  UserId!: any;
  fileSave!: File;
  file2Save!: File;
  completeRem!: any;
  rejectRem!: any;
  GembaId!: any;
  GembaActionId!: any;
  AreaName!: any;
  OwnerName!: any;
  completedObs!: any;
  FormMode!: boolean;
  columnDefs: any;
  ownersList!: any;
  icons!: any;
  startDate = new Date(new Date().getTime() - 3888000000);
  endDate = new Date(new Date().getTime() + 3888000000);
  ownerslist!: any;
  cellData: any;
  cellData2: any;
  startYear = new Date(new Date().getTime() - 3888000000);
  endYear!: any;
  ActionArray: ActionInterface[] = [];
  auditClassifications!: any[];
  dmsClassification!: any[];
  ButtonType!: any;
  observationDataForm!: FormGroup;
  SourceType!: string;
  completionCheck!: boolean;
  picAddedAndRemSaved!: boolean;
  plants!: any;
  area!: any[];
  responsibleForm!: FormGroup;
  groups!: any[];
  loginRoleName!: any;
  gembaData!: MatTableDataSource<any>;
  pillarData!: MatTableDataSource<Pillar>;
  // ActionDataSource!: MatTableDataSource<any>;
  globalColumns: string[] = ['plant', 'area', 'type', 'name', 'file'];
  actionColumns: string[] = [
    'action',
    'target',
    'newtarget',
    'misses',
    'responsible',
    'completed',
    'remove',
  ];
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      checkboxRenderer: CheckboxRenderer,
      checkboxIsWrite: CheckboxIsWrite,
    },
  };
  rowData!: any[];
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  image: any;
  CompleteRemarks: any;
  RejectRemarks: any;
  currentImagePath: any;
  departmentList: any;
  responsibleUser: any;
  generalCategoryList: any;
  raci!: any;
  gembaWalkId: any;

  rows!: FormArray;
  gembaActionform!: FormGroup;
  actionDataSource = new BehaviorSubject<AbstractControl[]>([]); // new MatTableDataSource();
  raciUsers!: any;
  previewerData!: MatTableDataSource<any>;
  previewerColumns: string[] = ['Desc', 'Action'];
  displayMaximizable = false;
  images: any[] = [];
  fileType!: string;
  imageIndexOne = 0;
  fileDesc: any;
  doc!: any;
  imageUrl: string;
  // gembaActionform!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userService: UserManagementService,
    private toaster: ToastrService,
    private auditService: AuditCategoryService,
    private areaService: AreaService,
    private globalService: GlobalStandardService,
    private gembaService: GembaWalkService,
    private defectService: DefectActionService,
    private defectConfigService: DefectConfigurationService,
    private spinner: NgxSpinnerService,
    private machineService: MachineMappingService,
    private kpiMaster: KpimasterService,
    private departmentService: MasterDepartmentService,
    private notificationService: NotificationService,
    public router: Router,
  ) {}
  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('displayRoleModal2') private displayRoleModal2!: TemplateRef<any>;
  @ViewChild('displayRoleModal3') private displayRoleModal3!: TemplateRef<any>;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('imageZoomModel2') private imageZoomModel2!: TemplateRef<any>;
  @ViewChild('previewModal') private previewModal!: TemplateRef<any>;

  ngOnInit() {
    this.imageUrl = environment.O3CoreApiImageUrl;
    this.GetBreadcrumList();
    this.GetMenuAccess();
    //this.getLangWords();
    this.usersActionsForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });

    this.gembaDataForm = this.fb.group({
      area: ['', Validators.required],
      owner: ['', Validators.required],
      // startdate: new Date((new Date().getTime() - 3888000000)),
      // enddate: new Date(),
      startdate: [''],
      enddate: [''],
    });
    this.gembaActionform = this.fb.group({
      actionsArray: this.fb.array([this.actionForm()]),
    });
    this.rows = this.gembaActionform.get('actionsArray') as FormArray;
    this.completionCheck = true;
    this.picAddedAndRemSaved = false;
    let d = new Date();
    d.setDate(d.getDate() - 7); // setMonth(d.getMonth() - 3);
    this.gembaDataForm.get('startdate')?.setValue(d);
    this.gembaDataForm.get('enddate')?.setValue(new Date());

    this.observationDataForm = this.fb.group({
      observe: ['', Validators.required],
      classification: ['', Validators.required],
      fivesclassification: ['', Validators.required],
      department: ['', Validators.required],
      responsibleUser: ['', Validators.required],
      generalCategories: [''],
      image: [''],
      CompleteRemarks: [''],
      save: [''],
    });

    this.responsibleForm = this.fb.group({
      owner: [''],
    });

    this.pictureAfter = this.fb.group({
      image: [''],
      CompleteRemarks: [''],
    });
    this.rejectedRemarksForm = this.fb.group({
      RejectRemarks: [''],
    });

    this.loginRoleName = JSON.parse(localStorage.roleName);

    for (var i in this.loginRoleName) {
      this.loginRoleName[i] = this.loginRoleName[i].toString().toLowerCase();
    }

    this.UserId = localStorage.getItem('user');
    this.getGroups();
  }

  getUserList(): void {
    const key = {
      egId: this.usersActionsForm.value.group,
    };
    this.userService.getRaciUsers(key).subscribe(
      (users) => {
        this.raciUsers = users;
      },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }

  onAreaChange(event: any): void {
    this.getOwner();
    this.area.map((x) => {
      if (x.areaId === event.value) {
        this.AreaName = x.areaName;
      }
    });
  }

  onOwnerChange(event: any): void {
    this.owners.map((x) => {
      if (x.userId === event.value) {
        this.OwnerName = x.userName;
      }
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.gembaData.paginator = this.paginator;
      this.gembaData.sort = this.sort;
    }, 1000);
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    const id = {
      value: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.usersActionsForm.get('group')?.setValue(data.masterList[0].TypeId);
        this.groups = data.masterList;
        id.value = data.masterList[0].TypeId;
        this.getPlant(id);
        this.rowData = [];
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  onPlantChange(event: any): void {
    this.getArea();
    this.rowData = [];
  }

  getAllUsersActions(): void {
    const key = {
      userId: JSON.parse(localStorage.user).UserId,
      fromDate: moment(this.gembaDataForm.value.startdate).format('YYYY-MM-DD'),
      toDate: moment(this.gembaDataForm.value.enddate).format('YYYY-MM-DD'),
      ptId: this.usersActionsForm.value.plant,
    };
    this.spinner.show();
    this.gembaService.getUsersActions(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.rowData = data;
      },
      (error: any) => {
        this.toaster.error('error', error.error.Message);
        this.spinner.hide();
      }
    );
  }

  getPlant(event: any): void {
    const key = {
      GroupId: event.value,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.usersActionsForm.get('plant')?.setValue(data.DepartList[0].DeptId);
        // this.getArea();
        this.getAllUsersActions();
        this.rowData = [];
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getArea(): void {
    //debugger;
    const key = {
      plantId: this.usersActionsForm.value.plant,
    };
    const urole = JSON.parse(localStorage.roleName).map((v: any) =>
      v.toString().toLowerCase()
    );
    const uid = JSON.parse(localStorage.user).UserId;
    this.areaService.getArea(key).subscribe(
      (data: any) => {
        this.area = data;
        const obj = {
          areaId: 0,
          areaName: 'All',
        };
        this.area.unshift(obj);
        this.gembaDataForm.get('area')?.setValue(this.area[0].areaId);
        const key2 = {
          value: this.gembaDataForm.value.area,
        };
        this.onAreaChange(key2);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getOwner(): void {
    //debugger;
    const a = this.area.map((x) => {
      return x.areaId;
    });
    const key = {
      egId: this.usersActionsForm.value.group,
      areaId: this.gembaDataForm.value.area,
      ptId: this.usersActionsForm.value.plant,
      areaIds: a,
    };

    this.userService.GetUsersByArea(key).subscribe(
      (data: any): any => {
        this.owners = data;
        this.ownersList = this.owners.map((x) => {
          return x.userName;
        });

        this.gembaDataForm.get('owner')?.setValue(data[0].userId);
        const key = {
          value: this.gembaDataForm.value.owner,
        };
        this.onOwnerChange(key);
        // this.getAllUsersActions();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onFileChange(event: any): void {
    const formData = new FormData();
    if (this.cellData2.isReqPicAfter === true) {
      if (
        this.file2Save === undefined ||
        this.file2Save == null
        //Completed remarks are not mandatory || this.completeRem === undefined
      ) {
        this.toaster.error('Error', 'Picture After is required');
        return;
      }
    }

    formData.append('file', this.file2Save);
    formData.append('remarks', this.completeRem === undefined ? 'Completed! ' : this.completeRem);
    this.cellData = {
      gembaActionId: this.cellData2.gembaActionId,
    };
    formData.append('gemba', JSON.stringify(this.cellData));
    if (this.SourceType == 'gembawalk') {
      this.gembaService.setGembaActionImage(formData).subscribe(
        (data: any) => {
          this.toaster.success(
            'Picture After Added and Remarks saved Successfuly'
          );
          this.picAddedAndRemSaved = true;
          event.target.value = null;
          this.file2Save = event.target.files;
          this.setCheckedActions();
          this.pictureAfter.reset();
          this.dialog.closeAll();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.Message);
          this.getAllUsersActions();
        }
      );
    } else {
      this.defectService.setGembaActionImage(formData).subscribe(
        (data: any) => {
          this.toaster.success(
            'Picture After Added and Remarks saved Successfuly'
          );
          this.picAddedAndRemSaved = true;
          event.target.value = null;
          this.file2Save = event.target.files;
          this.setDefectCheckedActions();
          this.dialog.closeAll();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.Message);
          this.getAllUsersActions();
        }
      );
    }
  }
  dialogClose() {
    this.pictureAfter.reset();
  }

  setCheckedActions(): void {
    if (this.picAddedAndRemSaved === true) {
      this.gembaService.setUsersActionsCompleted(this.cellData2).subscribe(
        (data): any => {
          this.sendNotificationRole(
            'Gemba Walk Action completed against this Observation:' +
            this.completedObs,
            'Gemba Walk'
          );
          this.toaster.success('Compeleted successfully');
          this.getAllUsersActions();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
          this.getAllUsersActions();
        }
      );
    }
  }

  setDefectCheckedActions(): void {
    if (this.picAddedAndRemSaved === true) {
      this.defectService.setUsersActionsCompleted(this.cellData2).subscribe(
        (data): any => {
          this.sendNotificationRole(
            'Defect Action completed against this Observation:' +
            this.completedObs,
            'Defect'
          );
          this.toaster.success('Compeleted successfully');
          this.getAllUsersActions();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
          this.getAllUsersActions();
        }
      );
    }
  }

  setRejectActions(): void {
    if (this.picAddedAndRemSaved === true) {
      this.gembaService.setUsersActionsRejected(this.cellData2).subscribe(
        (data): any => {
          this.sendNotificationRole(
            'Gemba Walk Action Rejected against this Observation:' +
            this.completedObs,
            'Gemba Walk'
          );
          this.toaster.success('Rejected successfully');
          this.getAllUsersActions();
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  setDefectRejectActions(): void {
    if (this.picAddedAndRemSaved === true) {
      this.defectService.setUsersActionsRejected(this.cellData2).subscribe(
        (data): any => {
          this.sendNotificationRole(
            'Defect Action Rejected against this Observation:' +
            this.completedObs,
            'Defect'
          );
          this.toaster.success('Rejected successfully');
          this.getAllUsersActions();
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  onFileChange2(): void {
    if (!this.rejectedRemarksForm.value.RejectRemarks) {
      this.toaster.error('Error', 'remarks are required');
      return;
    }
    this.cellData = {
      gembaActionId: this.cellData2.gembaActionId,
      updatedBy: JSON.parse(localStorage.user).UserId,
      gembaWalkId: this.cellData2.gembaWalkId,
      remarks: this.rejectedRemarksForm.value.RejectRemarks,
    };
    if (this.SourceType == 'gembawalk') {
      this.gembaService.setActionRemarks(this.cellData).subscribe(
        (data: any) => {
          this.toaster.success('Remarks saved Successfuly');
          this.picAddedAndRemSaved = true;
          this.setRejectActions();
          this.dialog.closeAll();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
          this.getAllUsersActions();
        }
      );
    } else {
      this.defectService.setActionRemarks(this.cellData).subscribe(
        (data: any) => {
          this.toaster.success('Remarks saved Successfuly');
          this.picAddedAndRemSaved = true;
          this.setDefectRejectActions();
          this.dialog.closeAll();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.getAllUsersActions();
        }
      );
    }
  }

  uploadedFile2(event: any): void {
    if (event.target.files.length > 0) {
      this.file2Save = event.target.files[0];
    }
  }

  addCompleteRemarks2(event: any): void {
    if (this.pictureAfter.value.CompleteRemarks !== '') {
      this.completeRem =
        'Completed! ' + this.pictureAfter.value.CompleteRemarks;
    }
  }

  addRejectRemarks2(event: any): void {
    if (this.rejectedRemarksForm.value.RejectRemarks !== '') {
      this.rejectRem =
        'Rejected! ' + this.rejectedRemarksForm.value.RejectRemarks;
    }
  }

  imageZoomModal(params: any): void {
    const col = params.colDef.field;
    if (col === 'pictureAfter') {
      this.image = params.data.PictureAfter;
      this.openPictureAfterModal();
    }
    if (col === 'PictureBefore') {
      this.image = params.data.PictureBefore;
      this.openPictureBeforeModal(params);
    }
  }

  openPictureAfterModal(): void {
  this.currentImagePath = this.image.includes('/null') ? '' : this.imageUrl + this.image;
    this.pictureAfter.reset();
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  openPictureBeforeModal(params: any): void {
    if (params.data.Source === 'Defect') {
      this.currentImagePath = this.image === ''
        ? 'assets/images/no-image.png' : this.image;
      this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
    }

    if (params.data.Source === 'GembaWalk') {
      this.currentImagePath = this.image.includes('/null') ? '' : this.image;
      this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
    }
  }

  openFileModal(): void {
    let dialog  = this.dialog.open(this.displayRoleModal2, { height: '60%', width: '70%' });
    dialog.afterClosed().subscribe(() => {
      this.getAllUsersActions();
    })
  }

  openFileModal2(): void {
    this.rejectedRemarksForm.reset();
    let dialog  = this.dialog.open(this.displayRoleModal3, { height: '60%', width: '70%' });
    dialog.afterClosed().subscribe(() => {
      this.getAllUsersActions();
    })
  }

  cellClicked(params: any): void {
    this.ButtonType = '';
    const col = params.colDef.field;
    this.SourceType = params.data.Source.toString().toLowerCase();
    this.GembaId = params.data.gembaId;
    this.GembaActionId = params.data.gembaActionId;
    this.ButtonType = params.event.target.classList[1];
    if (col === 'pictureAfter') {

      this.image = params.data.PictureAfter;
      if(this.image != null && this.image != '')
        this.openPictureAfterModal();
    }
    if (col === 'PictureBefore') {
      this.image = params.data.PictureBefore;
      if (this.ButtonType === 'fa-file') {
        this.openFilesModal(params.data);
      }
    }
  }

  openResponsibleModal(): void {
    this.dialog.open(this.displayRoleModal3, { height: 'auto', width: '30%' });
    this.getUserList();
  }

  extractValues(mappings: any): any {
    if (mappings) {
      return Object.keys(mappings);
    }
  }

  sendNotificationRole(descr: any, title: any): void {
    const key = {
      roleName: 'Section Manager',
      createdBy: JSON.parse(localStorage.user).UserId,
      egId: this.usersActionsForm.value.group,
      title,
      desc: descr,
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => {
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  sendUserNotification(users: any, descr: any): void {
    const key = {
      userId: users,
      desc: descr,
      title: 'Action Done',
      createdBy: JSON.parse(localStorage.user).UserId,
    };
    this.notificationService
      .addnotificationuserwise(key)
      .subscribe((data: any): any => { });
  }

  onCellValueChanged(params: any): void {
    const col = params.colDef.field;
    const userRole = JSON.parse(localStorage.roleName);
    const resId = JSON.parse(localStorage.user).UserId;
    this.picAddedAndRemSaved = false;
    this.GembaId = params.data.TableId;
    this.SourceType = params.data.Source;
    this.GembaActionId = params.data.ActionId;
    // tslint:disable-next-line: forin
    for (let i in userRole) {
      userRole[i] = userRole[i].toString().toLowerCase();
    }
    if (this.SourceType.toString().toLowerCase() === 'gembawalk') {
      if (col === 'IsCompleted') {
        if (
          params.data.UserRole === 'section manager' || params.data.UserRole === 'owner' ||
          params.data.ResponsibleId === resId
        ) {
          this.cellData2 = {
            gembaWalkId: params.data.TableId,
            gembaActionId: params.data.ActionId,
            isCompleted: params.data.IsCompleted,
            updatedBy: JSON.parse(localStorage.user).UserId,
            isReqPicAfter: params.data.IsRequiredPictureAfter,
          };
          if (params.data.IsCompleted === true) {
            //debugger;
            this.completedObs = params.data.Observation;
            this.file2Save = params.event;
            this.openFileModal();
            // this.getAllUsersActions();
          } else {
            this.gembaService
              .setUsersActionsCompleted(this.cellData2)
              .subscribe(
                (data): any => {
                  this.sendNotificationRole(
                    'Gemba Walk Action ' +
                    (params.value === false ? 'Incomplete' : 'Complete') +
                    '  against this Observation:' +
                    params.data.Observation,
                    'Gemba Walk'
                  );
                  this.toaster.success('Unchecked successfully');
                  this.getAllUsersActions();
                },
                (error) => {
                  this.toaster.error('Error', error.error.Message);
                  this.getAllUsersActions();
                }
              );
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
      if (col === 'IsVerified') {
        if (params.data.UserRole === 'section manager' || params.data.UserRole === 'owner') {
          if (
            params.data.IsCompleted === true ||
            params.data.IsRejected === true
          ) {
            this.cellData = {
              gembaWalkId: params.data.TableId,
              gembaActionId: params.data.ActionId,
              isVerified: params.data.IsVerified,
              updatedBy: JSON.parse(localStorage.user).UserId,
            };
            this.gembaService.setUsersActionsVerified(this.cellData).subscribe(
              (data): any => {
                // tslint:disable-next-line:max-line-length
                this.sendNotificationRole(
                  'Gemba Walk Action ' +
                  (params.value === false ? 'UnVerify' : 'Verify') +
                  '  against this Observation:' +
                  params.data.Observation,
                  'Gemba Walk'
                );
                this.sendUserNotification(
                  params.value.ResponsibleId.toString(),
                  'Gemba Walk Action ' +
                  (params.value === false ? 'UnVerify' : 'Verify') +
                  '  against this Observation:' +
                  params.data.Observation
                );
                this.toaster.success(
                  (params.value === false ? 'UnVerified' : 'Verified') +
                  ' Successfully'
                );
                this.getAllUsersActions();
              },
              (error) => {
                this.toaster.error('Error', error.message);
                this.getAllUsersActions();
              }
            );
          } else {
            this.toaster.warning('First Complete or reject then verify it');
            this.getAllUsersActions();
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
      if (col === 'IsRejected') {
        if (
          params.data.UserRole === 'section manager' || params.data.UserRole === 'owner' ||
          params.data.ResponsibleId === resId
        ) {
          this.cellData2 = {
            gembaWalkId: params.data.TableId,
            gembaActionId: params.data.ActionId,
            isRejected: params.data.IsRejected,
            updatedBy: JSON.parse(localStorage.user).UserId,
          };
          if (params.data.IsRejected === true) {
            this.completedObs = params.data.Observation;
            this.openFileModal2();
            // this.getAllUsersActions();
          } else {
            this.gembaService.setUsersActionsRejected(this.cellData2).subscribe(
              (data): any => {
                this.getAllUsersActions();
                // tslint:disable-next-line:max-line-length
                this.sendNotificationRole(
                  'Gemba Walk Action ' +
                  (params.value === false ? 'Undenied' : 'Rejected') +
                  '  against this Observation:' +
                  params.data.Observation,
                  'Gemba Walk'
                );
                this.toaster.success('Unchecked successfully');
              },
              (error) => {
                this.getAllUsersActions();
                this.toaster.error('Error', error.message);
              }
            );
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
      if (col === 'NewTargetDate') {
        if (
          params.data.UserRole === 'section manager' || params.data.UserRole === 'owner' ||
          params.data.ResponsibleId === resId
        ) {
          const newTarget = new Date(
            this.convertDate(params.data.NewTargetDate).replace(/-/g, '/')
          );
          const target = new Date(
            this.convertDate(params.data.TargetDate).replace(/-/g, '/')
          );
          const compareDate = new Date();
          if (
            newTarget > target &&
            (newTarget.getDate() === compareDate.getDate() ||
              newTarget >= compareDate)
          ) {
            let dateObject;

            if (params.data.NewTargetDate) {
              dateObject = this.convertDate(params.data.NewTargetDate);
            }
            this.cellData = {
              observationStatus: params.data.Source,
              newTargetDate: moment(dateObject).format('YYYY-MM-DD'),
              gembaActionId: params.data.ActionId,
              updatedBy: JSON.parse(localStorage.user).UserId,
              gembaWalkId: params.data.TableId,
            };
            this.gembaService.setNewTargetDates(this.cellData).subscribe(
              (data): any => {
                this.toaster.success('New Target Date Successfuly added');
                this.getAllUsersActions();
              },
              (error) => {
                this.toaster.error('Error', error.error.message);
              }
            );
          } else {
            this.toaster.warning(
              'New Target Date must be greater then Target date or must be a future date.'
            );
            this.getAllUsersActions();
            // params.data.newTargetDate='';
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
    } else {
      if (col === 'IsCompleted') {
        if (
          params.data.UserRole === 'section manager' || params.data.UserRole === 'owner' ||
          params.data.ResponsibleId === resId
        ) {
          this.cellData2 = {
            gembaWalkId: params.data.TableId,
            gembaActionId: params.data.ActionId,
            isCompleted: params.data.IsCompleted,
            updatedBy: JSON.parse(localStorage.user).UserId,
            isReqPicAfter: params.data.IsRequiredPictureAfter,
          };
          if (params.data.IsCompleted === true) {
            this.completedObs = params.data.Observation;
            this.openFileModal();
            // this.getAllUsersActions();
          } else {
            this.defectService
              .setUsersActionsCompleted(this.cellData2)
              .subscribe(
                (data): any => {
                  // tslint:disable-next-line: max-line-length
                  this.sendNotificationRole(
                    'Defect Action ' +
                    (params.value === false ? 'Incomplete' : 'Complete') +
                    ' against this defect:' +
                    params.data.Observation,
                    'Defect'
                  );
                  this.toaster.success('Unchecked successfully');
                  this.getAllUsersActions();
                },
                (error) => {
                  this.toaster.error('Error', error.error.message);
                  this.getAllUsersActions();
                }
              );
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
      if (col === 'IsVerified') {
        if (params.data.UserRole === 'section manager' || params.data.UserRole === 'owner') {
          if (
            params.data.IsCompleted === true ||
            params.data.IsRejected === true
          ) {
            this.cellData = {
              gembaWalkId: params.data.TableId,
              gembaActionId: params.data.ActionId,
              isVerified: params.data.IsVerified,
              updatedBy: JSON.parse(localStorage.user).UserId,
            };
            this.defectService.setUsersActionsVerified(this.cellData).subscribe(
              (data): any => {
                // tslint:disable-next-line:max-line-length
                this.sendNotificationRole(
                  'Defect Action ' +
                  (params.value === false ? 'UnVerify' : 'Verify') +
                  '  against this defect:' +
                  params.data.Observation,
                  'Defect'
                );
                this.sendUserNotification(
                  params.value.ResponsibleId.toString(),
                  'Defect Action ' +
                  (params.value === false ? 'UnVerify' : 'Verify') +
                  '  against this Defect:' +
                  params.data.Observation
                );
                this.toaster.success(
                  (params.value === false ? 'UnVerified' : 'Verified') +
                  ' Successfully'
                );
                this.getAllUsersActions();
              },
              (error) => {
                this.toaster.error('Error', error.message);
                this.getAllUsersActions();
              }
            );
          } else {
            this.toaster.warning('First Complete or Reject then verify it');
            this.getAllUsersActions();
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
      if (col === 'IsRejected') {
        if (
          params.data.UserRole === 'section manager' || params.data.UserRole === 'owner' ||
          params.data.ResponsibleId === resId
        ) {
          this.cellData2 = {
            gembaWalkId: params.data.TableId,
            gembaActionId: params.data.ActionId,
            isRejected: params.data.IsRejected,
            updatedBy: JSON.parse(localStorage.user).UserId,
          };
          if (params.data.IsRejected === true) {
            this.completedObs = params.data.Observation;
            this.openFileModal2();
            // this.getAllUsersActions();
          } else {
            this.defectService
              .setUsersActionsRejected(this.cellData2)
              .subscribe(
                (data): any => {
                  // tslint:disable-next-line:max-line-length
                  this.sendNotificationRole(
                    'Defect Action ' +
                    (params.value === false ? 'Undenied' : 'Rejected') +
                    '  against this defect:' +
                    params.data.Observation,
                    'Defect'
                  );
                  this.toaster.success('Unchecked successfully');
                },
                (error) => {
                  this.toaster.error('Error', error.message);
                }
              );
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
      if (col === 'NewTargetDate') {
        if (
          params.data.UserRole === 'section manager' || params.data.UserRole === 'owner' ||
          params.data.ResponsibleId === resId
        ) {
          const newTarget = new Date(
            this.convertDate(params.data.NewTargetDate).replace(/-/g, '/')
          );
          const target = new Date(
            this.convertDate(params.data.TargetDate).replace(/-/g, '/')
          );
          const compareDate = new Date();
          if (
            newTarget > target &&
            (newTarget.getDate() === compareDate.getDate() ||
              newTarget >= compareDate)
          ) {
            let dateObject;

            if (params.data.NewTargetDate) {
              dateObject = this.convertDate(params.data.NewTargetDate);
            }
            this.cellData = {
              observationStatus: params.data.Source,
              newTargetDate: moment(dateObject).format('YYYY-MM-DD'),
              gembaActionId: params.data.ActionId,
              updatedBy: JSON.parse(localStorage.user).UserId,
              defectTransactionId: params.data.TableId,
            };
            this.defectService.setNewTargetDates(this.cellData).subscribe(
              (data): any => {
                this.toaster.success('New Target Date Successfuly added');
                this.getAllUsersActions();
              },
              (error) => {
                this.toaster.error('Error', error.error.message);
              }
            );
          } else {
            this.toaster.warning(
              'New Target Date must be greater then Target date or must be a future date.'
            );
            this.getAllUsersActions();
            // params.data.newTargetDate='';
          }
        } else {
          this.toaster.warning('You do not have access to perform this action');
          this.getAllUsersActions();
        }
      }
    }
  }

  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        this.columnDefs = [
          {
            field: 'ActionId',
            sortable: true,
            width: 100,
            filter: true,
            resizable: true,
            wrapText: true,
            pinned: 'left',
            hide: 'true',
            cellStyle: (params: any) => {
              return { backgroundColor: params.data.FrequecncyColor };
            },
          },
          {
            field: 'TableId',
            sortable: true,
            width: 130,
            filter: true,
            resizable: true,
            hide: 'true',
            wrapText: true,
            pinned: 'left',
          },
          {
            headerName: 'Source',
            field: 'Source',
            width: 130,
            sortable: false,
            filter: false,
            resizable: true,
            wrapText: true,
            pinned: 'left',
          },
          {
            headerName: 'Area',
            field: 'AreaName',
            width: 140,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            pinned: 'left',
          },
          {
            headerName: 'Action',
            field: 'ActionName',
            width: 140,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            pinned: 'left',
          },
          {
            field: 'ResponsibleId',
            sortable: true,
            width: 100,
            filter: true,
            resizable: true,
            wrapText: true,
            pinned: 'left',
            hide: 'true',
            cellStyle: (params: any) => {
              return { backgroundColor: params.data.FrequecncyColor };
            },
          },
          {
            headerName: 'Responsible',
            field: 'ResponsibleName',
            width: 140,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            pinned: 'left',
          },
          // tslint:disable-next-line:max-line-length
          // {
          //   headerName: 'Source',
          //   field: 'Source',
          //   width: 130,
          //   sortable: false,
          //   filter: false,
          //   resizable: true,
          //   wrapText: true,
          //   pinned: 'left',
          // },
          {
            headerName: 'Observation',
            field: 'Observation',
            width: 140,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            pinned: 'left',
          },
          {
            headerName: 'ObservationStatus',
            field: 'ObservationStatus',
            width: 140,
            sortable: true,
            filter: true,
            hide: 'true',
          },
          {
            headerName: 'Remarks',
            field: 'Remarks',
            width: 140,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'TargetDate',
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            width: 150,
          },
          {
            field: 'NewTargetDate',
            sortable: true,
            width: 160,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',

            editable: this.IsWrite ? (params: any) =>
              (params.data.IsCompleted === false ||
                params.data.IsCompleted === null) &&
              (params.data.IsRejected === false ||
                params.data.IsRejected === null) &&
              (params.data.IsVerified === false || params.data.IsVerified === null) : false,
            filterParams: {
              comparator: myDateComparator,
            },
            cellStyle: this.IsWrite ? {
              color: 'black',
              'background-color': '#EDF1E5',
            } : '',
          },
          {
            field: 'NoOfMisses',
            sortable: true,
            width: 150,
            filter: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'PictureBefore',
            headerName: 'Picture Before',
            width: 120,
            sortable: true,
            resizable: true,
            // cellRenderer: (params: any): any => {
            //   debugger;
            //   if (params.data.Source === 'Defect') {
            //     return params.data.PictureBefore?.includes('/null')
            //       ? '<img style="height:40px;width:40px" src="assets/images/no-image.png" />'
            //       : '<img style="height:40px;width:40px" src="' + environment.O3ImageUrl +
            //       params.data.PictureBefore +
            //       '" />';
            //   }
            //   if (params.data.Source === 'GembaWalk') {
            //     return params.data.PictureBefore?.includes('/null') || params.data.PictureBefore === ''
            //       ? '<img style="height:40px;width:40px" src="assets/images/no-image.png" />'
            //       : '<img style="height:40px;width:40px" src="' +
            //       params.data.PictureBefore +
            //       '" />';
            //   }
            // },
            cellRenderer: (params: any) => {
              return `
              <div>
                <button mat-mini-fab="" color="primary" ptooltip="Send for Approval" title="SendforApproval" tooltipposition="top" class="mat-focus-indicator sortactionApproval mat-mini-fab mat-button-base mat-primary" style="background-color: green;" ng-reflect-color="primary" ng-reflect-text="Action Approval" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
              </div>
              `;
            },
          },
          {
            field: 'pictureAfter',
            headerName: 'Picture After',
            width: 120,
            sortable: true,
            resizable: true,
        
            cellRenderer: (params: any): any => {
              return !params.data.PictureAfter || params.data.PictureAfter.includes('/null')
                ? '<img style="height:40px;width:40px" src="assets/images/no-image.png" />'
                : '<img style="height:40px;width:40px" src="' + this.imageUrl + params.data.PictureAfter + '" />';
            },
          },
          {
            field: 'IsRejected',
            headerName: 'Rejected',
            width: 150,
            cellStyle: this.IsWrite ? (params: any) => {
              return params.data.ObservationStatus.toString().toLowerCase() == 'completed' ||
                params.data.ObservationStatus.toString().toLowerCase() === 'closed' ||
                params.data.ObservationStatus.toString().toLowerCase() === 'rejected' ||
                params.data.IsCompleted == true ||
                params.data.IsVerified == true
                ? { 'pointer-events': 'none', opacity: '0.4' }
                : {
                  color: 'black',
                  'background-color': '#EDF1E5',
                };
            } : '',
            sortable: false,
            resizable: true,
            cellRenderer: `checkboxIsWrite`,
            // editable: this.IsWrite ? (params: any) => params.data.IsRejected === false : false,
          },
          {
            field: 'IsCompleted',
            headerName: 'Completed',
            width: 150,
            cellStyle: this.IsWrite ? (params: any) => {
              return params.data.ObservationStatus.toString().toLowerCase() == 'completed' ||
                params.data.ObservationStatus.toString().toLowerCase() == 'closed' ||
                params.data.ObservationStatus.toString().toLowerCase() == 'rejected' ||
                params.data.IsRejected == true ||
                params.data.IsVerified == true
                ? { 'pointer-events': 'none', opacity: '0.4' }
                : {
                  color: 'black',
                  'background-color': '#EDF1E5',
                };
            } : '',
            sortable: false,
            resizable: true,
            cellRenderer: 'checkboxIsWrite',
            // editable: this.IsWrite ? (params: any) => params.data.IsCompleted === false : false,
          },
          {
            field: 'IsVerified',
            headerName: 'Verified',
            width: 150,
            cellStyle: this.IsWrite ? (params: any) => {
              return params.value === true
                ? { 'pointer-events': 'none', opacity: '0.4' }
                : {
                  color: 'black',
                  'background-color': '#EDF1E5',
                };
            } : '',
            sortable: false,
            resizable: true,
            cellRenderer: 'checkboxIsWrite',
            // editable: this.IsWrite ? (params: any) => params.data.IsVerified === false : false,
          },
          {
            field: 'IsRequiredPictureAfter',
            headerName: 'RequiredPictureAfter',
            width: 140,
            sortable: true,
            filter: true,
            hide: 'true',
          },
        ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  actionForm(): FormGroup {
    return this.fb.group({
      // gembaActionId   : [d && d.actionName ? d.actionName : null, []],
      actionName: [],
      targetDate: [],
      noOfMisses: [],
      responsibleId: [],
      newTargetDate: [],
      completed: [],
      gembaActionId: [],
    });
  }

  getGembafiles(): void {
    const key = {
      GembaId: this.gembaWalkId
    };
    this.previewerData = new MatTableDataSource<any>([]);
    this.gembaService.getGembafiles(key).subscribe(
      (listData: any) => {
        this.previewerData = new MatTableDataSource<any>(listData);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getDefectfiles(Id: number): void {
    const key = {
      defectId: Id
    };
    this.previewerData = new MatTableDataSource<any>([]);
    this.defectConfigService.getDefectfiles(key).subscribe(
      (listData: any) => {
        this.previewerData = new MatTableDataSource<any>(listData);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  openFilesModal(data: any): void {

    if (data.Source === 'GembaWalk') {
      this.gembaWalkId = data.TableId;
      this.getGembafiles();

    }
    else if (data.Source === 'Defect') {
      this.getDefectfiles(data.TableId);
    }
    this.dialog.open(this.previewModal, { height: 'auto', width: '90%' });
  }

  openFile(elem: any): void {
    const array = elem.FileName.split('.');
    this.doc = this.imageUrl + elem.FilePath;
    const fileType = array[array?.length - 1];
    if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
      this.images = [];
      this.fileType = 'img';
      this.images.push(this.doc);
    } else {
      this.fileType = 'doc';
    }
    this.displayMaximizable = true;
  }

}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};

const countryCellRenderer = (params: any) => {
  //  params.value.userName
};
