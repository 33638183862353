import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group } from '../models/group';
import { Plant } from '../models/plant';

@Injectable({
  providedIn: 'root'
})
export class DefectService {
  kpibaseUrl: string = environment.O3CoreApiUrl;
  apiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  // hardCodeToken: string = environment.token;


  constructor(private http: HttpClient) { }
  addDefects(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.o3CoreApiUrl + 'Defect/AddDefectData', data);
  }
  updateDefects(data: any): Observable<Group[]> {
    return this.http.put<Group[]>(this.o3CoreApiUrl + 'Defect/UpdateDefectData', data);
  }
  deleteDefect(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.o3CoreApiUrl + 'Defect/DeleteDefects', data);
  }
  getDefects(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.o3CoreApiUrl + 'Defect/GetAllDefects', data);
  }
  getAllDefectsReports(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.o3CoreApiUrl + 'Defect/GetAllMachineParameters', data);
  }

  getDefectReports(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'defectreports', data);
  }
  getProducts(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.o3CoreApiUrl + 'Product/GetProuctsByUnit', data);
  }

  getProductsData(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'products', data);
  }
  getParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/GetParameterGroups', data);
  }

  getQualityParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'qualityparametergroup', data);
  }
  machineParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Defect/GetMachineParameters', data);
  }
  DeleteDefectProducts(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Defect/DeleteDefectProduct', data);
  }
  addMachineParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Defect/AddDefectParameters', data);
  }

  addExcelDefects(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'exceldefectdata', data);
  }

  addDefectFile(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'defectfile', data);
  }

}
