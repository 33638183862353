<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card style="height: 90px;">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-4 p-lg-6 p-xl-6">
        <mat-card-title>Process Confirmation</mat-card-title>
      </div>

      <div class="p-col-12 p-md-8 p-lg-6 p-xl-6" style="display: flex; justify-content: flex-end;">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

        <form [formGroup]="searchAuditMasterForm" style="float: right">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Group</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"
              (selectionChange)="getPlantByGroup()">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>

        </form>
        <form [formGroup]="searchAuditMasterForm" style="float: right;  margin-left: 10px;">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Plant</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant"
              (selectionChange)="getAuditAreaByPlant()">
              <!-- <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="plantsDDL" (filteredReturn)="filterdplants =$event"></mat-select-filter>             -->
              <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>

        </form>
        <form [formGroup]="searchAuditMasterForm" style="float: right;  margin-left: 10px;">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Area</mat-label>
            <mat-select panelClass="testClass" name="area" formControlName="area" (selectionChange)="changeArea()">

              <mat-option *ngFor="let a of areaDDL" [value]="a.areaId"> {{a.areaName}} </mat-option>

            </mat-select>
          </mat-form-field>
        </form>
      </div>

    </div>
  </mat-card>
  <mat-card class="p-mt-2 p-p-0">
    <div class="p-grid p-fluid" style="margin-left: 1px;">

      <div class="p-xl-2 p-lg-2 p-md-3 p-col-12" *ngFor="let c of audicategories; let index = index ">
        <div class="position">
          <button mat-flat-button color="accent" class="btn-accent-big btnGreen"
            (click)="getCheckpointsByCategory(index, c)">
            {{c.categoryName}}
          </button>
        </div>
      </div>

      <div style="float: right;" *ngIf="IsWrite">
        <button type="submit" class="add-btn" style="margin-left: 18px;" pTooltip="Save" tooltipPosition="top"
          (click)="saveAuditExecution()">
          <img src="assets/images/save.png" alt="" width="20px" height="20px">
        </button>
        <button (click)="openGlobalModal()" class="globalstandard" pTooltip="Global Standards" tooltipPosition="top">
          <img src="assets/images/popup.png" alt="" width="20px" height="20px">
        </button>
      </div>

    </div>
  </mat-card>
  <mat-card class="">
    <div class="p-grid">
      <div class="p-xl-7 p-lg-7 p-md-12 p-col-12">
        <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
          <table mat-table class="basic-table plant-model-table" matSort [dataSource]="checkPointsData">

            <ng-container matColumnDef="srno">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10% !important;">
                <p class="header-text">SrNo</p>
              </th>
              <td mat-cell *matCellDef="let element; let index = index">{{index + 1}}</td>
            </ng-container>

            <!-- Checkpoints Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40% !important;">
                <p class="header-text">Checkpoints</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <!-- Score Column -->
            <ng-container matColumnDef="score">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Score</p>
              </th>
              <td mat-cell *matCellDef="let element">

                <mat-radio-group aria-label="Select an option" [value]="element.score"
                  (change)="scoreUpdate($event, element)" *ngIf="element.CatType !== 'Option'">
                  <mat-radio-button *ngFor="let p of scoreData" [value]="p.score">{{p.score}}</mat-radio-button>
                </mat-radio-group>

                <mat-radio-group aria-label="Select an option" [value]="element.CatTypeDetail"
                  (change)="scoreUpdate($event, element)" *ngIf="element.CatType === 'Option'">
                  <mat-radio-button *ngFor="let p of categoryTypeDDL" [value]="p.id">{{p.name}}</mat-radio-button>
                </mat-radio-group>
              </td>

            </ng-container>


            <tr mat-header-row *matHeaderRowDef="auditexeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: auditexeColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4" style="text-align: center;">No Data Matching</td>
            </tr>
          </table>
          <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
          </mat-paginator>

        </div>
      </div>
      <div class="p-xl-5 p-lg-5 p-md-12 p-col-12">
        <mat-card class="legendcard">
          <mat-card-header>
            <mat-card-title>Legends</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div style="display: flex;" *ngIf="Categorytype==='Score'">
              <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                <p class="card-sub-heading">Non-Compliance</p>
                <ul *ngFor="let s of nonCompList">
                  <li class="my-li">{{ s.score }} - {{ s.name }}</li>
                </ul>
              </div>
              <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                <p class="card-sub-heading">Compliance</p>
                <ul *ngFor="let s of compList">
                  <li class="my-li">{{ s.score }} - {{ s.name }}</li>
                </ul>
              </div>
            </div>
            <div style="display: flex;" *ngIf="Categorytype==='Option'">
              <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                <p class="card-sub-heading">Non-Compliance</p>
                <ul>
                  <li class="my-li">No</li>
                </ul>
              </div>
              <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                <p class="card-sub-heading">Compliance</p>
                <ul>
                  <li class="my-li">Yes</li>
                </ul>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- <div class="p-xl-4 p-lg-4 p-md-12 p-col-12">
        <mat-card class="legendcard" style="margin-top: 4px;">
          <mat-card-header>
            <mat-card-title>Legends</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <ul *ngFor="let s of scoreData">
              <li class="my-li">{{ s.score }} - {{ s.name }}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div> -->
    </div>
  </mat-card>



  <ng-template #displayRoleModal1>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Global Standards</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <table mat-table [dataSource]="GlobalData" class="basic-table plant-model-table" matSort>
      <ng-container matColumnDef="plant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Plant</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.ptDesc}} </td>
      </ng-container>

      <!-- Sort Column -->
      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Area</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.masterArea}} </td>
      </ng-container>


      <!-- Sort Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Type</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.typeName}} </td>
      </ng-container>

      <!-- Sort Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Name</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="file">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Action</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <a target="_blank" href="{{imageUrl + element.filename}}" *ngIf="element.filename !== null" [download]>
            <svg width="25" height="25" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.867 477.867"
              style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
              <g>
                <g>
                  <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
                        c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
                        V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
                        c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
                        C460.8,176.523,437.877,153.6,409.6,153.6z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
                        s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
                        l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
                        C342.915,261.286,342.727,250.482,335.947,243.934z" />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g>
              </g>
              <g></g>
              <g></g>
            </svg>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="globalColumns"></tr>
      <tr *matRowDef="let row; columns: globalColumns;" mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No Data Matching</td>
      </tr>
    </table>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>

    <div mat-dialog-actions style="float: right; margin-top: 10px">
      <!-- <button color="accent" mat-flat-button cdkFocusInitial>
          Save & Close
        </button> -->
    </div>

  </ng-template>
</section>