import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SuccessMessage } from '../models/successMessage';

@Injectable({
  providedIn: 'root',
})
export class MeetingmasterService {
  
  NetApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}

  addMeeting(meeting: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'addmeetingmaster', meeting);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingMaster/SaveMeetingMaster',
      meeting
    );
  }
  GetAllMeetingMaster(data: any): Observable<any> {
    // return this.http.post<any>(this.baseUrl + 'meetingmasters', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingMaster/GetMeetingMaster',
      data
    );
  }
  SaveArea(data:any):Observable<any> {
    return this.http.post<any[]>(this.NetApiUrl + 'MeetingEffectivenessChecklist/SaveEffectivenessArea',data )
  }
  GetArea(data:any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'MeetingEffectivenessChecklist/GetEffectivenessArea',data)
  }
 DeleteArea(data:any) :Observable <any> {
  return this.http.post<any[]>(this.NetApiUrl + 'MeetingEffectivenessChecklist/DeleteEffectivenessArea',data)
 }
 UpdateArea(data:any) : Observable <any>{
  return this.http.post<any[]>(this.NetApiUrl + 'MeetingEffectivenessChecklist/EditEffectivenessArea',data)
 }
  getMeetingMasterById(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'meetingmaster', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingMaster/GetMeetingMasterById',
      data
    );
  }
  updateMeeting(meeting: any): Observable<any[]> {
    // return this.http.put<any[]>(this.baseUrl + 'updatemeetingmaster', meeting);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingMaster/UpdateMeetingMaster',
      meeting
    );
  }

  deleteMeetingMaster(data: any): Observable<SuccessMessage> {
    // return this.http.post<SuccessMessage>(this.baseUrl + 'deletemeetingmaster', data);
    return this.http.post<SuccessMessage>(
      this.NetApiUrl + 'MeetingMaster/DeleteMeetingMaster',
      data
    );
  }

  SaveMeetingEffectivenessChecklist(meeting: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl +
        'MeetingEffectivenessChecklist/SaveMeetingEffectivenessChecklist',
      meeting
    );
  }

  GetMeetingEffectivenessChecklist_Master(data: any): Observable<any> {
    return this.http.post<any[]>(
      this.NetApiUrl +
        'MeetingEffectivenessChecklist/GetMeetingEffectivenessChecklist_Master',
      data
    );
  }

  GetMeetingEffectivenessChecklist_MasterById(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl +
        'MeetingEffectivenessChecklist/GetMeetingEffectivenessChecklist_MasterById',
      data
    );
  }

  DeleteMeetingEffectivenessChecklist(data: any): Observable<SuccessMessage> {
    return this.http.post<SuccessMessage>(
      this.NetApiUrl +
        'MeetingEffectivenessChecklist/DeleteMeetingEffectivenessChecklist_Master',
      data
    );
  }

  UpdateMeetingEffectivenessChecklist(meeting: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl +
        'MeetingEffectivenessChecklist/UpdateMeetingEffectivenessChecklist_Master',
      meeting
    );
  }

  getMeetingMenuList(): Observable<any> {
    return this.http.get<any>(this.NetApiUrl + 'MeetingMaster/GetMeetingMenuList');
  }

  AddPlantAllowedMenu(data: any): Observable<any> {
    return this.http.post<any>(
      this.NetApiUrl + 'MeetingMaster/AddPlantAllowedMenu',
      data
    );
  }

  GetPlantAllowedMenu(data: any): Observable<any> {
    return this.http.post<any>(
      this.NetApiUrl + 'MeetingMaster/GetPlantAllowedMenu',
      data
    );
  }

  DeletePlantAllowedMenu(data: any): Observable<any> {
    return this.http.post<any>(
      this.NetApiUrl + 'MeetingMaster/DeletePlantAllowedMenu',
      data
    );
  }

}
