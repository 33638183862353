import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { ActionanalysisComponent } from './actionanalysis/actionanalysis.component';
import { ScorecardComponent } from './scorecard/scorecard.component';
import { MeetingDefinationComponent } from './meeting-defination/meeting-defination.component';
import { MeetingMatrixComponent } from './meeting-matrix/meeting-matrix.component';
import { PerformancemastersettingsComponent } from './Performance Master Setting/performancemastersettings/performancemastersettings.component';
import { ActionDashboardComponent } from './action-dashboard/action-dashboard.component';
import { MeetingActionComponent } from './meeting-action/meeting-action.component';
import { MeetingSaturationComponent } from './meeting-saturation/meeting-saturation.component';
import { MeetingDashboardComponent } from './meeting-dashboard/meeting-dashboard.component';
import { KPIMilestoneComponent } from './KPI-milestone/KPI-milestone.component';
import { MeetingMatrixV2Component } from './meeting-matrixV2/meeting-matrixV2.component';

const routes: Routes = [
  { path: 'ActionPlan', component: ActionanalysisComponent , canActivate: [AuthGuard] },
  { path: 'Scorecard', component: ScorecardComponent , canActivate: [AuthGuard] },
  { path: 'MeetingDefination', component: MeetingDefinationComponent , canActivate: [AuthGuard] },
  { path: 'MeetingMatrix', component: MeetingMatrixComponent , canActivate: [AuthGuard] },
  { path: 'Performancemastersettings', component: PerformancemastersettingsComponent , canActivate: [AuthGuard] },
  { path: 'ActionDB', component: ActionDashboardComponent , canActivate: [AuthGuard] },
  { path: 'MeetingAction', component: MeetingActionComponent , canActivate: [AuthGuard] },
  { path: 'MeetingSaturation', component: MeetingSaturationComponent , canActivate: [AuthGuard] },
  { path: 'MeetingDashboard', component: MeetingDashboardComponent , canActivate: [AuthGuard] },
  {
    path: 'kpiMilestone',
    component: KPIMilestoneComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'MeetingMatrixV2',
    component: MeetingMatrixV2Component,
    canActivate: [AuthGuard],
  },

];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PerfomranceManagementRoutingModule {}
