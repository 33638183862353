import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RawMaterialExecutionService {
baseUrl: any = environment.O3CoreApiUrl;

constructor(private http: HttpClient) { }

GetRawMaterialInspectionReportHeader(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'getrawmaterialinspectionheader', data);
}

GetRawMaterialExecutionMasterHeader(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'getexecutionmasterheader', data);
}

GetRawMaterialExecutionLabSpecifications(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'getrawmaterialexecutionlabspecifications', data);
}

GetRawMaterialPhysicalSpecifications(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'getrawmaterialexecutionphysicalspecifications', data);
}

AddRawMaterialExecutionMasterHeader(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'addrawmaterialexecutionmasterheader', data);
}

AddRawMaterialExecutionLabSpecifications(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'addrawmaterialexecutionlabspecifications', data);
}

}
