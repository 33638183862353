<ng-template #menuAdditionModal>
    <div mat-dialog-title>
        <div class="d-flex justify-content-between">
            <h1 mat-dialog-title style="margin-bottom: 0px;">{{langObj ? langObj?.MeetingMatrixPermissions : ('MeetingMatrixPermissions' | translate)}}</h1>
            <button mat-mini-fab mat-button (click)="closeDialog()" color="warn" title="close">
                <i class="fa fa-times" style="color: #fff"></i>
            </button>
        </div>
    </div>
    <form [formGroup]="menuAdditionForm" style="display: flex; flex-direction: column; height: 80%">
        <label style="display: block;">{{langObj ? langObj?.SelectPermission : ('SelectPermission' | translate)}}</label>
        <p-multiSelect defaultLabel="Select Permitted Menu" [options]="meetingMenuList" [style]="{'width':'50%'}"
            formControlName="menuList" optionLabel="meetingDesc" optionValue="meetingMenuId">
        </p-multiSelect>
        <div style="width: 20%; margin-top: auto; margin-left: auto; display: flex; justify-content: flex-end;">
            <button mat-raised-button color="primary" [disabled]="!menuAdditionForm.valid" (click)="insertMeetingMenuInPlant()"><span style="color: white">{{langObj ? langObj?.Add : ('Add' | translate)}}</span></button>
        </div>
    </form>
</ng-template>

<mat-card class="p-mt-0 p-pt-0">
    <div class="p-d-flex p-jc-between p-ai-center">
        <mat-card-title class="p-ml-2 p-mt-2">{{langObj ? langObj?.MeetingMenuList : ('MeetingMenuList' | translate)}}
        </mat-card-title>
        <div class="">
            <mat-form-field>
                <input matInput type="text" class="form-control" placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}" [(ngModel)]="meetingMenuFilterVal" (keyup)="meetingMenuFilter()" />
            </mat-form-field>
        </div>
        <div class="">
            <button type="button" mat-mini-fab mat-button color="accent" pTooltip="Add New Menu"
                (click)="openMenuAdditionModal()" tooltipPosition="top">
                <i class="fa fa-plus" style="color: #fff"></i>
            </button>
        </div>
    </div>
    <mat-card-content>
        <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
            <table mat-table matSort matSortDirection="desc" class="basic-table plant-model-table"
            [dataSource]="meetingMenuListDS">
      
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.Action : ('Action' | translate)}} </th>
              <td mat-cell *matCellDef="let data" class="header-text">
                <button mat-mini-fab color="warn" (click)="deleteMeetingMenuInPlant(data.meetingMenuId)">
                  <mat-icon style="color: white">delete</mat-icon>
                </button>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="Menu">
              <th mat-header-cell mat-sort-header="Menu" *matHeaderCellDef> {{langObj ? langObj?.Menu : ('Menu' | translate)}} </th>
              <td mat-cell *matCellDef="let data" class="header-text"> {{data.meetingDesc}} </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="meetingMenuColumn"></tr>
            <tr mat-row *matRowDef="let row; columns: meetingMenuColumn;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="2" style="text-align: center;">{{langObj ? langObj?.NoData : ('NoData' | translate)}}</td>
              </tr>
          </table>
          <mat-paginator #meetingMenusPaginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
        </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>