import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  baseUrl = environment.O3CoreApiUrl;

  constructor(
    private http: HttpClient,
  ) { }
  getAllCity(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'cities');
  }
  addNewCity(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addcity', data);
  }
  updateCity(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.baseUrl + 'updatecity', data);
  }

  deleteCity(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'deletecity', data);
  }
}
