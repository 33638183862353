<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{ breadcrumItem.text }}</a
        >
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Meeting Dashboard</h1>
    </div>
    <form [formGroup]="MeetingDBForm" >
      <div class="drop-down-group">
        <div class="drop-down-element">
            <mat-form-field >
              <mat-label appearance="fill" class="field-fill">Group</mat-label>
              <mat-select  name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
                <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">  {{g.TypeName}} </mat-option>
              </mat-select>
            </mat-form-field>
    
        </div>
        <div class="drop-down-element">
          <mat-form-field >
            <mat-label >Plant</mat-label>
            <mat-select name="plant" formControlName="plant">
              <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId">  {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="wrapper-box wrapper-box-shadow grid-container">
  <div class="p-grid p-fluid">
    <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" style="height:auto;">
      <!-- <p class="graph-title">Fouad FTE Trend</p> -->
      <!-- <canvas id="myChart"></canvas> -->
      <div id="chart1">
        <apx-chart
          [series]="chartOptions1.series"
          [chart]="chartOptions1.chart"
          [xaxis]="chartOptions1.xaxis"
          [stroke]="chartOptions1.stroke"
          [colors]="chartOptions1.colors"
          [dataLabels]="chartOptions1.dataLabels"
          [legend]="chartOptions1.legend"
          [markers]="chartOptions1.markers"
          [grid]="chartOptions1.grid"
          [yaxis]="chartOptions1.yaxis"
          [title]="chartOptions1.title"
        ></apx-chart>
      </div>
      
    </div>
    <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="empty">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell *matCellDef="let element"> {{element.empty}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time1">
          <th mat-header-cell *matHeaderCellDef> 18/7/2019 </th>
          <td mat-cell *matCellDef="let element"> {{element.time1}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time2">
          <th mat-header-cell *matHeaderCellDef> 20/8/2019 </th>
          <td mat-cell *matCellDef="let element"> {{element.time2}} </td>
        </ng-container>

        <ng-container matColumnDef="time3">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time3}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time4">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time4}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time5">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time5}} </td>
        </ng-container>

        <ng-container matColumnDef="time6">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time6}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time7">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time7}} </td>
        </ng-container>

        <ng-container matColumnDef="time8">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time8}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time9">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time9}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time10">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time10}} </td>
        </ng-container>

        <ng-container matColumnDef="time11">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time11}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time12">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time12}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time13">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time13}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time14">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time14}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time15">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time15}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" style="height:auto;">
      <!-- <p class="graph-title">Fouad FTE Trend</p> -->
      <!-- <canvas id="myChart"></canvas> -->
      <div id="chart2">
        <apx-chart
          [series]="chartOptions2.series"
          [chart]="chartOptions2.chart"
          [yaxis]="chartOptions2.yaxis"
          [xaxis]="chartOptions2.xaxis"
          [labels]="chartOptions2.labels"
          [stroke]="chartOptions2.stroke"
          [title]="chartOptions2.title"
          [dataLabels]="chartOptions2.dataLabels"
          [fill]="chartOptions2.fill"
          [tooltip]="chartOptions2.tooltip"
        ></apx-chart>
      </div>
      
    </div>
    <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
      <table mat-table [dataSource]="dataSourcesecond" class="mat-elevation-z8">
        <ng-container matColumnDef="empty">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell *matCellDef="let element"> {{element.empty}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time1">
          <th mat-header-cell *matHeaderCellDef> 18/7/2019 </th>
          <td mat-cell *matCellDef="let element"> {{element.time1}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time2">
          <th mat-header-cell *matHeaderCellDef> 20/8/2019 </th>
          <td mat-cell *matCellDef="let element"> {{element.time2}} </td>
        </ng-container>

        <ng-container matColumnDef="time3">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time3}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time4">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time4}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time5">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time5}} </td>
        </ng-container>

        <ng-container matColumnDef="time6">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time6}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time7">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time7}} </td>
        </ng-container>

        <ng-container matColumnDef="time8">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time8}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time9">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time9}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time10">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time10}} </td>
        </ng-container>

        <ng-container matColumnDef="time11">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time11}} </td>
        </ng-container>
    
        <ng-container matColumnDef="time12">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time12}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time13">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time13}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time14">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time14}} </td>
        </ng-container>
      
        <ng-container matColumnDef="time15">
          <th mat-header-cell *matHeaderCellDef> dd/mm/yy </th>
          <td mat-cell *matCellDef="let element"> {{element.time15}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="seconddisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: seconddisplayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

</div>