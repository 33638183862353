import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CostCenterService } from 'src/app/services/cost-center.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslaterService } from 'src/app/services/translater.service';
@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.scss'],
})
export class CostCenterComponent implements OnInit {
  @ViewChild('ImportDialog') ImportDialog!: TemplateRef<any>;
  @ViewChild('duplicateDialog') duplicateDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() childToParent: EventEmitter<any> =   new EventEmitter();
  addCostForm!: FormGroup;
  costForm!: FormGroup;
  plantId!: number;
  costCenterId!: number;
  parentId!: any;
  disableCostParent = false;
  plantsDDL!: any[];
  parentDDL!: any[];
  GroupId: any;
  groupId!: number;
  cost: any;
  IsAccess: any;
  IsWrite: any;
  dialogRef: any;
  costCeneterData: any;
  costCenterDataSource!: MatTableDataSource<any>;
  costCenterColumns: any[] = [
    'costActions',
    'costCenter',
    'glAccount',
  ];
  duplicateDataSource!: MatTableDataSource<any>;
  duplicateDataColumns: any[] = [
    '_costCenterName',
    '_costElementName',
    '_sheetName',
    '_message',
    '_sheetNo',
  ];
  CostformMode = true;
  breadcrumList: any;
  moduleId: any;
  pageId: any;
  langObj: any;
  languageDir = 'ltr';
  constructor(
    private fb: FormBuilder,
    private costService: CostCenterService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.addCostForm = this.fb.group({
      costCenterId: [''],
      plantId: [this.plantId],
      name: ['', Validators.required],
      glaccount: ['', Validators.required],
      costCenterparent: [''],
    });
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        // this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openFile() {
    this.fileInput.nativeElement.click()
  }
  ImportToExcel() {
      this.dialogRef = this.dialog.open(this.ImportDialog, { width: '500px' });
      this.dialogRef.afterClosed().subscribe((result: any) => {
      });
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  // uploadExcelFile(e: any){
  //   this.spinner.show();
  //   let file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("plantId", this.plantId.toString());
  //   this.costService.uploadExcelFile(formData).subscribe((res: any) => {
  //     this.spinner.hide();
  //     this.dialog.closeAll();
  //     if(res.isDuplcate == true){
  //       this.duplicateDataSource = new MatTableDataSource<any>(res.result);
  //       this.duplicateDataSource.paginator = this.paginator;
  //       this.dialogRef = this.dialog.open(this.duplicateDialog, { width: '1000px' });
  //     }else{
  //       this.toaster.success('success', 'File Uploaded Successfully...');
  //       this.dialog.closeAll();
  //     }
  //     this.GetCostCenter({ plantId: this.plantId });
  //   });
  // }
  AddCostCenter(): void {
    const key = {
      plantId: this.plantId,
      costCenterName: this.addCostForm.value.name,
      glAccount: this.addCostForm.value.glaccount,
    };
    const costData = this.costCenterDataSource.filteredData;
    const cost = costData.filter(
      (c: any) => c.costCenterName === this.addCostForm.value.name
    );
    if (cost.length > 0) {
      this.toaster.error('Error', 'Cost Center Name Already Exist');
      return;
    }
    this.costService.AddCostCenter(key).subscribe((res: any) => {
      this.toaster.success('success', 'Cost Center is added successfully...');
      this.GetCostCenter({ plantId: this.plantId });
    });
    this.CostformMode = true;
    this.addCostForm.reset();
  }

  GetCostCenter(key: any): void {
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.costService.GetCostCenter(key).subscribe((data) => {
      this.costCenterDataSource = new MatTableDataSource<any>(data);
      this.costCeneterData = data;
      this.costCenterDataSource.paginator = this.paginator;
      this.parentDDL = data;
    });
  }

  DeleteCost(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Cost Center!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          costCenterId: element.costCenterId,
        };
        this.costService.deleteCost(id).subscribe(
          (response) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected cost center has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.GetCostCenter({ plantId: this.plantId });
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected cost center is safe :)', 'error');
      }
    });
  }

  EditCost(element: any): void {
    this.CostformMode = false;
    this.addCostForm.get('plantId')?.setValue(this.plantId);
    this.addCostForm.get('name')?.setValue(element.costCenterName);
    this.addCostForm.get('glaccount')?.setValue(element.glAccount);
    this.addCostForm.get('costCenterparent')?.setValue(element.parentId);
    this.costCenterId = element.costCenterId;
    this.disableCostParent = false;
    if (element.parentId === 0 || element.parentId === null) {
      this.disableCostParent = true;
      this.addCostForm.get('plantId')?.setValue(this.plantId);
      this.addCostForm.get('name')?.setValue(element.costCenterName);
      this.addCostForm.get('glaccount')?.setValue(element.glAccount);
      this.costCenterId = element.costCenterId;
    }
  }

  UpdateCostElement(): void {
    const key = {
      costCenterId: this.costCenterId,
      plantId: this.plantId,
      costCenterName: this.addCostForm.value.name,
      glAccount: this.addCostForm.value.glaccount,
    };
    const costData = this.costCenterDataSource.filteredData;
    const cost = costData.filter(
      (c: any) =>
        c.costCenterName === this.addCostForm.value.name &&
        c.costCenterId !== this.costCenterId
    );
    if (cost.length > 0) {
      this.toaster.error('Error', 'Cost Center Name Already Exist');
      return;
    }
    this.costService.updateCost(key).subscribe(
      (response) => {
        this.toaster.success('Success', 'Cost Center Updated Successfully');
        this.GetCostCenter({ plantId: this.plantId });
        this.childToParent.emit();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
    this.CostformMode = true;
    this.addCostForm.reset();
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.costCenterDataSource.filter = filterValue.trim().toLowerCase();
  }
  RefreshCostCenter() {
    this.addCostForm.reset();
    this.disableCostParent = false;
    this.CostformMode = true;
  }
}
