import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScoreServiceService {
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  addScore(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'score', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Score/AddScore', data);
  }

  getLevel(): any {
    // return this.http.get<any>(this.baseUrl + 'levels');
    return this.http.get<any>(this.O3CoreApiUrl + 'Score/GetMasterLevel');
  }

  getEvaluation(): any {
    // return this.http.get<any>(this.baseUrl + 'evaluation');
    return this.http.get<any>(this.O3CoreApiUrl + 'Score/GetEvaluations');
  }


  getMasterScore(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'masterscore', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Score/GetMasterEvaluation', data);
  }

  deleteScore(data: any): any {
    //return this.http.post<any>(this.baseUrl + 'deletescore', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Score/DeleteScore', data);
  }
  updateScore(data: any): any {
    // return this.http.put<any>(this.baseUrl + 'score', data);
    return this.http.put<any>(this.O3CoreApiUrl + 'Score/UpdateScore', data);
  }
  getScorebyId(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'getScoreById', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Score/GetScoreById', data);
  }


}
