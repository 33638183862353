import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopFloorDataService {
  baseUrl = environment.O3CoreApiUrl;
  apiUrl = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  // o3CoreApiUrl = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  GetStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Planner/GetStatus', data);
  }

  GetTreeModal(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetTreeModal', data);
  }

  GetProcessOrderList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetProcessOrderList', data);
  }

  GetLastShiftInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetLastShiftInfo', data);
  }

  GetLatestLotCode(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetLatestLotCode', data);
  }

  GetJbDowntimeHistory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetJbDowntimeHistory', data);
  }

  GetKpiParamInfoList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetKpiParamInfoList', data);
  }

  GetLableBatch(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetLableBatch', data);
  }

  GetItemTypeByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetItemTypeByUnit', data);
  }

  GetTotalBatch(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetTotalBatch', data);
  }

  GetJbBatchHistory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetJbBatchHistory', data);
  }

  GetWasteFaultByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetWasteFaultByUnit', data);
  }

  GetFaultByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetFaultByUnit', data);
  }

  GetStDuration(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetStDuration', data);
  }

  SaveJBDowntimeInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/SaveJBDowntimeInfo', data);
  }

  SumbitProcessOrderStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/SumbitProcessOrderStatus', data);
  }

  SaveJBBatchInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/SaveJBBatchInfo', data);
  }

  GetJbWasteHistory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetJbWasteHistory', data);
  }

  SaveJBWasteInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/SaveJBWasteInfo', data);
  }

  GetSTFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetSTFault', data);
  }

  GetBatchCheckListByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetBatchCheckListByUnit', data);
  }

  GetKpiParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetKpiParameters', data);
  }

  GetKpiParamInfoDetails(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetKpiParamInfoDetails', data);
  }

  SumbitJobKpiParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/SumbitJobKpiParameters', data);
  }

  GetTotalWaste(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetTotalWaste', data);
  }

  GetJbBatchchecklistDetails(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetJbBatchchecklistDetails', data);
  }

  GetShiftCloseValidation(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetShiftCloseValidation', data);
  }

  GetIsShiftStart(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetIsShiftStart', data);
  }

  GetValidationInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetValidationInfo', data);
  }

  SumbitShiftClose(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/SumbitShiftClose', data);
  }

  GetBarCodeDetails(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BarcodePrint/GetBarCodeDetails', data);
  }
  GetBarCodeDetailsLocalOrExport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BarcodePrint/GetBarCodeDetailsLocalOrExport', data);
  }

  //================================ Food Sector ================================//

  getFoodSector(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetIsFoodSector', data);
  }
  getItemBomListInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetItemBOMListInfo', data);
  }
  SaveFoodSectorMaterialConsumption(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/SubmitItemBOMBatchInfo', data);
  }
  getBatchItemBOMList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetBatchItemBOMList', data);
  }
  getBatchItemBOMInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetBatchItemBOMInfo', data);
  }

  getBatchConsumptionDD(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetBatchConsumptionList', data);
  }
  //===================================== Production Start =============================//
  getProductionDetailList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ProductionStart/GetDetails', data);
  }
  updateProductionDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ProductionStart/PostDetails', data);
  }

  //================================ sfdc events ================================//
  async addSfdcEvent(data: any): Promise<any> {
    return await this.http.post<any>(this.apiUrl + 'addsfdcevents', data).toPromise();
  }
  async insertSfdcEvent(data: any): Promise<any> {
    return await this.http.post<any>(this.o3CoreApiUrl + 'Quality/InsertSFDCEvents', data).toPromise();
  }

  //================================ Manual ProductionOutput ================================//
  GetBaseDetailsForProductionOutput(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'MES/GetBaseDetailsForProductionOutput', data);
  }
  GetPODetail(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'SDFC/GetPODetail', data);
  }
  SubmitManualProductionOutput(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'SDFC/SubmitManualProductionOutput', data);
  }
  GetManualProductionOutput(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'SDFC/GetManualProductionOutput', data);
  }
  SubmitOrderNumberForProductionOutput(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'SDFC/SubmitOrderNumberForProductionOutput', data);
  }
  UpdatePrintStatusForProductionOutput(data: any){
    return this.http.post(this.o3CoreApiUrl + 'SDFC/UpdatePrintStatusForProductionOutput', data);
  }
  GetPOListByDateRangeForProductionOutput(data: any){
    return this.http.post(this.o3CoreApiUrl + 'SDFC/GetPOListByDateRangeForProductionOutput', data);
  }
  DeleteManualProductionOutput(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'SDFC/DeleteManualProductionOutput', data);
  }
  GetERPMachineList() {
    return this.http.get(this.o3CoreApiUrl + 'SDFC/GetERPMachineList');
  }
}
