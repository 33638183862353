<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white;">{{ spinnerText }}</p>
</ngx-spinner>

<div>
    <div class="content-top-box">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                    (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
    </div>
    
    <div class="list-header">
        <h1 class="header-title" style="padding: 15px 0;margin: 0;">BOS Reporting</h1>
        <!-- <mat-form-field appearance="fill" class="field-fill" style="justify-content: flex-end;display: flex;">
            <mat-label>Search</mat-label>
            <input [readonly]="false" matInput class="input-text" (keyup)="applyFilter($event)" autocomplete="off" />
        </mat-form-field> -->
    </div>
    
    
    <div class="headerComp">
        <app-safety-header (add)="openDialog($event,'bos')" (emitHeaderData)="getBOSReport($event)"></app-safety-header>
    </div>
    
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
        <div class="table-div">
            <ag-grid-angular style="width: 100%; height: 24vw;margin-top: 10px;" class="ag-theme-alpine" [columnDefs]="BOSReportColumns" [rowData]="BOSReportList" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="paginationPageSize"></ag-grid-angular>
        </div>
    
    </div>
</div>

