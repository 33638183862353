import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { CustomDateComponent } from '../../date-components/custom-date-component.component';
import { BtnCellRendererActualEntry } from '../../btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from '../../btn-cell-renderer.component';
import { PrimeNGConfig } from 'primeng/api';
import { KpiactualentryService } from 'src/app/services/kpiactualentry.service';
import { ToastrService } from 'ngx-toastr';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Frequency } from 'src/app/models/frequency';
import { Driver } from 'src/app/models/driver';
import { Pillar } from 'src/app/models/pillar';
import { Department } from 'src/app/models/department';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserManu } from 'src/app/models/userManu';
import { UserPlant } from 'src/app/models/userPlant';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = _moment;
import { TranslaterService } from 'src/app/services/translater.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-manualactualentry',
  templateUrl: './manualactualentry.component.html',
  styleUrls: ['./manualactualentry.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ManualactualentryComponent implements OnInit {
  @ViewChild('uploadActualDialog') uploadActualDialog!: TemplateRef<any>;
  @ViewChild('uploadTargetDialog') uploadTargetDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('pickerforgrid') picker!: any;
  @ViewChild('starttimepicker') pickerE!: any;
  
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  dataArray!:any; 
  searchPlantForm!: FormGroup;
  dateForm!: FormGroup;
  manualActualEntryForm!:FormGroup;
  searchPlants!: UserPlant[];
  plants!: UserPlant[];
  plant!: number;
  visionId!: any;
  IsEdit=false;
  todaysDate = new Date();
  dataArraykpi!: [];
  kpiTitle!: string;
  kpifrequency!: string;
  kpifrequencies!: Frequency[];
  ExtUploadActualkpifrequencies!: Frequency[];
  ExtUploadTargetkpifrequencies!: Frequency[];
  kpiUom!: string;
  targetActualEntryDate!: any;
  frequencies!: any[];
  drivers!: Driver[];
  kpiDriver!: Driver[];
  pillars!: Pillar[];
  kpiPillar: string[] = [];
  kpidepartments: string[] = [];
  departments!: Department[];
  components: any;
  visibleVisionTarget = false;
  visibleYearlyTarget = false;
  visibleQutarlyTarget = false;
  visibleMonthlyTarget = false;
  visibleWeeklyTarget = false;
  visibleDailyTarget = false;
  visibleShiftlyTarget = false;
  gridApi: any;
  gridColumnApi: any;
  gridApiEdit: any;
  gridColumnApiEdit: any;
  columnDefsYearly: any;
  columnDefsMonth: any;
  columnDefsQuarter: any;
  columnDefsWeek: any;
  columnDefsDay: any;
  columnDefsShift: any;
  columnDefs: any;
  dayOffList: number[] = [];
  userMenu!: UserManu[];
  actualPermission!: UserManu;
  dateTime!: Date;
  frameworkComponents: any;
  ExtensionUploadActual:boolean=false;
  ExtensionUploadTarget:boolean=false;
  defaultFromDate: Date;
  defaultToDate: Date;
  range = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl()
  });


  filterForm: FormGroup = this.fb.group(
  {
    fromDate: ['', [Validators.required]],
    toDate: ['', [Validators.required]],
    ExtUploadActualkpifrequencies: ['', [Validators.required]],
  });


  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  groupList: any;
  isLoading: any;
  isLoadingTarget: any;
  isLoadingActual: any;
  ButtonType!: any;
  loadingOverlayComponent: any;
  user: any;
  userListRaci: any;
  userListPMD: any;
  today = new Date();
  dialogRef: any;
  excelData: any;
  gridRowIndex: any;
  gridColId: any;
  rowParams: any;
  timeFormGroup: FormGroup;
  DataTypeId: any;
  KPIObject: any;
  Frequency: any;
  EditObj: any;
  KPIID: any;
  constructor(
    private primengConfig: PrimeNGConfig,
    private kpiactualentry: KpiactualentryService,
    private toaster: ToastrService,
    private kpiservice: KpitreeService,
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private translater: TranslaterService,
    private dialog: MatDialog,
    public datepipe: DatePipe
  ) {
    this.loadingOverlayComponent = 'customLoadingOverlay';
    // LicenseManager.setLicenseKey('kazaz');
    this.columnDefsShift = [
      {
        field: 'EntryDate',
        sortable: true,
        filter: 'agDateColumnFilter',
        editable: true,
        cellEditor: 'primeCellEditor',
        // filterParams: {
        //   comparator: myDateComparator,
        // },
        valueFormatter: function (params: any) {
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'Shift', sortable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this) },
      {
        field: 'Value',
        sortable: true,
        filter: true,
        editable: false,
        valueParser: (params: any) => this.inputValidationEdit(params),
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'Week', sortable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this)},
      { field: 'Month', sortable: true, filter: true, suppressToolPanel: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Quarter', sortable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Day', sortable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Year', sortable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'KpiId', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      {
        field: 'AEId',
        headerName: 'Action',
        // cellRenderer: 'btnCellRenderer',
        cellRenderer: (params: any): any => {
          return `
            <div>
            <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="warn" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary"  ng-reflect-color="primary" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
            `;
        },
        filter: false,
        minWidth: 150,
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    this.columnDefs = [
      // { field: 'KpiCode',  headerName: 'KPI Code', sortable: true, filter: true, cellStyle:  (params: any) => {
      //   return {backgroundColor: params.data.FrequecncyColor };
      // }, },
      {
        field: 'KpiName',
        headerName: 'KPI Name',
        sortable: true,
        filter: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Frequency',
        sortable: true,
        filter: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ShiftName',
        sortable: true,
        filter: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Shift',
        sortable: true,
        filter: true,
        hide: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'TargetValue',
        sortable: true,
        filter: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActualValue',
        sortable: true,
        filter: true,
        onCellClicked: this.makeCellClicked.bind(this),
        editable:false,
        // editable: (params: any) => {
        //   if (params.data.AllowActualEntry === true && params.data.KPIDataTypeId === 1 || params.data.KPIDataTypeId === 4) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // },
        valueParser: (params: any) => this.inputValidation(params),
        cellStyle: (params: any) => {
          if (params.data.AllowActualEntry === true) {
            return { backgroundColor: '#daa50f' };
          } else {
            return;
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'KpiId',
        filter: false,
        headerName: 'Historical Data',
        cellRenderer: 'btnCellRendererActualEntry',
        
        cellRendererParams: {
          onClick: this.viewActualKpiEntry.bind(this),
        },
        minWidth: 150,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      { field: 'VisionId', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'PlantId', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'kpiUom', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      {
        field: 'Driver',
        sortable: true,
        filter: true,
        hide: false,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Pillar',
        sortable: true,
        filter: true,
        hide: false,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Department',
        sortable: true,
        filter: true,
        hide: false,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'AEId', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'FrequencyId', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'UomId', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'KpiCode', sortable: true, filter: true, hide: true, headerValueGetter: this.localizeHeader.bind(this) },
  
    ];
    this.getGroupData();
  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.dateTime = new Date();
    // const today = new Date();
    // this.targetActualEntryDate = today.getDate() + '/' + today.getMonth() + '/' + today.getFullYear();
    // this.targetActualEntryDate = new Date();
    // this.targetActualEntryDate.setMonth(this.targetActualEntryDate.getMonth());
    // const today = new Date();
    // this.targetActualEntryDate =
    //   today.getDate() +
    //   '/' +
    //   (today.getMonth() + 1) +
    //   '/' +
    //   today.getFullYear();
    const listmenu = localStorage.getItem('result');
    const permissions = listmenu !== null ? JSON.parse(listmenu) : [];
    // this.actualPermission = permissions.find(
    //   (x: { ParentName: string; MenuName: string; Actions: string }) =>
    //     x.ParentName === 'KPI Management' &&
    //     x.MenuName === 'KPI Actual Entry' &&
    //     x.Actions === 'KPI Actual Entry'
    // );
    this.primengConfig.ripple = true;
    this.searchPlantForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
    });
    this.dateForm = this.fb.group({
      date: ['', Validators.required],
    });
    this.dateForm.get('date').setValue(new Date());
    this.timeFormGroup = this.fb.group({
      timepicker: ['', Validators.required],
    });
    this.timeFormGroup.controls.timepicker.valueChanges.subscribe((value: any) => {
      this.onChangeHour(value);
    });
    this.manualActualEntryForm=this.fb.group({
      date: ['', Validators.required],
      value:['',Validators.required]
    });
     this.DefaultDate();

    this.range.valueChanges.subscribe(res=>{
      if(res.toDate == null)
      {
        this.spinner.hide();
      }
      else{
        this.filterForm.value.fromDate = res.fromDate;
        this.filterForm.value.toDate = res.toDate;
      }})
  }
  DefaultDate()
  {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 7);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);
    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.defaultFromDate = stDT;
    this.defaultToDate = edDT;
  }
 




  makeCellClicked(params: any) {
    if (params.data.AllowActualEntry) {
      if (params.data.KPIDataTypeId === 2) {
        this.reOpenCalendar();
      }
      else if (params.data.KPIDataTypeId === 3) {
        this.reOpenTimePicker();
      }
      this.gridColId = params.column.colId;
      this.gridRowIndex = params.rowIndex;
      this.rowParams = params;
    }
   
 
    // this.method();
  }
  gridDate(event: any): void {
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.gridRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(this.gridColId,moment(event.value).format('DD/MM/YYYY'));
  }
  onChangeHour(event: any): void {
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.gridRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(this.gridColId,(event));
  }
  reOpenCalendar(): any {
    const self = this;
    setTimeout(() => {
      self.picker.open();
    }, 50);
  }
  reOpenTimePicker(): any {
    const self = this;
    setTimeout(() => {
      self.pickerE.open();
      // self.pickerE.data('clockpicker').show();
    }, 50);
  }
  
  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe(
  //     (plants) => {
  //       this.searchPlants = plants;
  //       this.plants = plants;
  //       if (this.searchPlants.length === 1) {
  //         const plantObject = this.searchPlants[0];
  //         this.searchPlantForm.get('plant')?.setValue(plantObject);
  //         this.getMasterData(plantObject);
  //       }
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    return (
      !!this.dayOffList.find((x) => x === day) &&
      differenceInCalendarDays(d, this.today) < 0
    );
  };
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today

    differenceInCalendarDays(current, this.today) > 0;
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.searchPlantForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        this.plants = res.DepartList;
        const plantObject = this.searchPlants[0];
        this.searchPlantForm.get('plant')?.setValue(plantObject);
        this.getMasterData();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  inputValidation(params: any): any {
    if (this.IsWrite) {
      if(params.data.KPIDataTypeId == 1){
        if (isNaN(params.newValue)) {
        this.toaster.warning('Invalid!', 'Please Enter Integar Value Only');
        return params.oldValue;
       } else {
        if (params.newValue >= 100000000000000) {
          this.toaster.warning(
            'Max Length Exceeded!',
            'Please Enter Valid Value'
          );
          return params.oldValue;
        } else {
          return params.newValue;
        }
       }
      }
      else{
        if (params.newValue >= 100000000000000) {
          this.toaster.warning(
            'Max Length Exceeded!',
            'Please Enter Valid Value'
          );
          return params.oldValue;
        } else {
          return params.newValue;
        }

      }
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
      return params.oldValue;
    }
  }
  // inputValidation(params: any): any {
  //   this.user = localStorage.getItem('user');
  //   this.user = JSON.parse(this.user);
  //   if (this.IsWrite) {
  //     if (
  //       params.data.ResponsibleRoleId === '' ||
  //       params.data.ResponsibleRoleId === null
  //     ) {
  //       if (isNaN(params.newValue)) {
  //         this.toaster.warning('Invalid!', 'Please Enter Integar Value Only');
  //         return params.oldValue;
  //       } else {
  //         if (params.newValue >= 100000000000000) {
  //           this.toaster.warning(
  //             'Max Length Exceeded!',
  //             'Please Enter Valid Value'
  //           );
  //           return params.oldValue;
  //         } else {
  //           return params.newValue;
  //         }
  //       }
  //     } else {
  //       let isExistUserRaci = this.userListRaci?.find(
  //         (u) => u.UserId === this.user.UserId
  //       );
  //       let isExistUserInResponsibleRole = this.userListPMD?.find(
  //         (u) => u.UserId === this.user.UserId
  //       );
  //       if (isExistUserRaci) {
  //         return params.newValue;
  //       } else if (isExistUserInResponsibleRole) {
  //         return params.newValue;
  //       } else {
  //         this.toaster.error(
  //           'Alert!',
  //           'You are not Authorize Person for this Action'
  //         );
  //         return params.oldValue;
  //       }
  //     }
  //   } else {
  //     this.toaster.error('Alert!', 'you do not have permission to access');
  //     return params.oldValue;
  //   }
  // }
  async getUserAuthorization(params: any): Promise<any> {
    const key = {
      KpiId: params.data.KpiId,
    };
    const dto = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: params.data.ResponsibleRoleId,
    };
    this.userListRaci = await this.getRaciInformation(key);
    this.userListPMD = await this.kpiservice.getPositionMappedUsers(dto);
  }

  async getPositionMappedUsers(dto: any) {
    this.userListPMD = [];
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        this.userListPMD = await this.kpiservice.getPositionMappedUsers(dto);
        resolve(this.userListRaci);
      }, 2000);
    });
  }

  async getRaciInformation(key: any) {
    this.userListRaci = [];
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        this.userListRaci = await this.kpiactualentry.getRaciInformation(key);
        resolve(this.userListRaci);
      }, 2000);
    });
  }

  inputValidationEdit(params: any): any {
    if (this.IsWrite) {
      if (isNaN(params.newValue)) {
        this.toaster.warning('Invalid!', 'Please Enter Integar Value Only');
        return params.oldValue;
      } else {
        if (params.newValue >= 100000000000000) {
          this.toaster.warning(
            'Max Length Exceeded!',
            'Please Enter Valid Value'
          );
          return params.oldValue;
        } else {
          return params.newValue;
        }
      }
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
      return params.oldValue;
    }
  }

  onCellMouseOver(params) {
    if (
      params.column.colId === 'ActualValue' &&
      params.data.AllowActualEntry === true
    ) {
      this.getUserAuthorization(params);
    }
  }

  onCellValueEdit(params: any): void {
    const dateString = params.data.ActualEntryDate;
    const dateParts = dateString.split('/');
    const dateObject = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
    const data = {
      kpiId: params.data.KpiId,
      YearDate: dateObject,
      ActualValue: params.data.Value > 0 ? params.data.Value : 0,
      AEId: params.data.AEId,
      createdBy: 1,
    };
    this.kpiactualentry.addActualEntry(data).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Data Updated Successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onCellValueChanged(params: any): void {
    debugger;
    let shiftValue: number | null = null;
    if (params.data.Shift !== undefined) {
      shiftValue = parseInt(params.data.Shift, 10);
    }
    const today = this.dateForm.value.date;
    const quarter = Math.floor((today.getMonth() + 3) / 3);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();
    const currentWeek = this.getWeekNumber(today);
    const dateObject = new Date(this.dateForm.value.date); // getSqlDate(new date);
    dateObject.setHours(new Date().getHours());
    dateObject.setMinutes(new Date().getMinutes());
    dateObject.setSeconds(new Date().getSeconds());
    const monthly = {
      kpiId: params.data.KpiId,
      frequecnyId: params.data.FrequencyId,
      uomId: params.data.UomId,
      plantId: params.data.PlantId,
      visionId: params.data.VisionId,
      kpiCode: params.data.KpiCode,
      YearDate: moment(dateObject).utc(),
      ActualValue: params.data.ActualValue,
      AEId: params.data.AEId,
      createdBy: JSON.parse(localStorage.user).UserId,
      currentshift: shiftValue,
      createdOn: new Date(),
      YearFrom: currentYear,
      currentquarter: quarter,
      currentmonth: currentMonth,
      currentweek: currentWeek,
      currentday: currentDay,
    };
    if (params.data.Frequency === 'Yearly') {
      const actualEntreis = this.dataArray.filter(
        (x: { EntryDate: string | number | Date }) =>
          new Date(x.EntryDate).getFullYear() === currentYear
      );
      if (actualEntreis.length > 0) {
        this.toaster.error(
          'Alert!!',
          'Actual Entry Of This Quarter Already Added'
        );
        return;
      } else {
        monthly.currentquarter = 0;
        monthly.currentmonth = 0;
        monthly.currentweek = 0;
        monthly.currentday = 0;
        monthly.currentshift = 0;
      }
    } else if (params.data.Frequency === 'Quarterly') {
      const actualEntreis = this.dataArray.filter(
        (x: { EntryDate: string | number | Date }) =>
          Math.floor((new Date(x.EntryDate).getMonth() + 3) / 3) === quarter &&
          new Date(x.EntryDate).getFullYear() === currentYear
      );
      if (actualEntreis.length > 0) {
        this.toaster.error(
          'Alert!!',
          'Actual Entry Of This Quarter Already Added'
        );
        return;
      } else {
        monthly.currentmonth = 0;
        monthly.currentweek = 0;
        monthly.currentday = 0;
        monthly.currentshift = 0;
      }
    } else if (params.data.Frequency === 'Monthly') {
      const actualEntreis = this.dataArray.filter(
        (x: { EntryDate: string | number | Date }) =>
          new Date(x.EntryDate).getMonth() + 1 === currentMonth &&
          new Date(x.EntryDate).getFullYear() === currentYear
      );
      if (actualEntreis.length > 0) {
        this.toaster.error(
          'Alert!!',
          'Actual Entry Of This Month Already Added'
        );
        return;
      } else {
        monthly.currentday = 0;
        monthly.currentshift = 0;
      }
    } else if (params.data.Frequency === 'Weekly') {
      const actualEntreis = this.dataArray.filter(
        (x: { EntryDate: string | number | Date }) =>
          this.getWeekNumber(new Date(x.EntryDate)) === currentWeek &&
          new Date(x.EntryDate).getMonth() + 1 === currentMonth &&
          new Date(x.EntryDate).getFullYear() === currentYear
      );
      if (actualEntreis.length > 0) {
        this.toaster.error(
          'Alert!!',
          'Actual Entry Of This Week Already Added'
        );
        return;
      } else {
        monthly.currentday = 0;
        monthly.currentshift = 0;
      }
    } else if (params.data.Frequency === 'Daily') {
      const actualEntreis = this.dataArray.filter(
        (x: { EntryDate: string | number | Date }) =>
          new Date(x.EntryDate).getDate() === currentDay &&
          this.getWeekNumber(new Date(x.EntryDate)) === currentWeek &&
          new Date(x.EntryDate).getMonth() + 1 === currentMonth &&
          new Date(x.EntryDate).getFullYear() === currentYear
      );
      if (actualEntreis.length > 0) {
        this.toaster.error('Alert!!', 'Actual Entry Of This Day Already Added');
        return;
      } else {
        monthly.currentshift = 0;
      }
    } else if (params.data.Frequency === 'Shiftly') {
      const actualEntreis = this.dataArray.filter(
        (x: { EntryDate: string | number | Date; Shift: number }) =>
          new Date(x.EntryDate).getDate() === currentDay &&
          this.getWeekNumber(new Date(x.EntryDate)) === currentWeek &&
          x.Shift === shiftValue &&
          new Date(x.EntryDate).getMonth() + 1 === currentMonth &&
          new Date(x.EntryDate).getFullYear() === currentYear
      );
      if (actualEntreis.length > 0) {
        this.toaster.error(
          'Alert!!',
          'Actual Entry Of This Shift Already Added'
        );
        return;
      }
    }
    this.kpiactualentry.addActualEntry(monthly).subscribe(
      (response: any) => {
        if (params.data.AEId === null) {
          params.data.AEId = response.AeId;
        }
        this.toaster.success('Success', 'Data Updated Successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getWeekNumber(d: any): any {
    const date = new Date(d.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    const weekNumber =
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      );
    return weekNumber;
  }
  viewActualKpiEntry(e: any): any {
    this.resetEntry();
    
   

  

    if (this.IsAccess) {
      this.IsEdit=false;
      this.visionId = e.rowData.VisionId;
      this.DataTypeId = e.rowData.KPIDataTypeId;
      //////////////////////////////////////////////
      this.Frequency = e.rowData.Frequency;
      this.KPIID = e.rowData.KpiId;
      let shiftValue: number | null = null;
      if (e.rowData.Shift !== undefined) {
        shiftValue = parseInt(e.rowData.Shift, 10);
      }
   
      let objectForKPI = {
        kpiId: e.rowData.KpiId,
        frequecnyId: e.rowData.FrequencyId,
        uomId: e.rowData.UomId,
        plantId: e.rowData.PlantId,
        visionId: e.rowData.VisionId,
        kpiCode: e.rowData.KpiCode,
        YearDate: '',
        ActualValue: e.rowData.ActualValue,
        AEId: e.rowData.AEId,
        createdBy: JSON.parse(localStorage.user).UserId,
        currentshift: shiftValue,
        createdOn: new Date(),
        YearFrom: '',
        currentquarter: '',
        currentmonth: '',
        currentweek: '',
        currentday: '',
      };
      this.KPIObject = objectForKPI;
      /////////////////////////////////////////////////
      if(this.DataTypeId==1){
        // this.manualActualEntryForm.value.date
        this.manualActualEntryForm.controls['value'].setValidators([
          Validators.required,Validators.pattern(/^[-+]?\d+(\.\d+)?$/)
        ]);
        this.manualActualEntryForm.controls[
          'value'
        ].updateValueAndValidity();
      }
      else{
        this.manualActualEntryForm.controls['value'].setValidators([
          Validators.required
        ]);
        this.manualActualEntryForm.controls[
          'value'
        ].updateValueAndValidity();
      }
      const getActualEntry = {
        visionId: e.rowData.VisionId,
        kpiId: e.rowData.KpiId,
        frequency: e.rowData.Frequency,
      };
      this.kpiservice.getActualEntries(getActualEntry).subscribe(
        (response: any) => {
          this.dataArraykpi = response;
          this.kpiTitle = e.rowData.Name;
          this.kpifrequency = e.rowData.Frequency;
          this.kpiUom = e.rowData.kpiUom;
          if (e.rowData.Frequency === 'Yearly') {
            this.visibleShiftlyTarget = true;
            this.gridColumnApiEdit.setColumnsVisible(
              ['Quarter', 'Month', 'Week', 'Day', 'Shift'],
              false
            );
          } else if (e.rowData.Frequency === 'Quarterly') {
            this.visibleShiftlyTarget = true;
            this.gridColumnApiEdit.setColumnsVisible(
              ['Month', 'Week', 'Day', 'Shift'],
              false
            );
            this.gridColumnApiEdit.setColumnsVisible(['Quarter', 'AEId'], true);
          } else if (e.rowData.Frequency === 'Monthly' || e.rowData.Frequency === 'BiMonthly') {
            this.visibleShiftlyTarget = true;
            this.gridColumnApiEdit.setColumnsVisible(
              ['Week', 'Day', 'Shift'],
              false
            );
            this.gridColumnApiEdit.setColumnsVisible(
              ['Quarter', 'Month', 'AEId'],
              true
            );
          } else if (e.rowData.Frequency === 'Weekly') {
            this.visibleShiftlyTarget = true;
            this.gridColumnApiEdit.setColumnsVisible(['Day', 'Shift'], false);
            this.gridColumnApiEdit.setColumnsVisible(
              ['Quarter', 'Month', 'Week', 'AEId'],
              true
            );
          } else if (e.rowData.Frequency === 'Daily') {
            this.visibleShiftlyTarget = true;
            this.gridColumnApiEdit.setColumnsVisible(['Shift'], false);
            this.gridColumnApiEdit.setColumnsVisible(
              ['Quarter', 'Month', 'Week', 'Day', 'AEId'],
              true
            );
          } else if (e.rowData.Frequency === 'Shiftly') {
            this.visibleShiftlyTarget = true;
            this.gridColumnApiEdit.setColumnsVisible(
              ['Quarter', 'Month', 'Week', 'Day', 'Shift', 'AEId'],
              true
            );
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  cellClicked(params: any): void {
    debugger;
    const col = params.colDef.field;

    this.ButtonType = '';

    if (col === 'AEId') {
      this.ButtonType = params.event.target.classList[1];
      if (this.ButtonType === 'fa-trash') {
        this.ButtonType = '';
        if (this.IsWrite) {
          const key = {
            aeid: params.data.AEId,
          };
          this.kpiactualentry.deleteActualEntry(key).subscribe(
            (response: any) => {
              this.toaster.success('Success', 'Data Delete Successfully');
              this.getMasterData();


              
        const date = this.dateForm.value.date; // .format('YYYY-MM-DD');
        const startOfDay = date.setHours(0, 0, 0, 0);
       const endOfDay = date.setHours(23, 59, 59, 0);
  
       
       const KpiKey = {
         // date: moment(value).format('YYYY-MM-DD'),
         fromDate: moment(startOfDay).utc(),
         toDate: moment(endOfDay).utc(),
         plantId: this.searchPlantForm.value.plant.DeptId,
         userId: JSON.parse(localStorage.user).UserId,
         KpiCode : params.data.kpiCode
       };


       this.kpiactualentry.GetKPIActualEntryForKpi(KpiKey).subscribe((res)=>{

         let  rowNode;
   
         this.gridApi.forEachLeafNode((node: any) => {
           if (node.data.KpiId === this.KPIObject.kpiId) {
             // If it does, set the row node variable to the current node
             rowNode = node;
           }
         });
        
         rowNode.data.ActualValue=res[0].ActualValue;
         this.gridApi.redrawRows({ rowNodes: [rowNode] });


       });



              






              const getValue = {
                visionId: this.visionId,
                kpiId: params.data.KpiId,
                frequency: this.kpifrequency,
              };
              this.kpiservice.getActualEntries(getValue).subscribe(
                (data: any) => {
                  this.dataArraykpi = data;
                },
                (error: any) => {
                  this.toaster.error('Error', error.message);
                }
              );
            },
            (error: any) => {
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          this.toaster.error('Alert!', 'you do not have permission to access');
        }
      }
      if (this.ButtonType === 'fa-pencil') {
        this.ButtonType = '';
        if (this.IsWrite) {
        this.EditObj = params.data;
        this.IsEdit=true;
     
        let utcDateString = params.data.EntryDate?params.data.EntryDate:params.data.Date;
        let utcDate = new Date(utcDateString);
        let year = utcDate.getFullYear();
        let month = utcDate.getMonth() + 1; // month starts from 0, so add 1 to get the actual month
        let day = utcDate.getDate();
        let hours = utcDate.getHours();
        let mins = utcDate.getMinutes();
        
        

        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
        // console.log(formattedDate,"formatted date");
        // console.log(new Date(params.data.EntryDate?params.data.EntryDate:params.data.Date).toISOString().substr(0, 16),"DATE")
        this.manualActualEntryForm.get('date').setValue(formattedDate);
        this.manualActualEntryForm.get('value').setValue(params.data.Value);
        
         
        } else {
          this.toaster.error('Alert!', 'you do not have permission to access');
        }
      }


    }
  }
  deletekpiActualEntry(e: any): any {
    if (this.IsWrite) {
      const key = {
        aeid: e.rowData.AEId,
      };
      this.kpiactualentry.deleteActualEntry(key).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Data Delete Successfully');
          this.getMasterData();
          const getValue = {
            visionId: this.visionId,
            kpiId: e.rowData.KpiId,
            frequency: this.kpifrequency,
          };
          this.kpiservice.getActualEntries(getValue).subscribe(
            (data: any) => {
              this.dataArraykpi = data;
            },
            (error: any) => {
              this.toaster.error('Error', error.message);
            }
          );
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  getFrequesncies(): void {
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.frequencies = frequency;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getDrivers(): void {
    this.kpiMaster.getDrivers().subscribe(
      (drivers) => {
        this.drivers = drivers;
      },
      (error) => {
        this.toaster.error('Error!', error.message);
      }
    );
  }
  // getDepartments(): void {
  //   this.kpiMaster.getDepartments().subscribe(
  //     (dep) => {
  //       this.departments = dep;
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  getPillars(): void {
    this.kpiMaster.getPillars().subscribe(
      (pillars) => {
        this.pillars = pillars;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onFrequcnyChange(event: any): any {
    const instance = this.gridApi.getFilterInstance('Frequency');
    const myArray: any = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < event.value.length; i++) {
      myArray.push(event.value[i].name);
    }
    instance.setModel({
      values: myArray.length > 0 ? myArray : null,
    });
    if (myArray.includes('Shiftly')) {
      this.gridColumnApi.setColumnVisible('Shift', true);
    } else {
      this.gridColumnApi.setColumnVisible('Shift', false);
    }
    this.gridApi.onFilterChanged();
  }
  onDriversChange(event: any): any {
    const instance = this.gridApi.getFilterInstance('Driver');
    const model = instance.getValues();
    const myArray: any = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < event.value.length; i++) {
      myArray.push(event.value[i].name);
    }
    instance.setModel({
      values: myArray.length > 0 ? myArray : null,
    });
    this.gridApi.onFilterChanged();
  }
  onPillarChange(event: any): any {
    const instance = this.gridApi.getFilterInstance('Pillar');
    const model = instance.getValues();
    const myArray: any = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < event.value.length; i++) {
      myArray.push(event.value[i].name);
    }
    instance.setModel({
      values: myArray.length > 0 ? myArray : null,
    });
    this.gridApi.onFilterChanged();
  }
  onDepartmentChange(event: any): any {
    const instance = this.gridApi.getFilterInstance('Department');
    const model = instance.getValues();
    const myArray: any = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < event.value.length; i++) {
      myArray.push(event.value[i].name);
    }
    instance.setModel({
      values: myArray.length > 0 ? myArray : null,
      type: 'contains',
    });
    this.gridApi.onFilterChanged();
  }
  onDateChange(value: any): any {
    const date = value; // .format('YYYY-MM-DD');
    const startOfDay = date.setHours(0, 0, 0, 0);
    const endOfDay = date.setHours(23, 59, 59, 0);
    const getdata = {
      // date: moment(value).format('YYYY-MM-DD'),
      fromDate: moment(startOfDay).utc(),
      toDate: moment(endOfDay).utc(),
      plantId: this.searchPlantForm.value.plant.DeptId,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.gridApi.showLoadingOverlay();
    // this.kpifrequencies = [];
    // this.kpiDriver = [];
    // this.kpiPillar = [];
    // this.kpidepartments = [];
    if (getdata.plantId === undefined) {
      this.toaster.warning('Warning', 'Please Select Plant');
      this.isLoading = false;
      return;
    }
    this.frequencies = [];
    this.gridApi.showLoadingOverlay();
    this.kpiactualentry.getKPIActualEntry(getdata).subscribe(
      (response: any) => {
        // this.dataArray =response.filter((item, index) => {
        //   return !response.slice(index + 1).some(other => other.KpiCode === item.KpiCode);
        // });
        this.dataArray  = response
        this.gridApi.showLoadingOverlay();
        this.gridApi.setRowData(response);
        this.gridOptions.api.hideOverlay();
        // const distinctFrequency = response.map(c => c.Frequency);
        const map = new Map();
        const finalarr = [];
        for (const item of response) {
          if (!map.has(item.Frequency)) {
            map.set(item.Frequency, true); // set any value to Map
            finalarr.push({
              name: item.Frequency,
            });
          }
        }
        this.frequencies = [...new Set(finalarr)];
        this.isLoading = false;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.gridOptions.api.hideOverlay();
        this.isLoading = false;
      }
    );
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }
  getMasterData(): any {
    this.kpifrequencies = [];
    this.kpiDriver = [];
    this.kpiPillar = [];
    this.kpidepartments = [];
    // this.getFrequesncies();
    // this.dataArray = [];
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiMaster.getMasterDayOff(key).subscribe(
      (data: any) => {
        this.dayOffList = this.kpiMaster.getDayOfflist(data);
      },
      (error: any) => {
        this.toaster.error('error', error.error.message);
      }
    );
    this.kpiMaster.getPillarMasterData(key).subscribe(
      (pillars) => {
        this.pillars = pillars;
      },
      (error) => {
        this.toaster.error(error.message);
      }
    );
    this.kpiMaster.getMasterDrivers(key).subscribe(
      (drivers) => {
        this.drivers = drivers;
      },
      (error) => {
        this.toaster.error(error.message);
      }
    );
    // this.kpiMaster.getMasterFrequencies(key).subscribe(
    //   (frequency) => {
    //     this.frequencies = frequency;
    //   },
    //   (error) => {
    //     this.toaster.error('Error', error.message);
    //   }
    // );
    this.kpiMaster.getMasterDepartments(key).subscribe(
      (departments) => {
        this.departments = departments;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
    this.onDateChange(new Date());
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value:string){
    let val;
    if(this.langObj && this.langObj[value]){
      val = this.langObj[value];
    }
    return val?val:value;
 }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if(this.langObj){
        this.gridApi.refreshHeader();
      }
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  refreshData(): void {
    this.isLoading = true;
    const key = {
      ptId: this.searchPlantForm.value.plant.DeptId,
      currentDate: this.dateForm.value.date,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.kpiactualentry.executeActualEntry(key).subscribe(
      (data: any) => {
        // setTimeout(() => {
       
        this.onDateChange(this.dateForm.value.date);
        // }, 3000);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  UploadActualEntry() {
    if (this.searchPlantForm.valid) {
      this.dialogRef = this.dialog.open(this.uploadActualDialog, {
        width: '600px',
        // height:'300px'
      });
      this.dialogRef.afterClosed().subscribe((result: any) => {});
    } else {
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 3000,
      });
      return;
    }
  }
  UploadTargetEntry() {
    if (this.searchPlantForm.valid) {
      this.dialogRef = this.dialog.open(this.uploadTargetDialog, {
        width: '600px',
      });
      this.dialogRef.afterClosed().subscribe((result: any) => {});
    } else {
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 3000,
      });
      return;
    }
  }
  openActualFile() {
    this.ExtensionUploadActual = false;
    this.fileInput.nativeElement.click();
  }
  openTargetFile() {
    this.ExtensionUploadTarget = false;
    this.fileInput.nativeElement.click();
  }
  onCancelActualClick(): void {
    this.ExtensionUploadActual = false;
    this.dialogRef.close();
  }
  onCancelTargetClick(): void {
    this.ExtensionUploadTarget = false;
    this.dialogRef.close();
  }
  extensionUploadActual(){
    this.ExtensionUploadActual = true;
  }
  validkpifrequencies(type:string):boolean{
    
    if(type === 'Actualkpifrequencies')
    {
    if(this.ExtUploadActualkpifrequencies === undefined)
    {
      this.toaster.error("Please Select a Frequency")
      return false 
    }
   
    else
    {
      return true
    }
    }

    else{
      if(this.ExtUploadTargetkpifrequencies === undefined)
    {
      this.toaster.error("Please Select a Frequency")
      return false 
    }
   
    else
    {
      return true
    }

    }
   
  }

  getExcelData(): any {

    if(this.validkpifrequencies("Actualkpifrequencies")== true)
    {

    this.isLoadingActual = true;
    const freq = this.ExtUploadActualkpifrequencies.map((x:any)=>x.name).toString()
    const obj = {
      ptId: this.searchPlantForm.value.plant.DeptId,
      userId: JSON.parse(localStorage.user).UserId,
      fromDate: moment(this.filterForm.value.fromDate).utc(),
      toDate: moment(this.filterForm.value.toDate).utc(),
      frequencies:freq

    };
    
    this.kpiactualentry
      .getActualEntryExportData(obj)
      .subscribe((response: any) => {
        this.isLoadingActual = false;
        if (response.length) {
          response.forEach((element: any) => {
            element.Date = moment(new Date(element.Date)).format(
              'DD/MM/YYYY HH:mm'
            );
          });
          const workBook = XLSX.utils.book_new();
          const yearRecord = response.filter((c) => c.Frequency === 'Yearly');
          const quarterRecord = response.filter(
            (c) => c.Frequency === 'Quarterly'
          );
          const monthRecord = response.filter((c) => c.Frequency === 'Monthly');
          const biMonthRecord = response.filter(
            (c) => c.Frequency === 'BiMonthly'
          );
          const weekRecord = response.filter((c) => c.Frequency === 'Weekly');
          const dayRecord = response.filter((c) => c.Frequency === 'Daily');
          const shiftRecord = response.filter((c) => c.Frequency === 'Shiftly');

          if (yearRecord.length) {
            const dataSheet = [];
            for (const i of yearRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Value *']: i.Value,
               
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'Yearly');
          }
          if (quarterRecord.length) {
            const dataSheet = [];
            for (const i of quarterRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Quarter']: i.Quarter,
                ['Value *']: i.Value,
               
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'Quarterly');
          }
          if (monthRecord.length) {
            const dataSheet = [];
            for (const i of monthRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Quarter']: i.Quarter,
                ['Month']: i.Month,
                ['Value *']: i.Value,
              
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'Monthly');
          }
          if (biMonthRecord.length) {
            const dataSheet = [];
            for (const i of biMonthRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Quarter']: i.Quarter,
                ['Month']: i.Month,
                ['Value *']: i.Value,
                
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'BiMonthly');
          }
          if (weekRecord.length) {
            const dataSheet = [];
            for (const i of weekRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Quarter']: i.Quarter,
                ['Month']: i.Month,
                ['Week']: i.Week,
                ['Value *']: i.Value,
                
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'Weekly');
          }
          if (dayRecord.length) {
            const dataSheet = [];
            for (const i of dayRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Quarter']: i.Quarter,
                ['Month']: i.Month,
                ['Week']: i.Week,
                ['Day']: i.Day,
                ['Value *']: i.Value,
               
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'Daily');
          }
          if (shiftRecord.length) {
            const dataSheet = [];
            for (const i of shiftRecord) {
              const ExportData = {
                ['Group *']: i.Group,
                ['Plant *']: i.Plant,
                ['KPICode *']: i.KPICode,
                ['KPIName *']: i.KPIName,
                ['Frequency *']: i.Frequency,
                ['DataType *']: i.DataType,
                ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
                ['Year']: i.Year,
                ['Quarter']: i.Quarter,
                ['Month']: i.Month,
                ['Week']: i.Week,
                ['Day']: i.Day,
                ['Shift *']: i.Shift,
                ['Value *']: i.Value,
                
              };
              dataSheet.push(ExportData);
            }
            const sheet = XLSX.utils.json_to_sheet(dataSheet);
            XLSX.utils.book_append_sheet(workBook, sheet, 'Shift');
          }
          XLSX.writeFile(workBook, `Export_KPI_ActualEntry.xlsx`);
        } else {
          this.toaster.warning('warning', 'Data Not Found aginst this plant');
        }
        // this.excelService.exportAsExcelFile(response, 'Actual KPI Entry');
      });
    }
  }

  extensionUploadTarget()
  {
    this.ExtensionUploadTarget = true;
  }
  getTargetExcelData(): any {
    if(this.validkpifrequencies("Targetkpifrequencies")== true)
    {

    this.isLoadingTarget = true;
   const freqName= this.ExtUploadTargetkpifrequencies.map((x:any)=>x.name).toString()
 
    const ptId = this.searchPlantForm.value.plant;
    const key = {
      ptId: this.searchPlantForm.value.plant.DeptId,
      userId: JSON.parse(localStorage.user).UserId,
      fromDate: moment(this.filterForm.value.fromDate).utc(),
      toDate: moment(this.filterForm.value.toDate).utc(),
      frequencies:freqName,
    };
    this.kpiactualentry.getTargetExportData(key).subscribe((response: any) => {
      this.isLoadingTarget = false;
      if (response.length) {
        response.forEach((element: any) => {
          element.Date = moment(new Date(element.Date)).format(
            'DD/MM/YYYY HH:mm'
          );
        });
        const workBook = XLSX.utils.book_new();
        const yearRecord = response.filter((c) => c.Frequency === 'Yearly');
        const quarterRecord = response.filter(
          (c) => c.Frequency === 'Quarterly'
        );
        const monthRecord = response.filter((c) => c.Frequency === 'Monthly');
        const biMonthRecord = response.filter(
          (c) => c.Frequency === 'BiMonthly'
        );
        const weekRecord = response.filter((c) => c.Frequency === 'Weekly');
        const dayRecord = response.filter((c) => c.Frequency === 'Daily');
        const shiftRecord = response.filter((c) => c.Frequency === 'Shiftly');

        if (yearRecord.length) {
          const dataSheet = [];
          for (const i of yearRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Value *']: i.Value,
              
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'Yearly');
        }
        if (quarterRecord.length) {
          const dataSheet = [];
          for (const i of quarterRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Quarter']: i.Quarter,
              ['Value *']: i.Value,
             
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'Quarterly');
        }
        if (monthRecord.length) {
          const dataSheet = [];
          for (const i of monthRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Quarter']: i.Quarter,
              ['Month']: i.Month,
              ['Value *']: i.Value,
              
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'Monthly');
        }
        if (biMonthRecord.length) {
          const dataSheet = [];
          for (const i of biMonthRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Quarter']: i.Quarter,
              ['Month']: i.Month,
              ['Value *']: i.Value,
              
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'BiMonthly');
        }
        if (weekRecord.length) {
          const dataSheet = [];
          for (const i of weekRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Quarter']: i.Quarter,
              ['Month']: i.Month,
              ['Week']: i.Week,
              ['Value *']: i.Value,
             
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'Weekly');
        }
        if (dayRecord.length) {
          const dataSheet = [];
          for (const i of dayRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Quarter']: i.Quarter,
              ['Month']: i.Month,
              ['Week']: i.Week,
              ['Day']: i.Day,
              ['Value *']: i.Value,
             
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'Daily');
        }
        if (shiftRecord.length) {
          const dataSheet = [];
          for (const i of shiftRecord) {
            const ExportData = {
              ['Group *']: i.Group,
              ['Plant *']: i.Plant,
              ['KPICode *']: i.KPICode,
              ['KPIName *']: i.KPIName,
              ['Frequency *']: i.Frequency,
              ['DataType *']: i.DataType,
              ['Date (*DD/MM/YYYY HH:mm)']: i.Date,
              ['Year']: i.Year,
              ['Quarter']: i.Quarter,
              ['Month']: i.Month,
              ['Week']: i.Week,
              ['Day']: i.Day,
              ['Shift *']: i.Shift,
              ['Value *']: i.Value,
             
            };
            dataSheet.push(ExportData);
          }
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          XLSX.utils.book_append_sheet(workBook, sheet, 'Shift');
        }
        XLSX.writeFile(workBook, `Export_KPI_Target.xlsx`);
      } else {
        this.toaster.warning('warning', 'Data Not Found aginst this plant');
      }
      // this.excelService.exportAsExcelFile(response, 'Actual KPI Entry');
    });
    }
  }
  onActualReadExcel(event: any): any {
    
    this.spinner.show();
   if (this.searchPlantForm.invalid) {
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 3000,
      });
      this.spinner.hide();
    } else {
      let recordArr = [];
      this.excelData = event.target.files[0];
      // const fileName = this.excelData.name;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(this.excelData);
      fileReader.onload = (e) => {
        const arrayBuffer: any = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = new Array();
        let error = false;
        let RowCount=1;
        let date="";
        let time=";"
        let ReqDate ='01/08/2022';//Only for Datepipe to get time value 

        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < workbook.SheetNames.length; j++) {
          const sheetName = workbook.SheetNames[j];
          const worksheet = workbook.Sheets[sheetName];

          if (sheetName === 'Yearly') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
             for (var i = 0; i < record.length; i++) {
              
              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ `  ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                //   date="";
                //  date =this.datepipe.transform(record[i]['Value *'], 'dd/MM/yyyy');
                 let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                 let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
             
              for (const iterator of record) {
                recordArr.push(iterator);
              }
            
          } else if (sheetName === 'Quarterly') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] || !record[i]['Quarter'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on  row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;

              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Monthly') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['Month'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ `  ${sheetName} `,
                  'Error',
                );
               this.spinner.hide();
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'BiMonthly') {
  
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']){
                
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                    dateObject.toString()
                   date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }   for (var i = 0; i < record.length; i++) {
      
                if(record[i]['Value *'] != undefined)
                {
                if (record[i]['DataType *'] =='Decimal') {
                  RowCount = RowCount+1
                
                if(typeof(parseFloat(record[i]['Value *'])) !='number')
                {
                 
                  this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                  {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break    
                }}
                else if (record[i]['DataType *'] =='Date') {
                  RowCount = RowCount+1
                  try{
                    let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                 let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                  }
                  catch(exp){}
  
                  if(date =='01/01/1970' || date == undefined || date == "")
                  {
                    this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                  {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;  
                  }}
  
                  else if (record[i]['DataType *'] =='Time') {
                    RowCount = RowCount+1
                    try{
                      time="";
                      time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                    }
                    catch(exp){
  
                    }
  
                    if(time == undefined || time == "")
                    {
                      this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                      {
                      timeOut: 20000,
                    }); 
                    error=true;
                    this.spinner.hide();
                    break;   
                    }}
                   }
                 }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Weekly') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
           
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['Frequency *'] ||!record[i]['Month'] || !record[i]['Week']   || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName} `,
                  'Error',
                );
               this.spinner.hide();
               return;
               }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
               for (const iterator of record) {
                    recordArr.push(iterator);
              }
                
            
          } else if (sheetName === 'Daily') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if(!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['Week'] || !record[i]['Month'] ||!record[i]['Day']  ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']){
             
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
               }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Shift') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if(!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] || !record[i]['Week'] || !record[i]['Month'] ||!record[i]['Day'] ||!record[i]['Frequency *'] || !record[i]['Shift *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']){
             
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ `  ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                 
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          }
         
        }
        recordArr.forEach((el: any) => {

          let dateMomentObject = moment(el['Date (*DD/MM/YYYY HH:mm)'], "DD/MM/YYYY HH:mm"); // 1st argument - string, 2nd argument - format
        let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
          dateObject.toString()

          el.Group = el['Group *'];
          el.Plant = el['Plant *'];
          el.KPICode = el['KPICode *'];
          el.KPIName = el['KPIName *'];
          el.Frequency = el['Frequency *'];
          el.Shift = el['Shift *'];
          el.Date = dateObject;
          el.Value = el['Value *'];
          el.CreatedBy = JSON.parse(localStorage.user).UserId;
        });
        recordArr = recordArr.filter((c) => c.Value || c.Value === 0);
        
       
        if(error == false)
        {
          this.kpiactualentry.importActualEntry(recordArr).subscribe(
            (response: any) => {
              this.toaster.success('Success', response.message);
              this.spinner.show();
              this.onDateChange(this.dateForm.value.date);
              this.spinner.hide();
            },
            (error) => {
              this.toaster.error('Error', error.message);
              this.spinner.hide();
            }
          );
        }
        
        // this.validateSheet();
        this.dialogRef.close();
      };
    }
  }
  onTargetReadExcel(event: any): any {
    
    this.spinner.show();
    if (this.searchPlantForm.invalid) {
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 3000,
      });
      this.spinner.hide();
    } else {
      let recordArr = [];
      const excelData = event.target.files[0];
      // const fileName = this.excelData.name;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(excelData);
      fileReader.onload = (e) => {
        const arrayBuffer: any = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = new Array();
        let error = false;
        let RowCount=1;
        
        let date="";
        let time=";"
        let ReqDate ='01/08/2022';//Only for Datepipe to get time value 
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < workbook.SheetNames.length; j++) {
          const sheetName = workbook.SheetNames[j];
          const worksheet = workbook.Sheets[sheetName];
          if (sheetName === 'Yearly') {
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            
            for (var i = 0; i < record.length; i++) {
                
              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['DataType *'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Quarterly') {
            //const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *']  || !record[i]['DataType *'] ||!record[i]['Frequency *'] || !record[i]['Quarter'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on  row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Monthly') {
            //const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *']  || !record[i]['DataType *']  ||!record[i]['Frequency *'] || !record[i]['Quarter'] ||!record[i]['Month'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'BiMonthly') {
            //const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *']  || !record[i]['DataType *'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return; 
              }
      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true;
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Weekly') {
          //  const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {
      
              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['Quarter']  || !record[i]['DataType *']  ||!record[i]['Month'] || !record[i]['Week'] || !record[i]['KPICode *'] ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }

              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                   dateObject.toString()
                  date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Daily') {
           // const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
           const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *']  || !record[i]['DataType *']  ||!record[i]['Frequency *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }

      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                    dateObject.toString()
                   date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                this.spinner.hide();
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                  }); 
                  error=true;
                  this.spinner.hide();
                  break;   
                  }}
                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          } else if (sheetName === 'Shift') {
        //    const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        const record = XLSX.utils.sheet_to_json(worksheet, { raw: false,dateNF: "dd/mm/yyyy" });
            for (var i = 0; i < record.length; i++) {

              if (!record[i]['Year'] || !record[i]['Plant *'] || !record[i]['KPICode *'] ||!record[i]['Frequency *']  || !record[i]['DataType *']  || !record[i]['Shift *'] || !record[i]['Date (*DD/MM/YYYY HH:mm)']) {
                this.toaster.error(
                  `Cell can't be empty on row `+RowCount +` Sheet `+ ` ${sheetName}`,
                  'Error',
                );
               this.spinner.hide();
               return;
              }

      
              if(record[i]['Value *'] != undefined)
              {
              if (record[i]['DataType *'] =='Decimal') {
                RowCount = RowCount+1
              
              if(typeof(parseFloat(record[i]['Value *'])) !='number')
              {
               
                this.toaster.warning(`Value should be Numeric on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                timeOut: 20000,
              }); 
              error=true
              this.spinner.hide();
              break    
              }}
              else if (record[i]['DataType *'] =='Date') {
                RowCount = RowCount+1
                try{
                  let dateMomentObject = moment(record[i]['Value *'], 'dd/MM/yyyy'); // 1st argument - string, 2nd argument - format
                  let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                    dateObject.toString()
                   date =this.datepipe.transform(dateObject, 'dd/MM/yyyy');
                }
                catch(exp){}

                if(date =='01/01/1970' || date == undefined || date == "")
                {
                  this.toaster.warning(`Value should be Date Formate (01/01/1970) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                {
                  timeOut: 20000,
                }); 
                error=true;
                break;  
                }}

                else if (record[i]['DataType *'] =='Time') {
                  RowCount = RowCount+1
                  try{
                    time="";
                    time =this.datepipe.transform(ReqDate+' , '+record[i]['Value *'], 'dd/MM/yyyy , h:mm:ss');
                  }
                  catch(exp){

                  }

                  if(time == undefined || time == "")
                  {
                    this.toaster.warning(`Value should be Time Formate (hh:mm:ss) on row `+RowCount +` Sheet `+ `${sheetName}`,'Warning',
                    {
                    timeOut: 20000,
                     }); 
                    error=true;
                    this.spinner.hide();
                    break;   
                  }}

                 }
               }
            for (const iterator of record) {
              recordArr.push(iterator);
            }
          }
        }
        recordArr.forEach((el: any) => {

          let dateMomentObject = moment(el['Date (*DD/MM/YYYY HH:mm)'], "DD/MM/YYYY HH:mm"); // 1st argument - string, 2nd argument - format
          let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
            dateObject.toString()
          el.Group = el['Group *'];
          el.Plant = el['Plant *'];
          el.KPICode = el['KPICode *'];
          el.KPIName = el['KPIName *'];
          el.Frequency = el['Frequency *'];
          el.Shift = el['Shift *'];
          el.Date = dateObject;
          el.Value = el['Value *'];
          el.CreatedBy = JSON.parse(localStorage.user).UserId;
        });
        // const key={
        //   Group:recordArr[0]["Group *"],
        // }
        recordArr = recordArr.filter((c) => c.Value || c.Value === 0);
        if(error == false)
        {
        this.kpiactualentry.importTarget(recordArr).subscribe(
          (response: any) => {
            this.toaster.success('Success', response.message);
              this.spinner.show();
              this.onDateChange(this.dateForm.value.date);
              this.spinner.hide();
          },
          (error) => {
            this.toaster.error('Error', error.message);
          }
        );
        }
        // this.validateSheet();
        this.dialogRef.close();
      };
    }
  }

  SaveActualEntry(){
    const today = new Date(this.manualActualEntryForm.value.date);
    const quarter = Math.floor((today.getMonth() + 3) / 3);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();
    const currentWeek = this.getWeekNumber(today);
    const dateObject = new Date(this.manualActualEntryForm.value.date); // getSqlDate(new date);
    // dateObject.setHours(new Date().getHours());
    // dateObject.setMinutes(new Date().getMinutes());
    // dateObject.setSeconds(new Date().getSeconds());
 
    this.KPIObject.YearFrom = currentYear;
    this.KPIObject.currentquarter= quarter;
    this.KPIObject.currentmonth= currentMonth;
    this.KPIObject.currentweek= currentWeek;
    this.KPIObject.currentday= currentDay;
    this.KPIObject.YearDate= moment(dateObject).utc();
    this.KPIObject.ActualValue = this.manualActualEntryForm.value.value


    if (this.KPIObject.Frequency === 'Yearly') {
       
      this.KPIObject.currentquarter = 0;
      this.KPIObject.currentmonth = 0;
      this.KPIObject.currentweek = 0;
      this.KPIObject.currentday = 0;
      this.KPIObject.currentshift = 0;
      }
     else if (this.KPIObject.Frequency === 'Quarterly') {
     
        this.KPIObject.currentmonth = 0;
        this.KPIObject.currentweek = 0;
        this.KPIObject.currentday = 0;
        this.KPIObject.currentshift = 0;
     
    } else if (this.KPIObject.Frequency === 'Monthly') {
    
        this.KPIObject.currentday = 0;
        this.KPIObject.currentshift = 0;
      
    } else if (this.KPIObject.Frequency === 'Weekly') {
       
        this.KPIObject.currentday = 0;
        this.KPIObject.currentshift = 0;
     
    } else if (this.KPIObject.Frequency === 'Daily') {
   
        this.KPIObject.currentshift = 0;
      
    }  
    
    this.KPIObject.AEId =null;
    this.kpiactualentry.addActualEntry(this.KPIObject).subscribe(
      (response: any) => {
        if ( this.KPIObject.AEId === null) {
          this.KPIObject.AEId = response.AeId;
        }
        this.toaster.success('Success', 'Data Updated Successfully');
        this.resetEntry();

        const date = this.dateForm.value.date; // .format('YYYY-MM-DD');
         const startOfDay = date.setHours(0, 0, 0, 0);
        const endOfDay = date.setHours(23, 59, 59, 0);
   
        
        const KpiKey = {
          // date: moment(value).format('YYYY-MM-DD'),
          fromDate: moment(startOfDay).utc(),
          toDate: moment(endOfDay).utc(),
          plantId: this.searchPlantForm.value.plant.DeptId,
          userId: JSON.parse(localStorage.user).UserId,
          KpiCode : this.KPIObject.kpiCode
        };


        this.kpiactualentry.GetKPIActualEntryForKpi(KpiKey).subscribe((res)=>{

          let  rowNode;
    
          this.gridApi.forEachLeafNode((node: any) => {
            if (node.data.KpiId === this.KPIObject.kpiId) {
              // If it does, set the row node variable to the current node
              rowNode = node;
            }
          });
         
          rowNode.data.ActualValue=res[0].ActualValue;
          this.gridApi.redrawRows({ rowNodes: [rowNode] });


        });
   





        /////////////////
        const getActualEntry = {
          visionId:  this.KPIObject.visionId,
          kpiId: this.KPIObject.kpiId,
          frequency: this.Frequency,
        };
        this.kpiservice.getActualEntries(getActualEntry).subscribe(
          (response: any) => {
            this.dataArraykpi = response;
           
            if ( this.KPIObject.Frequency === 'Yearly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'Day', 'Shift'],
                false
              );
            } else if ( this.KPIObject.Frequency === 'Quarterly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Month', 'Week', 'Day', 'Shift'],
                false
              );
              this.gridColumnApiEdit.setColumnsVisible(['Quarter', 'AEId'], true);
            } else if ( this.KPIObject.Frequency === 'Monthly' || this.KPIObject.Frequency === 'BiMonthly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Week', 'Day', 'Shift'],
                false
              );
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'AEId'],
                true
              );
            } else if ( this.KPIObject.Frequency === 'Weekly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(['Day', 'Shift'], false);
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'AEId'],
                true
              );
            } else if ( this.KPIObject.Frequency === 'Daily') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(['Shift'], false);
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'Day', 'AEId'],
                true
              );
            } else if ( this.KPIObject.Frequency === 'Shiftly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'Day', 'Shift', 'AEId'],
                true
              );
            }
          },
          (error: any) => {
            this.toaster.error('Error', error.message);
          }
        );


      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );


  }


  UpdateActualEntry(){
    

    const dateObject = new Date(this.manualActualEntryForm.value.date); // getSqlDate(new date);
    // dateObject.setHours(new Date().getHours());
    // dateObject.setMinutes(new Date().getMinutes());
    // dateObject.setSeconds(new Date().getSeconds());
 
  
    
    // const dateString = this.EditObj.ActualEntryDate;
    // const dateParts = dateString.split('/');
    // const dateObject = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
    const data = {
      kpiId: this.EditObj.KpiId,
      YearDate:  moment(dateObject).utc(),
      ActualValue: this.manualActualEntryForm.value.value,
      AEId:this.EditObj.AEId,
      createdBy: 1,
    };
data

    this.kpiactualentry.addActualEntry(data).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Data Updated Successfully');
        this.resetEntry();
        this.IsEdit=false;


        
        const date = this.dateForm.value.date; // .format('YYYY-MM-DD');
         const startOfDay = date.setHours(0, 0, 0, 0);
        const endOfDay = date.setHours(23, 59, 59, 0);
   
        
        const KpiKey = {
          // date: moment(value).format('YYYY-MM-DD'),
          fromDate: moment(startOfDay).utc(),
          toDate: moment(endOfDay).utc(),
          plantId: this.searchPlantForm.value.plant.DeptId,
          userId: JSON.parse(localStorage.user).UserId,
          KpiCode : this.EditObj.KPICode
        };


        this.kpiactualentry.GetKPIActualEntryForKpi(KpiKey).subscribe((res)=>{

          let  rowNode;
    
          this.gridApi.forEachLeafNode((node: any) => {
            if (node.data.KpiId === this.KPIObject.kpiId) {
              // If it does, set the row node variable to the current node
              rowNode = node;
            }
          });
         
          rowNode.data.ActualValue=res[0].ActualValue;
          this.gridApi.redrawRows({ rowNodes: [rowNode] });


        });












        /////////////////
        const getActualEntry = {
          visionId:  this.visionId,
          kpiId: this.KPIID,
          frequency: this.Frequency
        };
        this.kpiservice.getActualEntries(getActualEntry).subscribe(
          (response: any) => {
            this.dataArraykpi = response;
           
            if ( this.KPIObject.Frequency === 'Yearly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'Day', 'Shift'],
                false
              );
            } else if ( this.KPIObject.Frequency === 'Quarterly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Month', 'Week', 'Day', 'Shift'],
                false
              );
              this.gridColumnApiEdit.setColumnsVisible(['Quarter', 'AEId'], true);
            } else if ( this.KPIObject.Frequency === 'Monthly' || this.KPIObject.Frequency === 'BiMonthly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Week', 'Day', 'Shift'],
                false
              );
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'AEId'],
                true
              );
            } else if ( this.KPIObject.Frequency === 'Weekly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(['Day', 'Shift'], false);
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'AEId'],
                true
              );
            } else if ( this.KPIObject.Frequency === 'Daily') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(['Shift'], false);
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'Day', 'AEId'],
                true
              );
            } else if ( this.KPIObject.Frequency === 'Shiftly') {
              this.visibleShiftlyTarget = true;
              this.gridColumnApiEdit.setColumnsVisible(
                ['Quarter', 'Month', 'Week', 'Day', 'Shift', 'AEId'],
                true
              );
            }
          },
          (error: any) => {
            this.toaster.error('Error', error.message);
          }
        );


      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );


  }

  resetEntry(){
    
    this.manualActualEntryForm.reset();
    this.IsEdit=false;

  }

  onActualValueChange(e: any) {
    // console.log(e);

    let regex = /^[-+]?\d+(\.\d+)?$/;
 

 
    let value = (e.target as HTMLInputElement).value;
    // console.log(value);
    value = value.trim();

    
    if (!regex.test(value)) {
      // console.log('match');
      this.toaster.error('Invalid Value');
      // (e.target as HTMLInputElement).value = value.match('/^d*.?d+$/gm') as any;
    }
  }
}

function getSqlDate(value: any): any {
  return (
    value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
  );
}
function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}
