import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductDetail } from 'src/app/models/ProductModels/productDetail';
import { PRODUCTS } from 'src/assets/data/dummydata';
import { ProductdetailComponent } from '../productdetail/productdetail.component';
import { ProductpropertyComponent } from '../productproperty/productproperty.component';
import { ProductspecsComponent } from '../productspecs/productspecs.component';

@Component({
  selector: 'app-productmaster',
  templateUrl: './productmaster.component.html',
  styleUrls: ['./productmaster.component.scss']
})
export class ProductmasterComponent implements OnInit {
  productdetail!: ProductDetail[];
  productproperty!: any;
  productspecs!: any;


  constructor() { }

  ngOnInit(): void {
    this.getMasterData();
  }
  // master data
  getMasterData(): void {
    this.productdetail = PRODUCTS;
  }

}
