import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { AtHeader } from 'src/app/models/analytical-toolsModels/atHeader';
import { FivewhyService } from 'src/app/services/fivewhy.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-five-why',
  templateUrl: './five-why.component.html',
  styleUrls: ['./five-why.component.scss'],
})
export class FiveWhyComponent implements OnInit {
  @Input() isAtNcr = false;
  @Input() onSubmit: Observable<any>;
  @Input() fiveWhy_: EventEmitter<any> = new EventEmitter();
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Input() _headerData: EventEmitter<any> = new EventEmitter();
  @Output() _emitHeaderData: EventEmitter<any> = new EventEmitter();

  @Input() headerID: any;

  fiveWhy: any = [];
  stageName: string = '5 Why';
  stageId: number = 4;
  Weigths = [
    { value: 2, checked: true },
    { value: 5, checked: false },
    { value: 8, checked: false },
  ];

  breadcrumList!: any;
  spinnerText: string = '';
  headerData: AtHeader = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageId: 4,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: '',
  };
  rcaId: number = 0;
  user: any;
  updateStatus: boolean = false;

  columns: any;

  fiveWhyDataSource = new MatTableDataSource();
  fiveWhyTest: any = [];
  levelId: number = 0;
  ReadyFor5YAP: boolean = false;
  isReset: boolean = false;
  @Input() _permission!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  sub: Subscription;
  result: any;

  constructor(
    private analyticalToolsService: AnalyticalToolsService,
    private FivewhyService: FivewhyService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.IsWrite = this._permission.IsWrite || false;
    this.headerData.ID = this.headerID;
    this.rcaId = this.analyticalToolsService.getRcaId();
    if (this.rcaId > 0) {
      this.columns = [
        'Contributing Source',
        'Priority',
        'Valid Cause',
        'Weight',
        'Why1',
        'Why2',
        'Why3',
        'Why4',
        'Why5',
        'RootCause',
        "M's Analysis",
        "SubY's",
      ];
    } else {
      this.columns = [
        'Valid Cause',
        'Why1',
        'Why2',
        'Why3',
        'Why4',
        'Why5',
        'RootCause',
        "M's Analysis",
        "SubY's",
      ];
    }
    this.getFiveWhy('');
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
    const userGroup = this.analyticalToolsService.getUserGroup();
    if (userGroup) this.headerData.Group = userGroup;

    this.sub = this.fiveWhy_.subscribe((event: any) => {
      this.saveFiveWhy(event);
    });
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  addRow(index: number, type: string) {
    let i = 0;
    for (const fy of this.fiveWhy) {
      fy.index = i;
      i++;
    }
    let obj: any = Object.assign({}, this.fiveWhy[index]);
    obj.ValidationEditFalse = false;
    obj.ID = 0;
    obj.delete = true;
    obj.TypeOfM = '';
    delete obj.ActionDisabled;
    if (type == 'sub') {
      obj.sub = true;
      if (obj.Why5) obj.parentCheck = 5;
      else if (obj.Why4) obj.parentCheck = 4;
      else if (obj.Why3) obj.parentCheck = 3;
      else if (obj.Why2) obj.parentCheck = 2;
      else if (obj.Why1) obj.parentCheck = 1;

      if (
        obj.ValidationFromCauseEffects &&
        (obj.Why1 || obj.Why2 || obj.Why3 || obj.Why4 || obj.Why5)
      ) {
      } else {
        this.toaster.warning(
          'Warning',
          `This Row Was Not Valid To Create Subrow`
        );
        return;
      }
      obj.RootCause = '';
    } else {
      obj.main = true;
      obj.ValidationFromCauseEffects = '';
      obj.RootCause = '';
    }

    let arr: any;
    obj.check1 = obj.check2 = obj.check3 = obj.check4 = obj.check5 = false;
    obj.Why1 = obj.Why2 = obj.Why3 = obj.Why4 = obj.Why5 = '';
    if (type == 'sub') {
      obj.sub = true;
      obj.MainRowShow = false;
    } else {
      obj.sub = false;
      obj.MainRowShow = true;
    }
    let spliceIndex;
    // if (arr && arr.length)
    //   spliceIndex = arr[arr.length - 1].index + 1;
    // else
    spliceIndex = obj.index + 1;
    if (type != 'sub') {
      // delete obj.spanRow;
      obj.Priority = this.fiveWhy[index].Priority + 1;
    }
    delete obj.readyForAP;
    this.fiveWhy.splice(spliceIndex, 0, obj);
    // if (type == 'sub')
    this.setSort();
    this.setIndex();

    for (let fy of this.fiveWhy) {
      // if (fy.Priority % 1 === 0) {
      const mRecords = this.fiveWhy.filter(
        (fiveY: any) => fy.MType == fiveY.MType && fy.Priority == fiveY.Priority 
          && fy.ValidationFromCauseEffects == fiveY.ValidationFromCauseEffects
      );
      if (mRecords && mRecords.length) {
        let disabled = mRecords.map((rec: any, index: number) => {
          if (index == 0)
            rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
          else rec.spanRow = true;
        });
      }
    }

    let a = this.fiveWhy.filter((obj: any) => !obj.spanRow);
    if (a && a.length) {
      for (let index = 0; index < a.length; index++) {
        if (index == a.length - 1) a[index].MainRowShow = true;
        else a[index].MainRowShow = false;
      }
    }

    this.fiveWhyDataSource = new MatTableDataSource(this.fiveWhy);
  }

  deleteRow(index: number) {
    let i = 0;
    for (const fy of this.fiveWhy) {
      fy.index = i;
      i++;
    }
    const isHaveSub = this.fiveWhy.filter(
      (cause: any) => cause.Priority == this.fiveWhy[index].Priority
    );
    if (isHaveSub && isHaveSub.length > 1 && !this.fiveWhy[index].sub) {
      this.toaster.warning(
        'Warning',
        `Remove sub values for ${this.fiveWhy[index].Priority}`
      );
      return;
    }
    // }
    this.fiveWhy.splice(index, 1);
    let previousM = '';
    let subPriority = 0.1;
    let Priority = 1;

    this.setSort();
    this.setIndex();
    let prevMType: any = '';
    let prevPriority: any = '';

    for (let fy of this.fiveWhy) {
      const mRecords = this.fiveWhy.filter(
        (fiveY: any) => fy.MType == fiveY.MType && fy.Priority == fiveY.Priority
          && fy.ValidationFromCauseEffects == fiveY.ValidationFromCauseEffects
      );
      if (mRecords && mRecords.length) {
        let disabled = mRecords.map((rec: any, index: number) => {
          if (index == 0)
            rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
          else rec.spanRow = true;
        });
      }

      let a = this.fiveWhy.filter((obj: any) => !obj.spanRow);
      if (a && a.length) {
        for (let index = 0; index < a.length; index++) {
          if (index == a.length - 1) a[index].MainRowShow = true;
          else a[index].MainRowShow = false;
        }
      }
    }
    this.fiveWhyDataSource = new MatTableDataSource(this.fiveWhy);
  }

  setIndex() {
    this.fiveWhy.map((cause: any, index: number) => {
      cause.index = index;
      // cause.Priority = Math.floor(cause.Priority);
    });
  }

  setPriority() {
    let prevMType: any = '';
    let prevPriority: any = '';
    let priority = 1,
      subPriority = 0.1,
      parentPriorityAdded = false;

    for (let fy of this.fiveWhy) {
      if (prevMType != fy.MType) {
        subPriority = 0.1;
        priority = 1;
        fy.Priority = priority;
      } else if (prevPriority != Math.floor(fy.Priority)) {
        subPriority = 0.1;
        priority += 1;
        fy.Priority = priority;
      } else {
        parentPriorityAdded = false;
        fy.Priority = priority + subPriority;
        subPriority += 0.1;
      }
      prevMType = fy.MType;
      prevPriority = Math.floor(fy.Priority);
    }
  }

  valChange(i: any, val: any, position: number) {
    let why1 = 'Why1';
    let why2 = 'Why2';
    let why3 = 'Why3';
    let why4 = 'Why4';
    let why5 = 'Why5';
    let check1 = 'check1';
    let check2 = 'check2';
    let check3 = 'check3';
    let check4 = 'check4';
    let check5 = 'check5';
    let curr_why = 'Why' + position;
    let curr_check = 'check' + position;
    let prev_pos = position - 1;
    let prev_why = 'Why' + prev_pos;
    if (this.fiveWhy[i][why1]) {
      if (
        !this.fiveWhy[i][why2] &&
        (this.fiveWhy[i][why3] ||
          this.fiveWhy[i][why4] ||
          this.fiveWhy[i][why5])
      ) {
        this.fiveWhy[i][why3] = '';
        this.fiveWhy[i][why4] = '';
        this.fiveWhy[i][why5] = '';
        this.fiveWhy[i][check3] = '';
        this.fiveWhy[i][check4] = '';
        this.fiveWhy[i][check5] = '';
        this.toaster.warning(
          'Warning',
          `Please enter previous why first in Priority ${this.fiveWhy[i].Priority}`
        );
      } else if (
        this.fiveWhy[i][why2] &&
        !this.fiveWhy[i][why3] &&
        (this.fiveWhy[i][why4] || this.fiveWhy[i][why5])
      ) {
        this.fiveWhy[i][why4] = '';
        this.fiveWhy[i][why5] = '';
        this.fiveWhy[i][check4] = '';
        this.fiveWhy[i][check5] = '';
        this.toaster.warning(
          'Warning',
          `Please enter previous why first in Priority ${this.fiveWhy[i].Priority}`
        );
        return;
      } else if (
        this.fiveWhy[i][why2] &&
        this.fiveWhy[i][why3] &&
        !this.fiveWhy[i][why4] &&
        this.fiveWhy[i][why5]
      ) {
        this.fiveWhy[i][why5] = '';
        this.fiveWhy[i][check5] = '';
        this.toaster.warning(
          'Warning',
          `Please enter previous why first in Priority ${this.fiveWhy[i].Priority}`
        );
        return;
      }
    } else if (this.fiveWhy[i].parentCheck && this.fiveWhy[i].parentCheck > 1) {
      if (this.fiveWhy[i][why2]) {
        if (
          !this.fiveWhy[i][why3] &&
          (this.fiveWhy[i][why4] || this.fiveWhy[i][why5])
        ) {
          this.fiveWhy[i][why4] = '';
          this.fiveWhy[i][why5] = '';
          this.fiveWhy[i][check4] = '';
          this.fiveWhy[i][check5] = '';
          this.toaster.warning(
            'Warning',
            `Please enter previous why first in Priority ${this.fiveWhy[i].Priority}`
          );
          return;
        } else if (
          this.fiveWhy[i][why3] &&
          !this.fiveWhy[i][why4] &&
          this.fiveWhy[i][why5]
        ) {
          this.fiveWhy[i][why5] = '';
          this.fiveWhy[i][check5] = '';
          this.toaster.warning(
            'Warning',
            `Please enter previous why first in Priority ${this.fiveWhy[i].Priority}`
          );
          return;
        }
      } else if (
        this.fiveWhy[i].parentCheck &&
        this.fiveWhy[i].parentCheck > 2
      ) {
        if (this.fiveWhy[i][why3]) {
          if (!this.fiveWhy[i][why4] && this.fiveWhy[i][why5]) {
            this.fiveWhy[i][why5] = '';
            this.fiveWhy[i][check5] = '';
            this.toaster.warning(
              'Warning',
              `Please enter previous why first in Priority ${this.fiveWhy[i].Priority}`
            );
            return;
          }
        } else if (
          this.fiveWhy[i].parentCheck &&
          this.fiveWhy[i].parentCheck > 3
        ) {
          if (this.fiveWhy[i][why4]) {
          } else if (
            this.fiveWhy[i].parentCheck &&
            this.fiveWhy[i].parentCheck > 4
          ) {
            if (!this.fiveWhy[i][why5]) {
              this.fiveWhy[i][why1] = '';
              this.fiveWhy[i][why2] = '';
              this.fiveWhy[i][why3] = '';
              this.fiveWhy[i][why4] = '';
              this.fiveWhy[i][why5] = '';
              this.fiveWhy[i][check1] = '';
              this.fiveWhy[i][check2] = '';
              this.fiveWhy[i][check3] = '';
              this.fiveWhy[i][check4] = '';
              this.fiveWhy[i][check5] = '';
              this.fiveWhy[i].RootCause = '';
              this.toaster.warning(
                'Warning',
                `Please enter why 5 in Priority ${this.fiveWhy[i].Priority}`
              );
              return;
            }
          } else {
            this.fiveWhy[i][why1] = '';
            this.fiveWhy[i][why2] = '';
            this.fiveWhy[i][why3] = '';
            this.fiveWhy[i][why4] = '';
            this.fiveWhy[i][why5] = '';
            this.fiveWhy[i][check1] = '';
            this.fiveWhy[i][check2] = '';
            this.fiveWhy[i][check3] = '';
            this.fiveWhy[i][check4] = '';
            this.fiveWhy[i][check5] = '';
            this.fiveWhy[i].RootCause = '';
            this.toaster.warning(
              'Warning',
              `Please enter why 4 in Priority ${this.fiveWhy[i].Priority}`
            );
            return;
          }
        } else {
          this.fiveWhy[i][why1] = '';
          this.fiveWhy[i][why2] = '';
          this.fiveWhy[i][why3] = '';
          this.fiveWhy[i][why4] = '';
          this.fiveWhy[i][why5] = '';
          this.fiveWhy[i][check1] = '';
          this.fiveWhy[i][check2] = '';
          this.fiveWhy[i][check3] = '';
          this.fiveWhy[i][check4] = '';
          this.fiveWhy[i][check5] = '';
          this.fiveWhy[i].RootCause = '';
          this.toaster.warning(
            'Warning',
            `Please enter why 3 in Priority ${this.fiveWhy[i].Priority}`
          );
          return;
        }
      } else {
        this.fiveWhy[i][why1] = '';
        this.fiveWhy[i][why2] = '';
        this.fiveWhy[i][why3] = '';
        this.fiveWhy[i][why4] = '';
        this.fiveWhy[i][why5] = '';
        this.fiveWhy[i][check1] = '';
        this.fiveWhy[i][check2] = '';
        this.fiveWhy[i][check3] = '';
        this.fiveWhy[i][check4] = '';
        this.fiveWhy[i][check5] = '';
        this.fiveWhy[i].RootCause = '';
        this.toaster.warning(
          'Warning',
          `Please enter why 2 in Priority ${this.fiveWhy[i].Priority}`
        );
        return;
      }
    } else {
      this.fiveWhy[i][why1] = '';
      this.fiveWhy[i][why2] = '';
      this.fiveWhy[i][why3] = '';
      this.fiveWhy[i][why4] = '';
      this.fiveWhy[i][why5] = '';
      this.fiveWhy[i][check1] = '';
      this.fiveWhy[i][check2] = '';
      this.fiveWhy[i][check3] = '';
      this.fiveWhy[i][check4] = '';
      this.fiveWhy[i][check5] = '';
      this.fiveWhy[i].RootCause = '';
      this.toaster.warning(
        'Warning',
        `Please enter why 1 in Priority ${this.fiveWhy[i].Priority}`
      );
      return;
    }

    this.fiveWhy[i].RootCause = val;
    if (this.fiveWhy[i][curr_why] == '') {
      if (this.fiveWhy[i][prev_why]) {
        this.fiveWhy[i].RootCause = this.fiveWhy[i][prev_why];
      }
    }
  }
  whyCheck(event: any, i: any, position: number) {
    let curr_why = 'Why' + position;
    let prev_pos = position + 1;
    let next_why = 'Why' + prev_pos;
    let curr_check = 'check' + position;

    // if(this.fiveWhy[i][next_why] && next_why != 'Why6'){
    if (!event.checked) {
      if (curr_why == 'Why5') {
        this.fiveWhy[i].RootCause = '';
        this.fiveWhy[i].TypeOfM = '';
      } else {
        if (this.fiveWhy[i] && this.fiveWhy[i][next_why]) {
          setTimeout(() => {
            this.fiveWhy[i][curr_check] = true;
            this.toaster.warning('Warning', `You can't erase this data`);
            return;
          }, 250);
        } else {
          this.fiveWhy[i].RootCause = '';
          this.fiveWhy[i].TypeOfM = '';
        }
      }
    } else {
      this.fiveWhy[i].RootCause = this.fiveWhy[i][curr_why];
    }
  }

  getFiveWhy(reset: any): any {
    this.spinnerText = 'Fetching Five Why Data';
    this.spinner.show();
    this.FivewhyService.getFiveWhyData(this.headerData.ID || 0).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.result = res.data;
        if (res.status) {
          this.updateStatus = res.updateStatus;
          if (res.data) {
            this.analyticalToolsService.setTeamLeaderList(res.data.Team);
            this.rcaId = res.data.rcaId ? res.data.rcaId : 0;
            this.levelId =
              res && res.data && res.data.stageId ? res.data.stageId : 0;
            if (
              (this.rcaId > 0) ||
              (this.levelId != 0 && this.levelId != 4 && this.levelId != 5)
            ) {
              this.columns = [
                'Contributing Source',
                'Priority',
                'Valid Cause',
                'Weight',
                'Why1',
                'Why2',
                'Why3',
                'Why4',
                'Why5',
                'RootCause',
                "M's Analysis",
                "SubY's",
              ];
            } else {
              this.columns = [
                'Valid Cause',
                'Why1',
                'Why2',
                'Why3',
                'Why4',
                'Why5',
                'RootCause',
                "M's Analysis",
                "SubY's",
              ];
            }
            delete res.data.rcaId;
            if (res.data.ID) {
              this.headerData = res.data;
            } else
              this.headerData = {
                ID: 0,
                Group: this.headerData.Group ? this.headerData.Group : '',
                Plant: this.headerData.Plant ? this.headerData.Plant : '',
                Dept: this.headerData.Dept ? this.headerData.Dept : '',
                Team: '',
                TeamLeader: '',
                InitProbStatement: '',
                NoOfM: 6,
                stageId: 4,
                uniqueCode: '',
                CreatedAt: new Date(),
                CreatedBy: this.user.UserId,
              };
          }
          // this.analyticalToolsService.setHeaderId(this.headerData.ID);
          this.analyticalToolsService.setRcaId(this.rcaId);
          this.analyticalToolsService.setLevelId(this.levelId);
          this.analyticalToolsService.setStatus(this.updateStatus);
          this.fiveWhy = res.data.fiveWhy;

          if (this.fiveWhy && this.fiveWhy.length == 1) {
            this.fiveWhy[0].MainRowShow = true;
          }

          if (this.fiveWhy && this.fiveWhy.length) {
            let arr = [],
              i = 0;
            for (let fy of this.fiveWhy) {
              if (fy.ValidationFromCauseEffects) fy.causeNotEmpty = true;
              else fy.causeNotEmpty = false;
              fy.check1 = fy.Why1 ? true : false;
              fy.check2 = fy.Why2 ? true : false;
              fy.check3 = fy.Why3 ? true : false;
              fy.check4 = fy.Why4 ? true : false;
              fy.check5 = fy.Why5 ? true : false;

              if (fy.check1) fy.parentCheck = 1;
              else if (fy.check2) fy.parentCheck = 2;
              else if (fy.check3) fy.parentCheck = 3;
              else if (fy.check4) fy.parentCheck = 4;
              else if (fy.check5) fy.parentCheck = 5;

              fy.readyForAP = fy && fy.ID && fy.RootCause ? true : false;

              if (!fy.RootCause) {
                if (fy.Why5) fy.check5 = false;
                else if (fy.Why4) fy.check4 = false;
                else if (fy.Why3) fy.check3 = false;
                else if (fy.Why2) fy.check2 = false;
                else if (fy.Why1) fy.check1 = false;
              }

              if (fy.fiveWhyActionPlan && fy.fiveWhyActionPlan.length) {
                let checkAction = fy.fiveWhyActionPlan.some(
                  (el: any) => el.Action
                );
                fy.ActionDisabled = checkAction ? true : false;
              }

              const mRecords = this.fiveWhy.filter(
                (fiveY: any) =>
                  fy.MType == fiveY.MType && fy.Priority == fiveY.Priority
                    && fy.ValidationFromCauseEffects == fiveY.ValidationFromCauseEffects
              );
              if (mRecords && mRecords.length) {
                let disabled = mRecords.map((rec: any, index: number) => {
                  if (index == 0)
                    rec.rowSpan =
                      mRecords && mRecords.length ? mRecords.length : 1;
                  else {
                    rec.spanRow = true;
                    rec.sub = true;
                  }
                });
              }
              i++;
            }
          }
          let a = this.fiveWhy.filter((obj: any) => !obj.spanRow);
          if (a && a.length) {
            for (let index = 0; index < a.length; index++) {
              if (index == a.length - 1) a[index].MainRowShow = true;
              else a[index].MainRowShow = false;
            }
          }
          this.setIndex();
          this.setSort();

          if (reset == 'reset' && !this.headerData.ID) this.resetData();
          this.fiveWhyDataSource = new MatTableDataSource(this.fiveWhy);
        } else this.toaster.error('Error', res.message);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  setSort() {
    this.fiveWhy.sort(function (a: any, b: any) {
      return a.Priority - b.Priority;
    });
    this.fiveWhy.sort(function (a: any, b: any) {
      return a.M - b.M;
    });
  }

  resetData() {
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 4,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId,
    };
    this.isReset = true;
    this.analyticalToolsService.setRca(false);
    this.analyticalToolsService.setRcaId(0);
    this.analyticalToolsService.setHeaderId(0);
    this.analyticalToolsService.setFilteredIndex(0);
    this.analyticalToolsService.setFilteredData([]);
    this.analyticalToolsService.setTeamLeaderList([]);
    this.getFiveWhy('');
  }

  saveFiveWhy(e: any) {
    let event = e.headerData;
    let integrationData = e.integrationData;
    delete event.CauseFileCount;
    delete event?.stageID;
    delete event?.FyActionFileCount;

    let statusCheck = false;

    let checkFiveWhy = [...this.fiveWhy];

    // checkFiveWhy = checkFiveWhy.filter((checkfy: any) => (checkfy.Why1 || checkfy.Why2 || checkfy.Why3 || checkfy.Why4 || checkfy.Why5) && checkfy.sub);

    if (
      (this.rcaId > 0) ||
      (this.levelId != 0 && this.levelId != 4 && this.levelId != 5)
    ) {
      this.fiveWhy = this.fiveWhy.filter(
        (fy: any) => fy.Why1 || fy.Why2 || fy.Why3 || fy.Why4 || fy.Why5
      );
    } else if (this.levelId == 0 || this.levelId == 4) {
      this.fiveWhy = this.fiveWhy.filter(
        (fy: any) =>
          !fy.sub || fy.Why1 || fy.Why2 || fy.Why3 || fy.Why4 || fy.Why5
      );
    }

    for (const fiveWhy of checkFiveWhy) {
      // if (!fiveWhy.ValidationFromCauseEffects) {
      //   this.toaster.warning('Warning', `Enter Value for Cause on Priority ${fiveWhy.Priority}`);
      //   return;
      // }
      if (
        !fiveWhy.Why1 &&
        !fiveWhy.Why2 &&
        !fiveWhy.Why3 &&
        !fiveWhy.Why4 &&
        !fiveWhy.Why5 &&
        !fiveWhy.sub
      ) {
        statusCheck = true;
      }
    }

    this.spinnerText = this.updateStatus
      ? 'Updating Five Why Data'
      : 'Creating Five Why Data';
    this.spinner.show();
    // let FiveWhy = {
    //   header: this.headerData,
    //   data: this.fiveWhy
    // };

    if (event.StatusCauseEffect && event.StatusCauseEffect == 'InProgress') {
      const found = this.fiveWhy.some(
        (el: any) => el.RootCause || el.ValidationFromCauseEffects
      );
      if (!found) event.StatusFiveWhy = 'Open';
      else event.StatusFiveWhy = 'InProgress';
    } else {
      const found = this.fiveWhy.some(
        (el: any) => el.RootCause || el.ValidationFromCauseEffects
      );
      if (!found) event.StatusFiveWhy = 'Open';
      else if (statusCheck) event.StatusFiveWhy = 'InProgress';
      else if (!statusCheck) event.StatusFiveWhy = 'Completed';
    }

    let isRootCauseFilter = this.fiveWhy.filter((el: any) => el.RootCause);
    if (isRootCauseFilter && isRootCauseFilter.length) {
      const found = isRootCauseFilter.every((el: any) => el.TypeOfM);
      if (!found) {
        if (event.StatusFiveWhy == 'Open') event.StatusFiveWhy = 'Open';
        else if (event.StatusFiveWhy == 'InProgress')
          event.StatusFiveWhy = 'InProgress';
        else if (event.StatusFiveWhy == 'Completed')
          event.StatusFiveWhy = 'InProgress';
      }
    }

    for (const val of this.fiveWhy) {
      delete val.rowSpan;
      delete val.spanRow;
      delete val.sub;
      delete val.parentCheck;
      delete val.MainRowShow;
      delete val.readyForAP;
      delete val.causeNotEmpty;
      delete val.ActionDisabled;
      delete val.fiveWhyActionPlan;
    }

    delete event.uniqueCode;
    let res = this.result;
    event = {
      ...event,
      causeAndEffects: res.causeAndEffects,
      fiveWhy: res.fiveWhy,
    };
    // event.StatusFiveWhy = event.StatusCauseEffect && event.StatusCauseEffect == 'InProgress' ? 'InProgress' : statusCheck ? 'InProgress' : 'Completed';
    event.stageId = this.levelId ? this.levelId : this.stageId;
    const formData = new FormData();
    formData.append('UploadFile', event.UploadFile);
    formData.append('header', JSON.stringify(event));
    formData.append('data', JSON.stringify(this.fiveWhy));
    let route = this.router.url;
    if (!route.includes('at/at-stages')) {
      formData.append('integrationData', JSON.stringify(integrationData));
    }

    if (this.updateStatus) {
      this.FivewhyService.updateFiveWhyData(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.analyticalToolsService.setRcaId(res.data.rcaId);
          this.fiveWhy = res.data.data;
          this.analyticalToolsService.setHeaderId(res.data.headerId);
          this.onSuccess.emit('fiveWhy');

          this.analyticalToolsService.setStatus(this.updateStatus);
          this.headerData.ID = this.headerID;
          this.getFiveWhy('');
          this._emitHeaderData.emit(res.data);

          this.toaster.success('Success', res.message);
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    } else {
      this.FivewhyService.addFiveWhyData(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.analyticalToolsService.setRcaId(res.data.rcaId);
          this.fiveWhy = res.data.data;
          this.analyticalToolsService.setHeaderId(res.data.headerId);
          this.onSuccess.emit('fiveWhy');

          this.updateStatus = true;
          this.analyticalToolsService.setStatus(this.updateStatus);
          this.headerData.ID = res.data.headerId;
          this.getFiveWhy('');
          this._emitHeaderData.emit(res.data);

          this.toaster.success('Success', res.message);
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
  }

  addActionPlan(id: any, event: any) {
    // if (!event.StatusFiveWhy || event.StatusFiveWhy == 'InProgress')
    //   this.toaster.warning('Warning', 'Please Complete This Stage');
    // else
    this.router.navigate(['/at/fivewhy-actpln'], {
      queryParams: { headerId: this.headerData.ID, fivewhyId: id },
    });
  }

  getShortData(data: any) {
    let strLength =
      (this.rcaId > 0) ||
      (this.levelId != 0 && this.levelId != 4 && this.levelId != 5)
        ? 25
        : 40;
    if (data.length > strLength) {
      let text = data.substring(0, strLength); //cuts to 1024
      let last = text.lastIndexOf(' '); //gets last space (to avoid cutting the middle of a word)
      text = text.substring(0, strLength); //cuts from last space (to avoid cutting the middle of a word)
      text = text + ` ...`; //adds (...) at the end to show that it's cut
      return text;
    } else return data;
  }

  setSearchData(event: any) {
    if (event && event.fiveWhy && event.fiveWhy.length) {
      this.headerData = event;

      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
      // this.toaster.info('Info', '5Why Data Not Found For This Header ID');
      // }

      // this.getFiveWhy('');
    } else {
      this.headerData = event;
      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          this.getFiveWhy('');
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
