import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalStandardService {

  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGlobalStandard(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GlobalStandards/GetMasterGlobalStandards', data);
  }

  addGlobalStandard(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'GlobalStandards/AddGlobalStandard', data);
  }
  updateGlobalStandard(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'GlobalStandards/UpdateGlobalStandard', data);
  }
  deleteGlobalStandard(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GlobalStandards/DeleteGlobalStandard', data);
  }
  downloadImage(data: any): Observable<any>{
    return this.http.post<any>(this.o3ApiUrl + 'GlobalStandards/downloadImage', data);
  }
}
