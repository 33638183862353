import { Component, OnInit, Inject, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-preventive-dialog',
  templateUrl: './preventive-dialog.component.html',
  styleUrls: ['./preventive-dialog.component.scss']
})
export class PreventiveDialogComponent implements OnInit {

  @ViewChild('fileInput') fileInput!: ElementRef;
  file: any = {
    Technology: [],
    TaskType: [],
    Tool: [],
    Frequency: [],
    Task: [],
    WOType: [],
    FailureMode: [],
    Priority: [],
    DelayTime: [],
    Technician: []
  };

  constructor(public dialogRef: MatDialogRef<PreventiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public toaster: ToastrService,) { }

  ngOnInit(): void { }

  openFile() {
    this.fileInput.nativeElement.click()
  }

  readExcel(event: any, name: any) {
    this.file = event.target.files[0];
    const fileName = this.file.name;
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      console.log(workbook);
      for (var i = 0; i < workbook.SheetNames.length; i++) {
        var sheet_name = workbook.SheetNames[i];
        var worksheet = workbook.Sheets[sheet_name];
        if (sheet_name == "Activity Type")
          this.file.Technology = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "Tool")
          this.file.Tool = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "Frequency")
          this.file.Frequency = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "Task Type")
          this.file.TaskType = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "Task")
          this.file.Task = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "WOType")
          this.file.WOType = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "FailureMode")
          this.file.FailureMode = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "Priority")
          this.file.Priority = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "DelayTime")
          this.file.DelayTime = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        else if (sheet_name == "Technician")
          this.file.Technician = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      }
      this.dialogRef.close(this.file);
    }
  }


  onCancelClick(): void {
    this.dialogRef.close();
  }

}
