import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZerovalueService {
  O3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  addzerovalue(data: any): any {
    //return this.http.put<any>(this.baseUrl + 'updateZeroVision', data);
    return this.http.put<any>(this.O3CoreApiUrl + 'KPI/updateZeroValues', data);
     
  }
  getzerovalue(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'getZeroVision', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/getZeroValues', data);
  }

}
