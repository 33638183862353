<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<div>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
  </div>
  <mat-card-title class="mat-card-title Headingmain">Palletization</mat-card-title>
</div>

<mat-card>
  <div>
    <form [formGroup]="warehouseForm">
      <div style="display: flex;">
        <div style="width: 100%;">
          <div class="content-top-row ">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item" style="margin: 0;">
              <mat-label class="placeholder-title">Group</mat-label>
              <mat-select formControlName="groupList" panelClass="testClass" name="Group"
                (selectionChange)="GetPlants()">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupList"
                  (filteredReturn)="filteredGroupList = $event">
                </mat-select-filter>
                <!-- <mat-option value="ALL">ALL</mat-option> -->
                <mat-option *ngFor=" let group of filteredGroupList" [value]="group.TypeId">
                  {{ group?.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Plant</mat-label>
              <mat-select formControlName="plantList" panelClass="testClass" name="Group"
                (selectionChange)="GetItemInfo()">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plantList"
                  (filteredReturn)="filteredPlantList = $event">
                </mat-select-filter>
                <!-- <mat-option value="ALL">ALL</mat-option> -->
                <mat-option *ngFor="let plant of filteredPlantList" [value]="plant.DeptId">
                  {{ plant?.DeptDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Item</mat-label>
              <mat-select formControlName="item" panelClass="testClass" name="Group"
                (selectionChange)="ItemChange($event,'itemCode')">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'ItemCode'" [array]="itemInfoList"
                  (filteredReturn)="filteredItemInfoList = $event">
                </mat-select-filter>
                <mat-option *ngFor="let item of filteredItemInfoList" [value]="item.ItemCode">
                  {{ item?.ItemCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Item Description</mat-label>
              <mat-select formControlName="itemDesc" panelClass="testClass" name="Group"
                (selectionChange)="ItemChange($event,'itemDesc')">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'ItemDesc'" [array]="itemInfoList"
                  (filteredReturn)="filteredItemInfoList = $event">
                </mat-select-filter>
                <mat-option *ngFor="let itemDesc of filteredItemInfoList" [value]="itemDesc.ItemDesc">
                  {{ itemDesc?.ItemDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">ProcessOrder</mat-label>
              <mat-select formControlName="procOrder" panelClass="testClass" name="Group">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="processOrderList"
                  (filteredReturn)="filteredProcessOrderList = $event">
                </mat-select-filter>
                <!-- <mat-option value="ALL">ALL</mat-option> -->
                <mat-option *ngFor="let order of filteredProcessOrderList" [value]="order.TypeId">
                  {{ order?.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex;width: 50%;justify-content: space-between;align-items: baseline;">
          <div class="content-top-row ">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item" style="margin: 0;width: 100%;">
              <mat-label class="placeholder-title">Scan BatchNo</mat-label>
              <input matInput (keyup.enter)="scanBatchNo()" formControlName="scantBatchNo" name="scantBatchNo" />
            </mat-form-field>
          </div>
          <button type="button" mat-raised-button color="warn"
            style="background-color: #0f0fd1;font-size: 14px!important;margin-right: 7px;" (click)="MoveBatchInfoDataSource()">
            <span style="font-size: 12px;color: white;">Move All</span>
          </button>
        </div>
        <div style="margin-right: 10px;">
          <div class="content-top-row ">
            <div class="p-grid p-fluid" style="text-align: end;">
              <div style="margin-top: 15px;">
              </div>
              <div style="margin-left: 10px;margin-top: 15px;">
                <button type="button" mat-raised-button color="warn" style="background-color: #0f0fd1;font-size: 14px;"
                  (click)="clearBatchInfoDataData()"><span style="font-size: 12px;color: white;">Clear
                    All</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>

  <mat-card-content>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-6">
        <h3 class="mat-card-title Headingmain">Batch Info</h3>
        <mat-card>
          <div class="p-grid p-fluid">
            <div class="p-col-1 p-md-6 p-lg-6">
            </div>
            <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
              <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end; ">
                <mat-label>Search</mat-label>
                <input [readonly]="false" matInput class="input-text" autocomplete="off"
                  (keyup)="applyFilterForqcBoxDataSource($event)" />
              </mat-form-field>
            </div>
          </div>
          <div class="content-row-flex-both-end m0 p0">
            <table mat-table [dataSource]="qcBoxDataSource" class="basic-table plant-model-table" matSort
              #sorter1="matSort">
              <ng-container matColumnDef="BatchInfoId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">BatchInfoId</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.BatchInfoId }} </td>
              </ng-container>
              <ng-container matColumnDef="ProcessOrder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">ProcessOrder</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ProcessOrder }} </td>
              </ng-container>
              <ng-container matColumnDef="PPD_Num">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">OperNo</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.PPD_Num }} </td>
              </ng-container>
              <ng-container matColumnDef="BatchNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">BatchNo</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.BatchNo }} </td>
              </ng-container>
              <ng-container matColumnDef="ItemCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Item Code</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ItemCode }} </td>
              </ng-container>
              <ng-container matColumnDef="ItemDesc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Item Desc</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ItemDesc }} </td>
              </ng-container>
              <ng-container matColumnDef="Qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Qty</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.Qty }} </td>
              </ng-container>
              <ng-container matColumnDef="ResultOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">ResultOn</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ResultOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
              </ng-container>
              <ng-container matColumnDef="Remarks">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Remarks</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.Remarks }} </td>
              </ng-container>
              <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedOn</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
              </ng-container>
              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedBy</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedBy }} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="qcBoxColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: qcBoxColumns;"
                [ngClass]="{highlighted: selectedRowIndex === row.BatchInfoId}" (click)="updatehighlight(row)"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" style="text-align: center;" colspan="7">No Data Found</td>
              </tr>
            </table>
          </div>
          <div>
            <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>

          </div>
        </mat-card>
      </div>
      <div class="p-col-12 p-md-6 p-lg-6">
        <h2 class="mat-card-title Headingmain">Selected Batch Info</h2>
        <mat-card>
          <div class="p-grid p-fluid">
            <div class="p-col-1 p-md-6 p-lg-6">
            </div>
            <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
              <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end; ">
                <mat-label>Search</mat-label>
                <input [readonly]="false" matInput class="input-text" autocomplete="off"
                  (keyup)="applyFilterForSelectedBatchInfo($event)" />
              </mat-form-field>
            </div>
          </div>
          <div class="content-row-flex-both-end m0 p0">
            <table mat-table [dataSource]="batchInfoDataSource" class="basic-table plant-model-table" matSort
              #sorter2="matSort">
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                  <p class="header-text">Action</p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="row-action-btns">
                    <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" pTooltip="Delete"
                      tooltipPosition="top" (click)="deleteBatchInfoDataData(element)"
                      style="margin-left:-5px;border-radius: 50%;">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                          fill="#F85439" />
                        <circle cx="17" cy="17" r="14" fill="#F85439" />
                        <path
                          d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                          fill="white" />
                      </svg>
                    </button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="BatchInfoId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">BatchInfoId</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.BatchInfoId }} </td>
              </ng-container>
              <ng-container matColumnDef="ProcessOrder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">ProcessOrder</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ProcessOrder }} </td>
              </ng-container>
              <ng-container matColumnDef="PPD_Num">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">OperNo</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.PPD_Num }} </td>
              </ng-container>
              <ng-container matColumnDef="BatchNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">BatchNo</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.BatchNo }} </td>
              </ng-container>
              <ng-container matColumnDef="ItemCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Item Code</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ItemCode }} </td>
              </ng-container>
              <ng-container matColumnDef="ItemDesc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Item Desc</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ItemDesc }} </td>
              </ng-container>
              <ng-container matColumnDef="Qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Qty</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.Qty }} </td>
              </ng-container>
              <ng-container matColumnDef="ResultOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">ResultOn</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.ResultOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
              </ng-container>
              <ng-container matColumnDef="Remarks">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">Remarks</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.Remarks }} </td>
              </ng-container>
              <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedOn</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
              </ng-container>
              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedBy</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedBy }} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="batchInfoColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: batchInfoColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" style="text-align: center;" colspan="7">No Data Found</td>
              </tr>
            </table>
          </div>
          <div>
            <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>

          </div>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content>
    <div class="d-flex-btn">
      <div class="pallet-btn">
        <button mat-raised-button color="warn" style="background-color: #0f0fd1;"
          (click)="openPreviewPalletDialog()"><span style="font-size: 12px;color: white;">Preview Pallet</span>
        </button>
      </div>
      <div class="pallet-btn">
        <button mat-raised-button color="warn" style="background-color: #0f0fd1;" (click)="subGeneratePallet()"><span
            style="font-size: 12px;color: white;">Generate
            Pallet</span></button>
      </div>
      <div class="pallet-btn">
        <button mat-raised-button color="warn" style="background-color: #0f0fd1;"
          (click)="warehouseHistoryModal()"><span style="font-size: 12px;color: white;">Pallet
            History</span></button>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content>
    <div class="pal-tab" style="padding: 20px 0;">
      <div class="p-col-12 p-md-12 p-lg-12">
        <div class="p-grid p-fluid">
          <div class="p-col-1 p-md-6 p-lg-6">
          </div>
          <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
            <mat-form-field appearance="fill" class="field-fill"
              style="display: flex; justify-content: flex-end;margin-right: 10px; ">
              <mat-label>Search</mat-label>
              <input [readonly]="false" matInput class="input-text" autocomplete="off"
                (keyup)="applyFilterForPalletInfo($event)" />
            </mat-form-field>
          </div>
        </div>
        <div class="content-row-flex-both-end m0 p0">
          <table mat-table [dataSource]="palletInfoDataSource" class="basic-table plant-model-table" matSort>

            <ng-container matColumnDef="PalletId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">PalletId</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.PalletId}} </td>
            </ng-container>
            <ng-container matColumnDef="PalletLotCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">PalletLotCode</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.PalletLotCode}} </td>
            </ng-container>
            <ng-container matColumnDef="NoOfBox">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">NoOfBox</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.NoOfBox}} </td>
            </ng-container>
            <ng-container matColumnDef="NoOfItem">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">NoOfItem</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.NoOfItem}} </td>
            </ng-container>
            <ng-container matColumnDef="CreatedOn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">CreatedOn</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss'}} </td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">CreatedBy</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.CreatedBy}} </td>
            </ng-container>
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                <p class="header-text">Action</p>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="row-action-btns">
                  <button (click)="getPalletDataWithDialog('print',element)" mat-icon-button class="btn-print"
                    aria-label="View Row Tree" pTooltip="Print" tooltipPosition="top">
                    <i class="fa fa-print" aria-hidden="true"></i>
                  </button>
                  <button (click)="getPalletDataWithDialog('search',element)" mat-icon-button class="btn-search"
                    aria-label="View Row Tree" pTooltip="Search" tooltipPosition="top">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                  <button (click)="getPalletDataWithDialog('delete',element)" mat-icon-button class="btn-delete"
                    aria-label="View Row Tree" pTooltip="Delete" tooltipPosition="top">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="palletInfoColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: palletInfoColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" style="text-align: center;" colspan="8">No Data Found</td>
            </tr>
          </table>
        </div>
        <div>
          <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 10, 20]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Quality History Modal-->
<ng-template #displayQualityHistoryModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center" style="font-size: 10px;">
      <h1>Pallet History</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div class="p-col-12 p-md-12 p-lg-12">
    <div class="p-grid p-fluid">
      <div class="p-col-1 p-md-6 p-lg-6">
      </div>
      <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
        <!-- <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end;margin-right: 10px; ">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" autocomplete="off"
            (keyup)="applyFilterForPalletReceivedHistory($event)" />
        </mat-form-field> -->
      </div>
    </div>
    <div class="content-row-flex-both-end m0 p0">
      <ag-grid-angular class="ag-theme-alpine"  style="width: 100%;height: 67.2vh;" [columnDefs]="PalletHistoryColumns" [rowData]="PalletHistoryList" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="paginationPageSize"></ag-grid-angular>
      <!-- <table mat-table [dataSource]="qualityHistoryDataSource" class="basic-table plant-model-table" matSort
        #sorter3="matSort">
        <ng-container matColumnDef="PalletId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">PalletId</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.PalletId}} </td>
        </ng-container>
        <ng-container matColumnDef="PalletLotCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">PalletLotCode</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.PalletLotCode}} </td>
        </ng-container>
        <ng-container matColumnDef="NoOfBox">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">NoOfBox</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.NoOfBox}} </td>
        </ng-container>
        <ng-container matColumnDef="NoOfItem">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">NoOfItem</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.NoOfItem}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">CreatedOn</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.CreatedOn}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">CreatedBy</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.CreatedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Action</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="row-action-btns">
              <button (click)="getPalletDataWithDialog(element,'print')" mat-icon-button class="btn-print"
                aria-label="View Row Tree" pTooltip="Print" tooltipPosition="top">
                <i class="fa fa-print" aria-hidden="true"></i>
              </button>
              <button (click)="getPalletDataWithDialog(element,'search')" mat-icon-button class="btn-search"
                aria-label="View Row Tree" pTooltip="Search" tooltipPosition="top">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <button (click)="getPalletDataWithDialog(element,'delete')" mat-icon-button class="btn-delete"
                aria-label="View Row Tree" pTooltip="Delete" tooltipPosition="top">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="qualityHistoryColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: qualityHistoryColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" style="text-align: center;" colspan="8">No Data Found</td>
        </tr>
      </table> -->
    </div>
    <!-- <div>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div> -->
  </div>
</ng-template>

<!-- Batch Info -->
<ng-template #batchInfoPalletDialog>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center" style="font-size: 10px;">
      <h1>Batch Info</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div class="p-col-12 p-md-12 p-lg-12">
    <div class="p-grid p-fluid">
      <div class="p-col-1 p-md-6 p-lg-6">
      </div>
      <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
        <!-- <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end;margin-right: 10px; ">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" autocomplete="off"
            (keyup)="applyFilterForPalletBatchInfo($event)" />
        </mat-form-field> -->
      </div>
    </div>
    <div class="content-row-flex-both-end m0 p0">
      <ag-grid-angular class="ag-theme-alpine"  style="width: 100%;height: 50vh;" [columnDefs]="BatchInfoColumns" [rowData]="BatchInfoList" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="paginationPageSize"></ag-grid-angular>
      <!-- <table mat-table [dataSource]="batchInfoPalletDataSource" class="basic-table plant-model-table" matSort
        #sorter4="matSort">
        <ng-container matColumnDef="BatchInfoId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">BatchInfoId</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.BatchInfoId }} </td>
        </ng-container>
        <ng-container matColumnDef="ProcessOrder">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">ProcessOrder</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.ProcessOrder }} </td>
        </ng-container>
        <ng-container matColumnDef="PPD_Num">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">OperNo</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.PPD_Num }} </td>
        </ng-container>
        <ng-container matColumnDef="BatchNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">BatchNo</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.BatchNo }} </td>
        </ng-container>
        <ng-container matColumnDef="ItemCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Item Code</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.ItemCode }} </td>
        </ng-container>
        <ng-container matColumnDef="ItemDesc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Item Desc</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.ItemDesc }} </td>
        </ng-container>
        <ng-container matColumnDef="Qty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Qty</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.Qty }} </td>
        </ng-container>
        <ng-container matColumnDef="ResultOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">ResultOn</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.ResultOn }} </td>
        </ng-container>
        <ng-container matColumnDef="Remarks">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Remarks</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.Remarks }} </td>
        </ng-container>
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">CreatedOn</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">CreatedBy</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.CreatedBy }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="batchInfoPalletColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: batchInfoPalletColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" style="text-align: center;" colspan="8">No Data Found</td>
        </tr>
      </table> -->
    </div>
    <!-- <div>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div> -->
  </div>
</ng-template>

<!--previewPalletDialog Modal-->
<ng-template #previewPalletDialog style="padding: 0px;">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center" style="font-size: 10px;">
      <h1>Pallet Item Info</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div class="p-col-12 p-md-12 p-lg-12">
    <div class="p-grid p-fluid">
      <div class="p-col-1 p-md-6 p-lg-6">
      </div>
      <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
        <!-- <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end;margin-right: 10px; ">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" autocomplete="off"
            (keyup)="applyFilterForPalletPreview($event)" />
        </mat-form-field> -->
      </div>
    </div>
    <div class="content-row-flex-both-end m0 p0">
      <ag-grid-angular class="ag-theme-alpine"  style="width: 100%;height: 67.2vh;" [columnDefs]="PreviewPalletColumns" [rowData]="PreviewPalletList" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="paginationPageSize"></ag-grid-angular>
      <!-- <table mat-table [dataSource]="previewPalletDataSource" class="basic-table plant-model-table" matSort
        #sorter5="matSort">
        <ng-container matColumnDef="ItemCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Item Code</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.ItemCode}} </td>
        </ng-container>
        <ng-container matColumnDef="ItemDesc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">Item Desc</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.ItemDesc}} </td>
        </ng-container>
        <ng-container matColumnDef="NoOfBox">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">NoOfBox</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.NoOfBox}} </td>
        </ng-container>
        <ng-container matColumnDef="TotalQty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">TotalQty</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.TotalQty}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="previewPalletColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: previewPalletColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" style="text-align: center;" colspan="8">No Data Found</td>
        </tr>
      </table> -->
    </div>
    <!-- <div>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div> -->
  </div>
</ng-template>

<!-- <div class="container" id="componentID">
  <table data-show-print="true" id="table1" style="width:100%" class="table">
    <thead>
      <tr style="text-align: left;">
        <th colspan="16"><img [src]="'http://localhost:4200/assets'+LogoPath"></th>
      </tr>
    </thead>
    <tbody colspan="16">
      <tr>
        <td colspan="16" class="td-bold" style="text-align: center;font-size:180px;font-weight:bold;color:gray;">
          {{ItemCode}}</td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: center;font-size:45px;font-weight:bolder;color:gray;" class="td-parent">
          {{ItemDesc}}</td>
      </tr>
      <tr>
        <td colspan="16" class="td-bold" style="text-align: center;font-size:180px;font-weight:bold;color:gray;">
          {{ItemCode1}}</td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: center;font-size:45px;font-weight:bolder;color:gray;" class="td-parent">
          {{ItemDesc1}}</td>
      </tr>
      <tr style="text-align: center;font-size:25px;">
        <td class="td-bold" style="width:30%;">QTY-Pcs/Box</td>
        <td class="td-bold" style="width:30%;text-align: center;">{{Qty}} Pcs</td>
        <td style="text-align: top;" class="td-bold" colspan="2" rowspan="2">QC Stamp<br>
          <span><img style="text-align: center;" [src]="'http://localhost:4200/assets'+ApproveImage"></span>
        </td>
      </tr>
      <tr style="text-align: center;font-size:25px;">
        <td class="td-bold" style="width:30%;">Prod Date &amp; Shift</td>
        <td class="td-bold" style="width:30%;text-align: center;">{{ProdDate}}</td>
      </tr>
      <tr>
        <td class="td-bold" colspan="16" rowspan="2">
          <div style="text-align:center;font-size:25px;">
            <svg id="barcode"></svg>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div> -->