import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class prevTaskService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  addPrevTask(data: any): Observable<any[]> {
    return this.http.post<any>(this.baseUrl + 'add-pm-technician-task', data)
  }
  // *** Core Api Method *** //
  updatePrevTask(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTechnicianTask/UpdatePMTechnicianTask', data);
  }

  deletePrevTask(data: any): Observable<any[]> {
    return this.http.put<any>(this.baseUrl + 'delete-pm-technician-task', data)
  }

  getPrevTask(id: any): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + 'get-pm-technician-task', { params: { TaskId: id } })
  }
  getPMTechnicianTask(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTask/GetPMTechnicianTask', { params: data });
  }
  // *** Core Api Method *** //
  getTechAvailableTime(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTask/TechAvailableTime', { params: data });
  }
  resheduleTask(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTask/ReschedulePMTask', data)
  }

}