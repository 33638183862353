<div class="quality-db-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Quality DB</h1>
      <div class="basic-mat-input">
        <span class="date-label">Last 30 days</span>
        <mat-form-field appearence="fill">
          <!-- <mat-label>Choose date range</mat-label> -->
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Dropdown -->
  <form [formGroup]="dropDownForm">
    <div class="drop-down-group">
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Year</mat-label>
          <mat-select name="Year">
            <mat-option *ngFor="let Year of yearList" [value]="Year">
              {{Year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Month</mat-label>
          <mat-select name="Month">
            <mat-option *ngFor="let Month of monthList" [value]="Month">
              {{Month}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Week</mat-label>
          <mat-select name="Week">
            <mat-option *ngFor="let Week of weekList" [value]="Week">
              {{Week}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Day</mat-label>
          <mat-select name="Day">
            <mat-option *ngFor="let Day of dayList" [value]="Day">
              {{Day}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Shift</mat-label>
          <mat-select name="Shift">
            <mat-option *ngFor="let Shift of shiftList" [value]="Shift">
              {{Shift}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Machine</mat-label>
          <mat-select name="Machine">
            <mat-option *ngFor="let Machine of machineList" [value]="Machine">
              {{Machine}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Charts -->
  <div class="chart-group">

    <!-- # of CCR -->
    <div class="chart-items">
      <div class="chart-title"> # of CCR</div>
      <app-o3-charts [chartData]="ccr" [datasets]="ccr.barChartData" [labels]="ccr.barChartLabels"
        [colors]="ccr.barChartColors" [options]="ccr.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- # of NCR -->
    <div class="chart-items">
      <div class="chart-title"> # of NCR</div>
      <app-o3-charts [chartData]="ncr" [datasets]="ncr.barChartData" [labels]="ncr.barChartLabels"
        [colors]="ncr.barChartColors" [options]="ncr.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- CCR by Project -->
    <div class="chart-items">
      <div class="chart-title"> CCR by Project</div>
      <app-o3-charts [chartData]="ccrByProject" [datasets]="ccrByProject.barChartData"
        [labels]="ccrByProject.barChartLabels" [colors]="ccrByProject.barChartColors"
        [options]="ccrByProject.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- NCR by Project -->
    <div class="chart-items">
      <div class="chart-title"> NCR by Project</div>
      <app-o3-charts [chartData]="ncrByProject" [datasets]="ncrByProject.barChartData"
        [labels]="ncrByProject.barChartLabels" [colors]="ncrByProject.barChartColors"
        [options]="ncrByProject.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- # of CCR Open vs Closed -->
    <div class="chart-items">
      <div class="chart-title"> # of CCR Open vs Closed</div>
      <app-o3-charts [chartData]="ccrOpenClosed" [datasets]="ccrOpenClosed.barChartData"
        [labels]="ccrOpenClosed.barChartLabels" [colors]="ccrOpenClosed.barChartColors"
        [options]="ccrOpenClosed.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- # of NCR Open vs Closed -->
    <div class="chart-items">
      <div class="chart-title"> # of NCR Open vs Closed</div>
      <app-o3-charts [chartData]="ncrOpenClosed" [datasets]="ncrOpenClosed.barChartData"
        [labels]="ncrOpenClosed.barChartLabels" [colors]="ncrOpenClosed.barChartColors"
        [options]="ncrOpenClosed.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- CCR Gap Analysis -->
    <div class="chart-items">
      <div class="chart-title"> CCR Gap Analysis</div>
      <app-o3-charts [chartData]="ccrGapAnalysis" [datasets]="ccrGapAnalysis.barChartData"
        [labels]="ccrGapAnalysis.barChartLabels" [colors]="ccrGapAnalysis.barChartColors"
        [options]="ccrGapAnalysis.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- NCR Gap Analysis -->
    <div class="chart-items">
      <div class="chart-title"> NCR Gap Analysis</div>
      <app-o3-charts [chartData]="ncrGapAnalysis" [datasets]="ncrGapAnalysis.barChartData"
        [labels]="ncrGapAnalysis.barChartLabels" [colors]="ncrGapAnalysis.barChartColors"
        [options]="ncrGapAnalysis.barChartOptions">
      </app-o3-charts>
    </div>

  </div>
