<div class="container">
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
        <mat-panel-title>
          Countries
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="countryDataForm">
        <div class="content-top-row" style="margin-top: 30px;">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <input matInput formControlName="countryName" placeholder="Country Name">
            <mat-error class="error-msg" *ngIf="checkError('countryName', 'required')">Country Name is required
            </mat-error>
          </mat-form-field>
          <button [disabled]="!countryDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
            pTooltip="Submit" tooltipPosition="top" style="margin-left: 20px;" (click)="addNewCountry()">
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
          <button [disabled]="!countryDataForm.valid" (click)="reset()" mat-mini-fab mat-button color="primary"
            pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;">
            <i class="fa fa-refresh" style="color: #fff"></i>
          </button>
        </div>
      </form>

      <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="countries"
        [columnDefs]="columnDefsCountry" [gridOptions]="gridOptions" rowSelection="multiple"
        (gridReady)="onGridReady($event)" [pagination]="true" [groupHideOpenParents]="true">
      </ag-grid-angular>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
        <mat-panel-title>
          Cities
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="cityDataForm">
        <div class="content-top-row" style="margin-top: 30px;">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <input matInput formControlName="cityName" placeholder="City Name">
            <mat-error class="error-msg" *ngIf="checkErrorCity('cityName', 'required')">City Name is required
            </mat-error>
          </mat-form-field>
          <input formControlName="Id" [hidden]="true">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="countryId" placeholder="Country Name" name="countryId">
              <mat-option *ngFor="let country of countries" [value]="country.countryId">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <button [disabled]="!cityDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
            pTooltip="submit" tooltipPosition="top" style="margin-left: 20px;" (click)="addNewCity()">
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
          <button [disabled]="!cityDataForm.valid" (click)="resetCity()" mat-mini-fab mat-button color="primary"
            pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;">
            <i class="fa fa-refresh" style="color: #fff"></i>
          </button>
        </div>
      </form>

      <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="citiesRes"
      [columnDefs]="columnDefsCities" [gridOptions]="gridOptions" rowSelection="multiple"
      (gridReady)="onGridReady($event)" [pagination]="true" [groupHideOpenParents]="true">
    </ag-grid-angular>
    </mat-expansion-panel>
  </mat-accordion>
</div>