<div class="dashboard">
  <div class="top-blue-box">
    <h1 class="title-30">Good morning,<span class="title-35"> David Turner!</span></h1>
    <p class="text-16">You have 22 Tasks pending. Complete them on time to increase your score level.</p>
    <button mat-flat-button color="accent" class="btn-accent-big">Let’s get started!</button>
    <div class="img-box">
      <img src="assets/images/dashboad-top-image.png" alt="">
    </div>
  </div>


  <div class="table-card-group ">
    <div class="wrapper-box wrapper-box-shadow task-table">
      <div class="content-top-box-content">
        <h1 class="content-title">My tasks</h1>
        <div class="add-task">
          <button mat-flat-button color="accent" class="btn-accent">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.2913 6.45825H6.45801V10.2916H4.54134V6.45825H0.708008V4.54158H4.54134V0.708252H6.45801V4.54158H10.2913V6.45825Z"
                fill="white" />
            </svg>Add new task</button>
        </div>
      </div>
      <!-- Table -->
      <table mat-table [dataSource]="dashboardData" class="basic-table dashboard-table" matSort>

        <!-- Flag Column -->
        <ng-container matColumnDef="flag">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="frist-child">
              <img class="header-icon" [src]="flag" alt="{{flag}}" />
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="table-element-content">
              <div class="img-box">
                <img class="img" [src]="element.flagIcon" alt="{{element.flagIcon}}" />
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Due date -->
        <ng-container matColumnDef="dueDate">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="headers-text"> Due date</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.dueDate}} </td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="headers-text">Title</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="table-element-content">
              <div class="img-box">
                <img class="img" [src]="element.taskTitle" alt="{{element.taskTitle}}" />
              </div>
              <p class="element-text-main"> {{element.title}} </p>
            </div>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="headers-text">Status</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div [ngSwitch]="element.status">
              <div *ngSwitchCase="'inProgress'">
                <button class="btn-base btn-base-blue">In progress</button>
              </div>
              <div *ngSwitchCase="'overdue'">
                <button class="btn-base btn-base-red">Overdue</button>
              </div>
              <div *ngSwitchCase="'done'">
                <button class="btn-base btn-base-green">Done</button>
              </div>
            </div>

          </td>
        </ng-container>

        <!-- Assignee Column -->
        <ng-container matColumnDef="assignee">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="headers-text"> Assignee</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="table-element-content">
              <div class="img-box">
                <img class="img" [src]="element.assigneeIcon" alt="{{element.assigneeIcon}}" />
              </div>
              <p class="element-text-main"> {{element.assignee}} </p>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dashboardColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dashboardColumns;"></tr>
      </table>
    </div>
    <div class="achievement-box">
      <div class="achievement-box-content">
        <h1 class="text-17">Achievement</h1>
        <div class="img-box">
          <img src="assets/images/david-turner-achievement.png" alt="">
        </div>
        <h1 class="text-25">David Turner</h1>
        <p class="text-16">Floor Engineer</p>
        <div class="achievement-level">
          <img src="assets/images/achievement-level.svg" alt="">
          <div class="achievement-level-text">
            <p class="text-20">Level 5</p>
            <p class="text-15">127 Points this Month</p>
          </div>
        </div>
        <!-- <div class="right-bottom-box">
          <img src="assets/images/achievement-box-bg.svg" alt="">
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="chart-menu-group-wrapper">
  <div class="chart-menu-group">
    <div class="chart-item">
      <div class="chart-title-wrapper">
        <div class="text-17"> Production Volume</div>
        <div class="chart-data">
          <div class="chart-title"> 7,462 </div>
          <div class="chart-title-sub">(last 30 days)</div>
        </div>

      </div>
     
    <!-- <app-oo3-chart [chartData]="dashboardChart" [datasets]="dashboardChart.lineChartData"
    [labels]="dashboardChart.lineChartLabels" [colors]="dashboardChart.lineChartColors"
    [options]="dashboardChart.lineChartOptions">
  </app-oo3-chart> -->
      <app-line-chart [chartData]="dashboardChart" [datasets]="dashboardChart.lineChartData"
        [labels]="dashboardChart.lineChartLabels" [colors]="dashboardChart.lineChartColors"
        [options]="dashboardChart.lineChartOptions">
      </app-line-chart>
    </div>

    <div class="notification-box">
      <div class="notification-content">
        <div class="text-17">Company events</div>
        <div class="notification-item-grp">
          <div class="notification-item-wrapper" *ngFor="let event of companyEvent">
            <div class="event-img-box">
              <h1 class="text-14">{{event.iconDate}}</h1>
              <p class="text-9">{{event.iconMonth}}</p>
            </div>
            <div class="notification-item">
              <h1 class="text-14">{{event.eventTitle}}</h1>
              <p class="text-12">{{event.eventContent}}</p>
              <p class="text-12">{{event.eventFooter}}</p>
            </div>
          </div>
          <div *ngIf="viewAllEvent">
            <div class="notification-item-wrapper" *ngFor="let event of companyEvent">
              <div class="event-img-box">
                <h1 class="text-14">{{event.iconDate}}</h1>
                <p class="text-9">{{event.iconMonth}}</p>
              </div>
              <div class="notification-item">
                <h1 class="text-14">{{event.eventTitle}}</h1>
                <p class="text-12">{{event.eventContent}}</p>
                <p class="text-12">{{event.eventFooter}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="view-all-wrapper" (click)="openViewAllEvent()">
          <div class="text-13">View all events</div>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.00032 0.666504L5.06033 1.6065L8.78032 5.33317H0.666992V6.6665H8.78032L5.06033 10.3932L6.00032 11.3332L11.3337 5.99984L6.00032 0.666504Z"
              fill="#0F0FD1" />
          </svg>
        </div>
      </div>

      <div class="notification-content">
        <div class="text-17">HR announcements</div>
        <div class="notification-item-grp">
          <div class="notification-item-wrapper" *ngFor="let announcement of hrAnnouncements">
            <div class="event-img-box announcement-bg">
              <h1 class="text-14">{{announcement.iconDate}}</h1>
              <p class="text-9">{{announcement.iconMonth}}</p>
            </div>
            <div class="notification-item">
              <h1 class="text-14">{{announcement.eventTitle}}</h1>
              <p class="text-12">{{announcement.eventContent}}</p>
              <p class="text-12">{{announcement.eventFooter}}</p>
            </div>
          </div>
          <div *ngIf="viewAllAnnouncement">
            <div class="notification-item-wrapper" *ngFor="let announcement of hrAnnouncements">
              <div class="event-img-box announcement-bg">
                <h1 class="text-14">{{announcement.iconDate}}</h1>
                <p class="text-9">{{announcement.iconMonth}}</p>
              </div>
              <div class="notification-item">
                <h1 class="text-14">{{announcement.eventTitle}}</h1>
                <p class="text-12">{{announcement.eventContent}}</p>
                <p class="text-12">{{announcement.eventFooter}}</p>
              </div>
            </div>
          </div>
          <!-- <div class="notification-item-wrapper">
            <div class="event-img-box announcement-bg">
              <h1 class="text-14">28</h1>
              <p class="text-9">OCT 2020</p>
            </div>
            <div class="notification-item">
              <h1 class="text-14">New Openings for IT Consultant Role</h1>
              <p class="text-12">Lorem at nec sed eros velit pharetra...</p>
              <p class="text-12">By HR Manager </p>
            </div>

          </div>
          <div class="notification-item-wrapper">
            <div class="event-img-box announcement-bg">
              <h1 class="text-14">23</h1>
              <p class="text-9">OCT 2020</p>
            </div>
            <div class="notification-item">
              <h1 class="text-14">Apply for Work from Home for <br> Nov 2020</h1>
              <p class="text-12">Lorem at nec sed eros velit pharetra...</p>
              <p class="text-12">By HR Manager </p>
            </div>
          </div>
          <div class="notification-item-wrapper">
            <div class="event-img-box announcement-bg">
              <h1 class="text-14">15</h1>
              <p class="text-9">OCT 2020</p>
            </div>
            <div class="notification-item">
              <h1 class="text-14">Update Time Sheets</h1>
              <p class="text-12">Lorem at nec sed eros velit pharetra,<br> mi velit.</p>
              <p class="text-12">By HR Manager</p>
            </div>
          </div> -->
        </div>
        <div class="view-all-wrapper" (click)="openViewAllAnnouncement()">
          <h1 class="text-13">View all announcements</h1>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.00032 0.666504L5.06033 1.6065L8.78032 5.33317H0.666992V6.6665H8.78032L5.06033 10.3932L6.00032 11.3332L11.3337 5.99984L6.00032 0.666504Z"
              fill="#0F0FD1" />
          </svg>
        </div>
      </div>
    </div>

  </div>
  <div class="card-group">
    <div class="machine-card-wrapper">
      <div class="machine-card-item">
        <div class="img-box">
          <img src="assets/images/machine-output-green-icon-img.png" alt="">
        </div>
        <div class="machine-card-content">
          <h1 class="text-17">Machine output 1</h1>
          <p class="title-30">19M
            <span class="title-30 text-15">kg</span>
          </p>
          <div class="machine-output-footer">
            <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.1092 12.997L6.79722 8.51796L1.50022 13.984L0.0742188 12.582L6.81222 5.65096L11.1662 10.173L18.6562 3.00096L16.5102 0.855957H22.1132V6.45696L20.0702 4.41496L11.1092 12.997Z"
                fill="#0DA03B" />
            </svg>
            <p class="text-13">12.57%</p>
          </div>

        </div>
      </div>
      <div class="machine-card-item">
        <div class="img-box img-box-red">
          <img src="assets/images/machine-output-red-icon-img.png" alt="">
        </div>
        <div class="machine-card-content">
          <h1 class="text-17">Machine output 2</h1>
          <p class="title-30 text-red">378.05
            <span class="title-30 text-15">mtr</span>
          </p>
          <div class="machine-output-footer">
            <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.0783 1.84289L15.3903 6.32189L20.6873 0.855888L22.1133 2.25789L15.3753 9.18889L11.0213 4.66689L3.53128 11.8389L5.67728 13.9839L0.0742798 13.9839L0.0742803 8.38289L2.11728 10.4249L11.0783 1.84289Z"
                fill="#F85439" />
            </svg>
            <p class="text-13">12.57%</p>
          </div>

        </div>
      </div>
    </div>
    <div class="menu-card-grp card-blue">
      <h1 class="text-17">New Joinee</h1>
      <h1 class="text-18">Daniel Powell</h1>
      <p class="text-14">HR Assistant</p>
      <p class="text-13">Welcome him!</p>
      <div class="top-box">
        <div class="top-icon">
          <img src="assets/images/new-joinee.svg" alt="">
        </div>
      </div>
      <div class="bottom-box">
        <img src="assets/images/new-joinee-bottom-icon.svg" alt="">
      </div>
    </div>
    <div class="menu-card-grp card-violet">
      <h1 class="text-17">Birthday Alert</h1>
      <h1 class="text-18">Daniel Powell</h1>
      <p class="text-14">HR Assistant</p>
      <p class="text-13">Welcome him!</p>
      <div class="top-box">
        <div class="top-icon">
          <img src="assets/images/birthday-alert.svg" alt="">
        </div>
      </div>
      <div class="bottom-box">
        <img src="assets/images/birthday-alert-bottom-icon.svg" alt="">
      </div>
    </div>
    <div class="menu-card-grp card-red">
      <h1 class="text-17">Employee of the Month</h1>
      <h1 class="text-18">Daniel Powell</h1>
      <p class="text-14">HR Assistant</p>
      <p class="text-13">Welcome him!</p>
      <div class="top-box">
        <div class="top-icon">
          <img src="assets/images/birthday-alert.svg" alt="">
        </div>
      </div>
      <div class="bottom-box">
        <img src="assets/images/employee-of-month-bottom-icon.svg" alt="">
      </div>
    </div>
  </div>

</div>
