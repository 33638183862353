import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-opms-main',
  templateUrl: './opms-main.component.html',
  styleUrls: ['./opms-main.component.scss',
              './opms-main.component.css']
})
export class OPMSMainComponent implements OnInit {

  public breadcrumList: any;
  private subscriptions = new Subscription();
  isHotEndUser: boolean = true;

  constructor(
    private usermanagementService: UserManagementService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    public router: Router,
  ) { }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService.GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      ).subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  ngOnInit(): void {
    const roles = JSON.parse(localStorage.getItem('roleName'));
    if(roles.length){
      const isAdmin = roles.find((fre: any) => {
                        return (fre == 'Glass - Admin');
                      })
      if(isAdmin){
        this.isHotEndUser = false;
      }
      else{
        this.isHotEndUser = roles.find((fre: any) => {
                              return (fre == 'Hot End Diary');
                            })
        if(this.isHotEndUser && roles.length == 1){
          this.isHotEndUser = true;
        }
        else{
          this.isHotEndUser = false;
        }
      }
    }
    else{
      this.isHotEndUser = false;
    }
    this.getBreadcrumList();
  }

}
