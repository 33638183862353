import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { ProductdetailComponent } from 'src/app/screens/productdetail/productdetail.component';

const routes: Routes = [
      { path: 'ReactiveMaintenance', component: ProductdetailComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProgressiveMaintenanceRoutingModule {}
